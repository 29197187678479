import axios from "axios";
import {
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAIL,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  GET_CLIENT_SEARCH_SUCCESS,
  GET_CLIENT_SEARCH_FAIL,
  GET_BENEFICIARY_SUCCESS,
  GET_BENEFICIARY_FAIL,
	GET_CLIENT_SEARCH_NUIP_SUCCESS,
	GET_CLIENT_SEARCH_NUIP_FAIL,
	GET_CLIENT_DASHBOARD_SUCCESS,
	GET_CLIENT_DASHBOARD_FAIL,
	GET_CLIENT_DASHBOARD_LIST_SUCCESS,
	GET_CLIENT_DASHBOARD_LIST_FAIL,
	GET_CLIENT_DASHBOARD_LIST_BY_TABLE_SUCCESS,
	GET_CLIENT_DASHBOARD_LIST_BY_TABLE_FAIL,
	GET_CLIENT_SEARCH_NUIP_NOT_FOUND
} from "./types";

// listar todas las categorias
export const get_client_list = (active = null, table = null,tokenVerified=null,plans=null,vip=null,corpoindustrial=null,estrato=null, beneficiarios=null) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  let url = `${process.env.REACT_APP_API_URL}/client/list/`;
  let params = new URLSearchParams();

	if (beneficiarios !== null && beneficiarios !== "") {
		params.append('beneficiarios', beneficiarios);
	}
  if (active !== null && active !== "") {
    params.append('active', active);
  }
  if (table !== null && table !== "") {
    params.append('table', table);
  }
	if (tokenVerified !== null && tokenVerified !== "") {
		params.append('token_verified', tokenVerified);
	}
	if (plans !== null && plans !== "") {
		params.append('plans', plans);
	}
	if (vip !== null && vip !== "") {
		params.append('vip', vip);
	}
	if (corpoindustrial !== null && corpoindustrial !== "") {
		params.append('corpoindustrial', corpoindustrial);
	}
	if (estrato !== null && estrato !== "") {
		params.append('estrato', estrato);
	}
	
  url += `?${params.toString()}`;

  try {
    const res = await axios.get(url, config);
    if (res.status === 200) {
      dispatch({
        type: GET_CLIENT_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_CLIENT_LIST_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CLIENT_LIST_FAIL,
    });
  }
};


// listar categoria por uuid
export const get_client = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_CLIENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_CLIENT_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CLIENT_FAIL,
    });
  }
};

export const get_client_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_CLIENT_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_CLIENT_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_CLIENT_LIST_FAIL,
    });
  }
};

export const get_beneficiary = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/beneficiary/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_BENEFICIARY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_BENEFICIARY_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_BENEFICIARY_FAIL,
    });
  }
};

export const search_client = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,

    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/search/${search_term}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_CLIENT_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_CLIENT_SEARCH_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CLIENT_SEARCH_FAIL,
    });
  }
};

export const search_client_nuip = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",


    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/response/search/${search_term}/`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_CLIENT_SEARCH_NUIP_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_CLIENT_SEARCH_NUIP_FAIL,
      });
    }
  } catch (err) {
		if (err.response && err.response.status === 404) {
      dispatch({
        type: GET_CLIENT_SEARCH_NUIP_NOT_FOUND,
      });
		}
  }
};

export const get_client_dashboard = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/client/dashboard/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_CLIENT_DASHBOARD_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_CLIENT_DASHBOARD_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_CLIENT_DASHBOARD_FAIL,
		});
	}
};

export const get_client_dashboard_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/dashboard/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_CLIENT_DASHBOARD_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_CLIENT_DASHBOARD_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_CLIENT_DASHBOARD_LIST_FAIL,
    });
  }
};

export const get_client_dashboard_table = (id) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/client/dashboard/${id}/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_CLIENT_DASHBOARD_LIST_BY_TABLE_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_CLIENT_DASHBOARD_LIST_BY_TABLE_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_CLIENT_DASHBOARD_LIST_BY_TABLE_FAIL,
		});
	}
}