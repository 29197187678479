import {
  GET_SHARED_TOKEN_SUCCESS,
  GET_SHARED_TOKEN_FAIL,
  GET_TOKEN_SEARCH_SUCCESS,
  GET_TOKEN_SEARCH_FAIL,
} from "../actions/sharedtoken/types";

const initialState = {
  tokens: null,
  count: null,
  next: null,
  previous: null,
};

export default function tokens(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SHARED_TOKEN_SUCCESS:
      return {
        ...state,
        tokens: payload.results.tokens,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_SHARED_TOKEN_FAIL:
      return {
        ...state,
        tokens: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_TOKEN_SEARCH_SUCCESS:
      return {
        ...state,
        filtered_token: payload.filtered_token,
      };
    case GET_TOKEN_SEARCH_FAIL:
      return {
        ...state,
        filtered_token: null,
      };
    default:
      return state;
  }
}
