import React, { useState } from "react";
import { IconSend, IconX } from "@tabler/icons-react";
import DeleteClient from "../modals/DeleteClient";
import axios from "axios";

function MembershipRenewModal({
  data,
  handleButtonClick,
  isButtonDisabled,
  onSubmitDeleteClient,
  refreshData
}) {
  const [showConfirmRenew, setShowConfirmRenew] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [wantsReferral, setWantsReferral] = useState(false);
  const [referrerNuip, setReferrerNuip] = useState("");
  const [referrerData, setReferrerData] = useState(null);
  const [referrerError, setReferrerError] = useState(null);
  const [isReferrerValidated, setIsReferrerValidated] = useState(false);

  const handleRenewClick = () => {
    setShowConfirmRenew(true);
    document.getElementById("membership_renew_modal").checked = false;
  };

  const handleDeleteClick = () => {
    setShowConfirmDelete(true);
    document.getElementById("membership_renew_modal").checked = false;
  };

  const validateReferrerNuip = async () => {
    if (referrerNuip) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        const data = {
          nuip_client: referrerNuip,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client/referred/`,
          data,
          config
        );

        if (response.status === 200) {
          setReferrerData(response.data);
          setIsReferrerValidated(true);
          setReferrerError(null);
        }
      } catch (error) {
        if (error.response && error.response.data.error) {
          setReferrerError(error.response.data.error);
        } else {
          setReferrerError("Ha ocurrido un error al validar la cédula.");
        }
        setReferrerData(null);
        setIsReferrerValidated(false);
      }
    } else {
      setReferrerError(null);
      setReferrerData(null);
      setIsReferrerValidated(false);
    }
  };

  const confirmRenew = (e) => {
    if (wantsReferral && !isReferrerValidated) {
      alert("Debe validar el documento del referente antes de confirmar.");
      return;
    }

    handleButtonClick(e, data.nuip, referrerNuip);
    refreshData();
    setShowConfirmRenew(false);
  };

  const confirmDelete = (e) => {
    onSubmitDeleteClient(data);
    refreshData();
    setShowConfirmDelete(false);
  };

  return (
    <div>
      <div className="flex tooltip" data-tip="Renovar membresia">
        <label
          htmlFor="membership_renew_modal"
          className={`p-1 text-white bg-green-500 rounded-full cursor-pointer ${isButtonDisabled ? "disabled" : ""}`}
          disabled={isButtonDisabled}
        >
          <IconSend />
        </label>
      </div>

      <input type="checkbox" id="membership_renew_modal" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box rounded-md">
          <div className="flex justify-between items-center">
            <div className="w-6"></div>
            <h3 className="font-medium text-2xl text-gray-900 text-center">Renovar membresía</h3>
            <label
              htmlFor="membership_renew_modal"
              className="btn btn-sm btn-circle btn-ghost text-gray-500"
            >
              <IconX size={18} />
            </label>
          </div>
          <div className="my-2">
            <p className="text-xl text-center mb-2 text-gray-500">
              ¿Deseas renovar la membresía de {data.first_name}?
            </p>
            <p className="text-gray-500 text-center">Pulsa "Sí" para renovar la membresía del cliente, o "No" para desactivarlo</p>
          </div>

          <div className="modal-action justify-center">
            <div className="flex justify-center gap-x-2">
              <label
                onClick={handleRenewClick}
                className="btn border w-20 bg-blue-500 text-white hover:bg-blue-600"
              >
                Sí
              </label>
              <label
                onClick={handleDeleteClick}
                className="btn border w-20 bg-red-500 text-white hover:bg-red-600"
              >
                No
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmar Renovación Modal */}
      {showConfirmRenew && (
        <div className="modal modal-open" role="dialog">
          <div className="modal-box rounded-md relative">
            <button
              className="btn btn-sm btn-circle absolute right-2 top-2 text-gray-500"
              onClick={() => setShowConfirmRenew(false)}
            >
              <IconX size={18} />
            </button>
            <h3 className="font-medium text-xl text-gray-900 text-center">Confirmar renovación</h3>
            <p className="text-gray-500 text-center mb-4">
              ¿Estás seguro de que deseas renovar la membresía de {data.first_name}?
            </p>

            {/* Checkbox y campo de documento para referidos */}
            <div className="flex items-center justify-center mt-4">
              <label className="label cursor-pointer">
                <span className="text-gray-500 mr-2">¿Quiere ser referido de alguien más?</span>
                <input
                  type="checkbox"
                  checked={wantsReferral}
                  onChange={() => {
                    setWantsReferral(!wantsReferral);
                    // Resetear datos del referente al cambiar el estado
                    setReferrerNuip("");
                    setReferrerData(null);
                    setReferrerError(null);
                    setIsReferrerValidated(false);
                  }}
                  className="checkbox checkbox-primary"
                />
              </label>
            </div>
            {wantsReferral && (
              <div className="mt-2">
                <label className="text-gray-500">Número de Documento del Referente:</label>
                <div className="flex">
                  <input
                    type="text"
                    value={referrerNuip}
                    onChange={(e) => {
                      setReferrerNuip(e.target.value);
                      setIsReferrerValidated(false);
                      setReferrerError(null);
                      setReferrerData(null);
                    }}
                    className="input input-bordered w-full"
                    placeholder="Ingrese el número de documento"
                  />
                  <button
                    type="button"
                    onClick={validateReferrerNuip}
                    className="btn ml-2 bg-[#0096C4] text-white"
                  >
                    Buscar
                  </button>
                </div>
                {referrerError && (
                  <p className="text-red-500 text-sm mt-1">{referrerError}</p>
                )}
                {referrerData && (
                  <div className="mt-2">
                    <p className="text-gray-500">Datos del Referente:</p>
                    <p><strong>Nombre:</strong> {referrerData.parent.first_name}</p>
                    <p><strong>Apellido:</strong> {referrerData.parent.last_name}</p>
                  </div>
                )}
              </div>
            )}

            <div className="modal-action justify-center mt-4">
              <button
                onClick={confirmRenew}
                className="btn bg-blue-500 text-white hover:bg-blue-600"
                disabled={wantsReferral && !isReferrerValidated}
              >
                Confirmar
              </button>
              <button
                onClick={() => setShowConfirmRenew(false)}
                className="btn bg-red-500 text-white hover:bg-red-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Confirmar Desactivación Modal */}
      {showConfirmDelete && (
        <div className="modal modal-open" role="dialog">
          <div className="modal-box rounded-md relative">
            <button
              className="btn btn-sm btn-circle absolute right-2 top-2 text-gray-500"
              onClick={() => setShowConfirmDelete(false)}
            >
              <IconX size={18} />
            </button>
            <h3 className="font-medium text-xl text-gray-900 text-center">Confirmar desactivación</h3>
            <p className="text-gray-500 text-center mb-4">
              ¿Estás seguro de que deseas desactivar al cliente {data.first_name}?
            </p>
            <div className="modal-action justify-center">
              <button
                onClick={confirmDelete}
                className="btn bg-blue-500 text-white hover:bg-blue-600"
              >
                Confirmar
              </button>
              <button
                onClick={() => setShowConfirmDelete(false)}
                className="btn bg-red-500 text-white hover:bg-red-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MembershipRenewModal;
