import UsersCardHorizontal from "./UsersCardHorizontal";


function UsersListSearch({
  users
}) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2">
                <div className="w-52">Datos</div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-32">Contacto</div>
              </th>
							<th scope="col" className="p-2">
								<div className="w-24">Trabajo</div>
							</th>
              <th scope="col" className="p-2">
								<div className="w-24">Mesas</div>
							</th>
              <th scope="col" className="p-2">
                <div className="w-32">Fecha</div>
              </th>
             
              <th scope="col" className="p-2">
                Acciones
              </th>

              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user, index) => (
                <UsersCardHorizontal
                  data={user}
                  key={index}
                  index={index}
                />
              ))}
          </tbody>
        </table>
      </div>

    </div>
  );
}
export default UsersListSearch;
