// para obtener todos los clientes
export const GET_CLIENT_LIST_SUCCESS = 'GET_CLIENT_LIST_SUCCESS';
export const GET_CLIENT_LIST_FAIL = 'GET_CLIENT_LIST_FAIL';
// para obtener el cliente por uuid
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAIL = 'GET_CLIENT_FAIL';
// para obtener el beneficiario por uuid    
export const GET_BENEFICIARY_SUCCESS = 'GET_BENEFICIARY_SUCCESS';
export const GET_BENEFICIARY_FAIL = 'GET_BENEFICIARY_FAIL';
// para obtener el cliente busqueda
export const GET_CLIENT_SEARCH_SUCCESS = 'GET_CLIENT_SEARCH_SUCCESS';
export const GET_CLIENT_SEARCH_FAIL = 'GET_CLIENT_SEARCH_FAIL';
// para obtener el cliente busqueda

export const GET_CLIENT_SEARCH_NUIP_SUCCESS = 'GET_CLIENT_SEARCH_SUCCESS';
export const GET_CLIENT_SEARCH_NUIP_FAIL = 'GET_CLIENT_SEARCH_FAIL';
export const GET_CLIENT_SEARCH_NUIP_NOT_FOUND = 'GET_CLIENT_SEARCH_NUIP_NOT_FOUND';

// obtener los clientes en el dashboard
export const GET_CLIENT_DASHBOARD_SUCCESS = 'GET_CLIENT_DASHBOARD_SUCCESS';
export const GET_CLIENT_DASHBOARD_FAIL = 'GET_CLIENT_DASHBOARD_FAIL';
// obtener los clientes en el dashboard
export const GET_CLIENT_DASHBOARD_LIST_SUCCESS = 'GET_CLIENT_DASHBOARD_LIST_SUCCESS';
export const GET_CLIENT_DASHBOARD_LIST_FAIL = 'GET_CLIENT_DASHBOARD_LIST_FAIL';

export const GET_CLIENT_DASHBOARD_LIST_BY_TABLE_SUCCESS = 'GET_CLIENT_DASHBOARD_LIST_BY_TABLE_SUCCESS';

export const GET_CLIENT_DASHBOARD_LIST_BY_TABLE_FAIL = 'GET_CLIENT_DASHBOARD_LIST_BY_TABLE_FAIL';