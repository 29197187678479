import React from "react";
import { Link } from "react-router-dom";
import { IconPencil, IconFileDescription } from "@tabler/icons-react";
import CommissionEditModal from "../modals/CommissionEditModal";
import { useSelector } from "react-redux";

function CommissionsPaymentsCardHorizontal({ data, index }) {
	// Parsea `commission_amount` a un objeto JavaScript.
	let commissionAmountDetails = {};
	try {
		commissionAmountDetails = data && JSON.parse(data.commission_amount);
	} catch (e) {
		console.error("Error al parsear los datos de comisión", e);
	}
	const user = useSelector((state) => state.auth.user);
	return (
		<React.Fragment key={index}>
			<tr className="bg-white border-b">
				{data && data.user && (data.user.role === "BANCO" || data.user.role === "COSTOS Y GASTOS" || data.user.role === "IMPUESTOS") ? (
					<td className="p-2 text-xs">
						<div className="w-44">
							<span className="font-bold">Rol: </span>
							{data.user.role}
						</div>
					</td>
				) : (
					<td className="p-2 text-xs">
						<div className="w-44">
							<span className="font-bold">Nombres: </span>
							{data.user && data.user.first_name}
						</div>
						<div className="w-44">
							<span className="font-bold">Apellidos: </span>
							{data.user && data.user.last_name}
						</div>
						<div className="w-44">
							<span className="font-bold">Rol: </span>
							{data.user && data.user.role}
						</div>
					</td>
				)}
				<td className="p-2 text-xs">
					<div className="w-56">
						<div className="font-bold">Total a pagar:</div>
						<div>
							<div className="bg-[#0096C4] text-white shadow-md my-1 p-2 rounded-lg">
								{data.total > 0 && <div className="text-xs">$ {data.total}</div>}
							</div>
						</div>
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="w-24">
						<span className="font-bold">Nro: </span>
						<span className="bg-gray-200 px-2 py-1 rounded-full">{data.table && data.table.table}</span>
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="w-24">
						<span className="font-bold">Pagado: </span>
						<span className="text-green-500">{data.paid ? "Si" : "No"}</span>
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="flex gap-2">
						{user && user.role && user.role === "CONTABILIDAD" && !data.paid ? <CommissionEditModal id={data.id} isEnterprise={false} /> : null}
						<div className="tooltip flex" data-tip="Generar Reporte">
							<Link className="p-1 bg-teal-400 text-white rounded-full" to={`/commissions-report/users/${data.id}`} target="_blank">
								<IconFileDescription />
							</Link>
						</div>
					</div>
				</td>
			</tr>
		</React.Fragment>
	);
}
export default CommissionsPaymentsCardHorizontal;
