import { IconTable, IconUsers, IconChartArrowsVertical } from '@tabler/icons-react';

function TableCard(props) {
  return (
    <div className="bg-gray-50 shadow-md p-3 rounded-xl space-y-1.5">
		    <div className='flex items-center gap-2'>
          <div className='p-2 rounded-full shadow-md bg-[#0096C4]'>
            <IconTable size={18} className='text-white' />
          </div>
          <h2><span className='font-bold'>Mesa:</span> {props.table}</h2>
        </div>
        <div className='flex items-center gap-2'>
          <div className='p-2 rounded-full shadow-md bg-[#0096C4]'>
            <IconUsers size={18} className='text-white' />
          </div>
          <h2><span className='font-bold'>Total de clientes:</span> {props.clientCount}</h2>
        </div>
        <div className='flex items-center gap-2'>
          <div className='p-2 rounded-full shadow-md bg-[#0096C4]'>
            <IconChartArrowsVertical size={18} className='text-white' />
          </div>
          <h2><span className='font-bold'>Nivel de mesa:</span> {props.clientLevel}</h2>
        </div>
		</div>
  )
}

export default TableCard;