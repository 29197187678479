import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { search_client } from "../../../redux/actions/client/client";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import ClientListSearch from "../../../components/client/ClientListSearch";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading";

function SearchClient({ search_client, clients }) {
	const params = useParams();
	const term = params.term;

	useEffect(() => {
		search_client(term);
	}, [search_client, term]);

	const [term2, setTerm] = useState("");

	const handleChange = (e) => {
		setTerm(e.target.value);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setTimeout(() => (window.location.href = "/search/clients/" + term2), 0.2);
		setTerm("");
	};
	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Búsqueda</title>
			</Helmet>
			<div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
				<div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Lista de Titulares
						</h3>
					</div>
					<div className="flex items-center gap-4">
						<div className="">
							<Link to="/create-clients">
								<button className="bg-[#0096C4] text-white px-4 py-2 rounded hover:bg-slate-700">
									Agregar titular
								</button>
							</Link>
						</div>
						<div className="">
							<Link to="/create-referred">
								<button className="bg-[#0096C4] text-white px-4 py-2 rounded hover:bg-slate-700">
									Agregar referrido
								</button>
							</Link>
						</div>
						<div className="">
							<div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
								<form onSubmit={(e) => onSubmit(e)} className="w-full">
									<label htmlFor="search" className="sr-only">
										Search
									</label>
									<div className="relative">
										<button
											type="submit"
											className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
												/>
											</svg>
										</button>
										<input
											id="search"
											name="search"
											required
											onChange={(e) => {
												handleChange(e);
											}}
											className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											placeholder="Search"
											type="search"
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			{clients ? (
				<ClientListSearch clients={clients} />
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<p className="text-gray-500 text-lg">
						No hay un titular registrado con este número de documento: {term}
					</p>
				</div>

			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	clients: state.clients.filtered_clients,
});

export default connect(mapStateToProps, {
	search_client,
})(SearchClient);
