import axios from "axios";
import {
	GET_COMMISSIONS_ENTERPRISES_SUCCESS,
	GET_COMMISSIONS_ENTERPRISES_FAIL,
	GET_COMMISSIONS_ENTERPRISES_SEARCH_SUCCESS,
	GET_COMMISSIONS_ENTERPRISES_SEARCH_FAIL,
	GET_COMMISSIONS_ENTERPRISES_SUCCESS_ID,
	GET_COMMISSIONS_ENTERPRISES_FAIL_ID,

	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS_ID,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL_ID,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_SUCCESS,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_FAIL,
} from "./types";

export const get_commissions_enterprise = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };


  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/commissions-enterprise/list/`, config);
    if (res.status === 200) {
      dispatch({
        type: GET_COMMISSIONS_ENTERPRISES_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_COMMISSIONS_ENTERPRISES_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMMISSIONS_ENTERPRISES_FAIL,
    });
  }
};

export const get_commissions_page_enterprise = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/commissions-enterprise/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_COMMISSIONS_ENTERPRISES_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_COMMISSIONS_ENTERPRISES_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_COMMISSIONS_ENTERPRISES_FAIL,
    });
  }
};

export const search_commision_enterprise = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/commissions-enterprise/search/${search_term}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_COMMISSIONS_ENTERPRISES_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_COMMISSIONS_ENTERPRISES_SEARCH_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMMISSIONS_ENTERPRISES_SEARCH_FAIL,
    });
  }
};

export const get_commission_enterprise = (id) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions-enterprise/list/${id}/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_SUCCESS_ID,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_FAIL_ID,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_ENTERPRISES_FAIL_ID,
		});
	}
}


export const get_commissions_payments_enterprise = (enterprises= null,rol = null, month = null, year = null, paid=null) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	let url = `${process.env.REACT_APP_API_URL}/commissions-payments-enterprise/list/`;
	let params = new URLSearchParams();

	if (paid !== null && paid !== "") {
		params.append('paid', paid);
	}
	if (enterprises !== null && enterprises !== "") {
		params.append('enterprises', enterprises);
	}
	if (rol !== null && rol !== "") {
		params.append('rol', rol);
	}
	if (month !== null && month !== "") {
		params.append('month', month);
	}
	if (year !== null && year !== "") {
		params.append('year', year);
	}
	url += `?${params.toString()}`;
	try {
		const res = await axios.get(
			url,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL,
		});
	}
}

export const get_commissions_payments_page_enterprise = (p) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions-payments-enterprise/list/?p=${p}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL,
			});
		}
	} catch {
		dispatch({
			type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL,
		});
	}
};

export const search_commision_payments_enterprise = (search_term) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions-payments-enterprise/search/${search_term}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_FAIL,
		});
	}
};

export const get_commission_payments_enterprise = (id) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions-payments-enterprise/list/${id}/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS_ID,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL_ID,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL_ID,
		});
	}
}