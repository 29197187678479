import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { get_commission_enterprise } from "../../../redux/actions/commissions-enterprise/commissions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Toast from "../../../components/Alert";
import { Select } from "@material-tailwind/react";

function CommissionsEdit({ get_commission_enterprise, commission }) {
	const params = useParams();
	const id = params.id;

	useEffect(() => {
		get_commission_enterprise(id);
	}, [id]);

	const [updateAmount, setUpdateAmount] = useState(false);

	const [formData, setFormData] = useState({
		amount: "",
		choices: "",
	});

	const {
		amount,
		choices,
	} = formData;

	const resetStates = () => {
		setUpdateAmount(false);
		setFormData({
			amount: "",
			choices: "",
		});
	};

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const [loading, setLoading] = useState(false);

	const onSubmitField = (e) => {
		e.preventDefault();
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		
		const formData = new FormData();
		formData.append("id", id);
		formData.append("amount", amount);
		formData.append("choices", choices);

		const fetchData = async () => {
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/commissions-enterprise/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setLoading(false);
					get_commission_enterprise(id);
					resetStates();
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					setLoading(false);
					resetStates();
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				setLoading(false);
				resetStates();
				Toast({ tipo: "error", mensaje: "Error al editar las comisión" });
			}
		};
		fetchData();
	};

	return (
		<DashboardLayout>
			<div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
				<h1 className="font-bold text-3xl  text-gray-900">
					Editar monto para calcular las comisiones
				</h1>
				{commission && commission ? (
					<>
						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos a modificar
						</div>
						<div className="border border-gray-300 rounded-lg p-3">
							<div>
								<h2>
									<span className="font-bold">EMPRESA: </span>
									<span className="py-3">{commission.choices}</span>
								</h2>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
								<div className="form-control">
									<label htmlFor="amount" className="label">
										<span className="label-text">Monto</span>
									</label>
									{ updateAmount ? (
										<form onSubmit={(e) => onSubmitField(e)} className="">
											<input
												type="number"
												name="amount"
												value={amount}
												onChange={(e) => onChange(e)}
												required
												className="input input-bordered w-full"
											/>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<div
													onClick={() => setUpdateAmount(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</div>
											</div>
										</form>
									) : (
										<>
											<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
												{commission.amount}
											</span>
											<div className="mt-3">
												<div
													onClick={() => setUpdateAmount(true)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Modificar
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</>
				) : (
					<p>Loading...</p>
				)}
			</div>
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	commission: state.commissions_enterprise.commission_enterprise,
});

export default connect(mapStateToProps, { get_commission_enterprise })(CommissionsEdit);
