// alertas
import React from "react";
import moment from "moment";

// const copyToClipboard = function () {
//     let copyClip = document.getElementById("copy-clip").textContent;
//     navigator.clipboard.writeText(copyClip)
// }

function SharedTokenCardHorizontal({ data, index }) {

  return (
    <React.Fragment key={index}>
      <tr className="bg-white border-b">
        <td className="p-2 text-xs">
          <div className="w-44">
            <span className="font-bold">Documento:</span> {data.nuip}
          </div>
          <div className="w-44">
            <span className="font-bold">Nombre:</span> {data.name}
          </div>
          <div className="w-44">
            <span className="font-bold">Teléfono:</span> {data.phone}
          </div>
        </td>
        <td className="p-2 text-xs">
          <div className="flex items-center gap-3">
            <span id="copy-clip">{data.key}</span>
            {/* <button className="bg-slate-200 p-1 rounded-lg" onClick={copyToClipboard}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-copy" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                        </svg>
                    </button> */}
          </div>
        </td>
        <td className="p-2 text-xs">
          {moment(data.created_at).format("DD/MM/YYYY")}
        </td>
        <td className="p-2 text-xs">
          {data.verified ? (
            <div className="badge badge-success text-xs">Verificado</div>
          ) : (
            <div className="badge badge-warning text-xs">No Verificado</div>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
}
export default SharedTokenCardHorizontal;
