import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import {
  get_shared_tokens,
  get_shared_token_page,
} from "../../../redux/actions/sharedtoken/sharedtoken";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import SharedTokenList from "../../../components/sharedtoken/SharedTokenList";

function SharedToken({
  get_shared_tokens,
  get_shared_token_page,
  tokens,
  count,
}) {
  useEffect(() => {
    get_shared_tokens();
  }, [get_shared_tokens]);
  const [term, setTerm] = useState("");

  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => (window.location.href = "/search/token/" + term), 0.2);
    setTerm("");
  };
  return (
    <DashboardLayout>
      <Helmet>
        <title>Latir | Código</title>
      </Helmet>
      <div className="border-b border-gray-200 bg-white py-5">
        <div className="flex flex-col md:flex-row md:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900">
              Lista de códigos
            </h3>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
            <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
              <form onSubmit={(e) => onSubmit(e)} className="w-full">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <button
                    type="submit"
                    className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </button>
                  <input
                    id="search"
                    name="search"
                    required
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Buscar"
                    type="search"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {
				tokens ? <SharedTokenList
        tokens={tokens}
        get_shared_tokens={get_shared_token_page}
        count={count}
      />: (
        <div className="w-full h-full flex justify-center items-center">
          <Loading />
        </div>
      )
			}
    </DashboardLayout>
  );
}
const mapStateToProps = (state) => ({
  tokens: state.tokens.tokens,
  count: state.tokens.count,
});
export default connect(mapStateToProps, {
  get_shared_tokens,
  get_shared_token_page,
})(SharedToken);
