// para obtener todos los clientes
export const GET_COMMISSIONS_SUCCESS = 'GET_COMMISSIONS_SUCCESS';
export const GET_COMMISSIONS_FAIL = 'GET_COMMISSIONS_FAIL';
// para obtener el beneficiario por busqueda    
export const GET_COMMISSIONS_SEARCH_SUCCESS = 'GET_COMMISSIONS_SEARCH_SUCCESS';
export const GET_COMMISSIONS_SEARCH_FAIL = 'GET_COMMISSIONS_SEARCH_FAIL';


export const GET_COMMISSIONS_SUCCESS_ID = 'GET_COMMISSIONS_SUCCESS_ID';
export const GET_COMMISSIONS_FAIL_ID = 'GET_COMMISSIONS_FAIL_ID';


export const GET_COMMISSIONS_PAYMENTS_SUCCESS = 'GET_COMMISSIONS_PAYMENTS_SUCCESS';
export const GET_COMMISSIONS_PAYMENTS_FAIL = 'GET_COMMISSIONS_PAYMENTS_FAIL';

export const GET_COMMISSIONS_PAYMENTS_SUCCESS_ID = 'GET_COMMISSIONS_PAYMENTS_SUCCESS_ID';

export const GET_COMMISSIONS_PAYMENTS_FAIL_ID ='GET_COMMISSIONS_PAYMENTS_FAIL_ID'


export const GET_COMMISSIONS_PAYMENTS_SEARCH_SUCCESS = 'GET_COMMISSIONS_PAYMENTS_SEARCH_SUCCESS';

export const GET_COMMISSIONS_PAYMENTS_SEARCH_FAIL = 'GET_COMMISSIONS_PAYMENTS_SEARCH_FAIL';