import axios from "axios";
import {
	GET_COMMISSIONS_SUCCESS,
	GET_COMMISSIONS_FAIL,
	GET_COMMISSIONS_SEARCH_SUCCESS,
	GET_COMMISSIONS_SEARCH_FAIL,
	GET_COMMISSIONS_SUCCESS_ID,
	GET_COMMISSIONS_FAIL_ID,
	GET_COMMISSIONS_PAYMENTS_SUCCESS,
	GET_COMMISSIONS_PAYMENTS_FAIL,
	GET_COMMISSIONS_PAYMENTS_SUCCESS_ID,
	GET_COMMISSIONS_PAYMENTS_FAIL_ID,
	GET_COMMISSIONS_PAYMENTS_SEARCH_SUCCESS,
	GET_COMMISSIONS_PAYMENTS_SEARCH_FAIL,
} from "./types";

export const get_commissions = () => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions/list/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_FAIL,
		});
	}
};

export const get_commissions_page = (p) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions/list/?p=${p}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_FAIL,
			});
		}
	} catch {
		dispatch({
			type: GET_COMMISSIONS_FAIL,
		});
	}
};

export const search_commision = (search_term) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions/search/${search_term}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_SEARCH_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_SEARCH_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_SEARCH_FAIL,
		});
	}
};

export const get_commission = (id) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions/list/${id}/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_SUCCESS_ID,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_FAIL_ID,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_FAIL_ID,
		});
	}
};

export const get_commissions_payments =
	(table = null, rol = null, month = null, year = null, paid = null) =>
	async (dispatch) => {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		let url = `${process.env.REACT_APP_API_URL}/commissions-payments/list/`;
		let params = new URLSearchParams();
		if (paid !== null && paid !== "") {
			params.append("paid", paid);
		}
		if (table !== null && table !== "") {
			params.append("table", table);
		}
		if (rol !== null && rol !== "") {
			params.append("rol", rol);
		}
		if (month !== null && month !== "") {
			params.append("month", month);
		}
		if (year !== null && year !== "") {
			params.append("year", year);
		}
		url += `?${params.toString()}`;
		try {
			const res = await axios.get(url, config);
			if (res.status === 200) {
				dispatch({
					type: GET_COMMISSIONS_PAYMENTS_SUCCESS,
					payload: res.data,
				});
			} else {
				dispatch({
					type: GET_COMMISSIONS_PAYMENTS_FAIL,
				});
			}
		} catch (err) {
			dispatch({
				type: GET_COMMISSIONS_PAYMENTS_FAIL,
			});
		}
	};

export const get_commissions_payments_page = (p) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions-payments/list/?p=${p}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_PAYMENTS_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_PAYMENTS_FAIL,
			});
		}
	} catch {
		dispatch({
			type: GET_COMMISSIONS_PAYMENTS_FAIL,
		});
	}
};

export const search_commision_payments = (search_term) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions-payments/search/${search_term}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_PAYMENTS_SEARCH_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_PAYMENTS_SEARCH_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_PAYMENTS_SEARCH_FAIL,
		});
	}
};

export const get_commission_payments = (id) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commissions-payments/list/${id}/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_COMMISSIONS_PAYMENTS_SUCCESS_ID,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_COMMISSIONS_PAYMENTS_FAIL_ID,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_COMMISSIONS_PAYMENTS_FAIL_ID,
		});
	}
};
