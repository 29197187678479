import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import profileImg from "../../../assets/img/profile-img.png";
import { IconDiscountCheck, IconPencil } from "@tabler/icons-react";
import moment from "moment";
import Loading from "../../../components/Loading";
import bannerImg from "../../../assets/img/banner.png";
import TableCard from "../../../components/tables/TableCard";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	get_commissions_payments,
	get_commissions_payments_page,
} from "../../../redux/actions/commissions/commissions";
import CommissionsPaymentsList from "../../../components/userscommissions/CommissionsPaymentsList";

function Profile({
	get_commissions_payments,
	get_commissions_payments_page,
	commissions_payments,
	count,
}) {
	const user = useSelector((state) => state.auth.user);
	const [profileData, setProfileData] = useState(null);
	const [term, setTerm] = useState("");

	const getProfile = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/profile/`, {
				headers: {
					Accept: "application/json",
					Authorization: `JWT ${localStorage.getItem("access")}`,
				},
			})
			.then((res) => {
				console.log(res.data.data);
				setProfileData(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				// Handle any errors that occur during the request
			});
	};

	useEffect(() => {
		getProfile();
	}, []);

	useEffect(() => {
		get_commissions_payments();
	}, [get_commissions_payments]);

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Perfil de usuario</title>
			</Helmet>
			<div className="container mx-auto max-w-3xl relative">
				<div className="banner-profile">
					<img src={bannerImg} className="rounded-xl" alt="Banner profile" />
				</div>
				{user ? (
					<div className="flex flex-col items-center relative">
						<div className="flex flex-col md:flex-row md:justify-between lg:absolute -top-6 lg:-top-10 px-4 py-3 bg-white md:bg-transparent shadow-lg w-full rounded-b-lg md:shadow-none">
							<div className="profile-data flex flex-col md:flex-row items-center gap-4">
								<div className="relative drop-shadow-md">
									{user.department
										? (() => {
												switch (user.department) {
													case "SIAU":
														return (
															<div className="p-1 rounded-full overflow-hidden w-20 h-20 md:w-32 md:h-32 flex justify-center items-center bg-white">
																<div
																	className={
																		"p-1 rounded-full overflow-hidden w-16 h-16 md:w-28 md:h-28 flex justify-center items-center bg-yellow-500"
																	}
																>
																	<img
																		src={profileImg}
																		className="h-12 w-12 md:h-24 md:w-24"
																		alt="User profile"
																	/>
																</div>
															</div>
														);
													case "CONTABILIDAD":
														return (
															<div className="p-1 rounded-full overflow-hidden w-20 h-20 md:w-32 md:h-32 flex justify-center items-center bg-white">
																<div
																	className={
																		"p-1 rounded-full overflow-hidden w-16 h-16 md:w-28 md:h-28 flex justify-center items-center bg-[#0096C4]"
																	}
																>
																	<img
																		src={profileImg}
																		className="h-12 w-12 md:h-24 md:w-24"
																		alt="User profile"
																	/>
																</div>
															</div>
														);
													case "ADMINISTRACION":
														return (
															<div className="p-1 rounded-full overflow-hidden w-20 h-20 md:w-32 md:h-32 flex justify-center items-center bg-white">
																<div
																	className={
																		"p-1 rounded-full overflow-hidden w-16 h-16 md:w-28 md:h-28 flex justify-center items-center bg-[#00496B]"
																	}
																>
																	<img
																		src={profileImg}
																		className="h-12 w-12 md:h-24 md:w-24"
																		alt="User profile"
																	/>
																</div>
															</div>
														);
													case "REPRESENTANTES":
														return (
															<div className="p-1 rounded-full overflow-hidden w-20 h-20 md:w-32 md:h-32 flex justify-center items-center bg-white">
																<div
																	className={
																		"p-1 rounded-full overflow-hidden w-16 h-16 md:w-28 md:h-28 flex justify-center items-center bg-green-500"
																	}
																>
																	<img
																		src={profileImg}
																		className="h-12 w-12 md:h-24 md:w-24"
																		alt="User profile"
																	/>
																</div>
															</div>
														);
													default:
														return (
															<div className="p-1 rounded-full overflow-hidden w-20 h-20 md:w-32 md:h-32 flex justify-center items-center bg-white">
																<div
																	className={
																		"p-1 rounded-full overflow-hidden w-16 h-16 md:w-28 md:h-28 flex justify-center items-center bg-red-500"
																	}
																>
																	<img
																		src={profileImg}
																		className="h-12 w-12 md:h-24 md:w-24"
																		alt="User profile"
																	/>
																</div>
															</div>
														);
												}
										  })()
										: "Sin Especificar"}

									<div>
										{user && user.verified ? (
											<div className="bg-green-500 rounded-full p-0.5 absolute bottom-2.5 right-2.5">
												<IconDiscountCheck className="text-white" />
											</div>
										) : (
											<div className="bg-green-500 rounded-full p-0.5 absolute bottom-2.5 right-2.5 hidden">
												<IconDiscountCheck className="text-white" />
											</div>
										)}
									</div>
								</div>
								<div className="flex flex-col items-center md:items-start">
									<h2 className="font-bold text-lg uppercase text-[#00496B]">
										{user.first_name + " " + user.last_name}
									</h2>
									<h3 className="text-sm uppercase text-[#00496B]">
										{user.username}
									</h3>
									<h4 className="font-thin text-sm uppercase text-[#00496B]">
										{user.department} / {user.role}
									</h4>
								</div>
							</div>
							<div className="flex items-center justify-center md:justify-end gap-x-2">
								<div>
									<span className="font-bold text-sm text-[#00496B]">
										Registrado:{" "}
									</span>
									<span className="text-sm text-[#00496B]">
										{moment(user.date_joined).format("DD/MM/YYYY")}
									</span>
								</div>
								<div className="flex tooltip" data-tip="Editar">
									<Link
										to={`/edit-users/${user.id}`}
										className="p-1 text-white bg-yellow-500 rounded-full"
									>
										<IconPencil />
									</Link>
								</div>
							</div>
						</div>
						{user.role === "REPRESENTANTES" ||
						user.role === "SUPERVISOR" ||
						user.role === "COORDINADOR JUNIOR" ||
						user.role === "COORDINADOR EJECUTIVO" ||
						user.role === "SUBDIRECTOR" ||
						user.role === "DIRECTOR" ||
						user.role === "ENLACE" ||
						user.role === "BANCO" ||
						user.role === "COSTOS Y GASTOS" ||
						user.role === "IMPUESTOS" ? (
							<>
								<div className="mt-5 lg:mt-32 w-full">
									<div className="flex justify-center">
										<span className="font-bold text-center text-[#00496B]">
											Mesas asignadas
										</span>
									</div>
									<div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-3">
    {Array.isArray(profileData) ? (
        profileData.map((item) => (
            <TableCard
                table={item.table}
                clientCount={item.client_count}
                clientLevel={item.client_level}
                key={item.table}
            />
        ))
    ) : (
        <p>No data available</p>
    )}
</div>

								</div>
								<div className="mt-8 w-full">
									<div className="flex justify-center mb-2">
										<span className="font-bold text-center text-[#00496B]">
											Detalles de comisiones
										</span>
									</div>
									<div className="py-2">
										{commissions_payments && commissions_payments.length > 0 ? (
											<CommissionsPaymentsList
												commissions={commissions_payments}
												get_commissions_page={get_commissions_payments_page}
												count={count}
											/>
										) : (
											<div className="flex items-center justify-center h-96">
												<p className="text-gray-500 text-2xl">
													No hay comisiones por pagar
												</p>
											</div>
										)}
									</div>
								</div>
							</>
						) : null}
					</div>
				) : (
					<div className="w-full h-full flex justify-center items-center">
						<Loading />
					</div>
				)}
			</div>
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	commissions_payments: state.commissions.commissions_payments,
	count: state.commissions.count,
});

export default connect(mapStateToProps, {
	get_commissions_payments,
	get_commissions_payments_page,
})(Profile);
