import SmallSetPagination from "../pagination/SmallSetPagination";
import CommissionsCardHorizontal from "./CommissionsCardHorizontal";

function CommissionsList({ commissions_entreprises, get_commissions_page_enterprise, count }) {

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2">
                Datos de Empresa
              </th>
							<th scope="col" className="p-2">
                Monto
              </th>
							<th scope="col" className="p-2">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {commissions_entreprises &&
							commissions_entreprises.map((commissions_entreprises, index) => (
								<CommissionsCardHorizontal
									data={commissions_entreprises}
									key={index}
									index={index}
								/>
							))}
          </tbody>
        </table>
      </div>
      <SmallSetPagination
        list_page={get_commissions_page_enterprise}
				list={commissions_entreprises}
				count={count}
      />
    </div>
  );
}

export default CommissionsList;
