import {
	GET_USER_LIST_SUCCESS,
	GET_USER_LIST_FAIL,
	GET_USER_SUCCESS,
	GET_USER_FAIL,
	GET_USER_SEARCH_SUCCESS,
	GET_USER_SEARCH_FAIL,
} from "../actions/user/types";

const initialState = {
  user: null,
  users: null,
  filtered_user: null,
  count: null,
  next: null,
  previous: null,
};

export default function users(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        users: payload.results.users,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_USER_LIST_FAIL:
      return {
        ...state,
        users: null,
        count: null,
        next: null,
        previous: null,
      };
		case GET_USER_SUCCESS:
			return {
				...state,
				user: payload.user,
			};
		case GET_USER_FAIL:
			return {
				...state,
				user: null,
			};
		case GET_USER_SEARCH_SUCCESS:
			return {
				...state,
				filtered_user: payload.results,
			};
		case GET_USER_SEARCH_FAIL:
			return {
				...state,
				filtered_user: null,
			};
		default:
			return state;
		}}