import SmallSetPagination from "../pagination/SmallSetPagination";
import CommissionsCardHorizontal from "./CommissionsCardHorizontal";


function CommissionsListSearch({ commissions }) {

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
					<tr>
              <th scope="col" className="p-2">
                Data
              </th>
							<th scope="col" className="p-2">
                Bonos Principales
              </th>
							<th scope="col" className="p-2">
                Bonos Final de Linea
              </th>

							<th scope="col" className="p-2">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {commissions &&
              commissions.map((commission, index) => (
                <CommissionsCardHorizontal
                  data={commission}
                  index={index}
                />
              ))}
          </tbody>
        </table>
      </div>
      
    </div>
  );
}
export default CommissionsListSearch;
