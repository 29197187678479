import latirLogo from "../../assets/img/logo.png"
import { IconBrandX, IconBrandInstagram, IconBrandFacebook } from "@tabler/icons-react"
import { Link } from "react-router-dom"

function Footer() {
	return (
		<footer className="p-4 bg-[#00496B] text-white">
			<div className="container mx-auto max-w-5xl">
				<div className="footer items-center justify-center md:justify-between">
					<div className="w-full flex justify-center">
						<img src={latirLogo} alt="logo latir" className="w-44" />
					</div>
					<div className="w-full flex justify-center">
						<p className="text-center md:text-left">Latir, latimos por tu bienestar © 2024. Todos los derechos reservados</p>
					</div>
					<div className="w-full flex justify-center">
						<div className="flex gap-2">
							<Link to="#" className="p-2 bg-white text-[#00496B] rounded-full" title="X">
								<IconBrandX size={22} strokeWidth={2} />
							</Link>
							<Link to="#" className="p-2 bg-white text-[#00496B] rounded-full" title="Instagram">
								<IconBrandInstagram size={22} strokeWidth={2} />
							</Link>
							<Link to="#" className="p-2 bg-white text-[#00496B] rounded-full" title="Facebook">
								<IconBrandFacebook size={22} strokeWidth={2} />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
export default Footer;
