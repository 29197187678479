import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	get_client_list,
	get_client_list_page,
} from "../../../redux/actions/client/client";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import ClientsList from "../../../components/client/ClientsList";
import Loading from "../../../components/Loading";
import { IconFilter } from "@tabler/icons-react";

function Clients({
	get_client_list,
	get_client_list_page,
	clients,
	count,
	clients_per_table,
}) {
	const user = useSelector((state) => state.auth.user);

	const [isAllTablesFull, setIsAllTablesFull] = useState(false);
	const [active, setActive] = useState("");
	const [table, setTable] = useState("");
	const [tokenVerified, setTokenVerified] = useState("");
	const [plans, setPlans] = useState("");
	const [vip, setVip] = useState("");
	const [corpoindustrial, setCorpoindustrial] = useState("");
	const [estrato, setEstrato] = useState("");
	const [beneficiarios, setBeneficiarios] = useState("");
	useEffect(() => {
		if (
			active !== "" ||
			table !== "" ||
			tokenVerified !== "" ||
			plans !== "" ||
			vip !== "" ||
			corpoindustrial !== "" ||
			estrato !== "" ||
			beneficiarios !== ""
		) {
			get_client_list(
				active,
				table,
				tokenVerified,
				plans,
				vip,
				corpoindustrial,
				estrato,
				beneficiarios
			);
		} else {
			get_client_list();
		}
	}, [
		get_client_list,
		active,
		table,
		tokenVerified,
		plans,
		vip,
		corpoindustrial,
		estrato,
		beneficiarios,
	]);

	useEffect(() => {
		if (user && user.table && clients_per_table) {
			const isEveryTableFull = user.table.every((userTable) =>
				clients_per_table.some(
					(clientTable) =>
						parseInt(clientTable.table) === parseInt(userTable.table) &&
						clientTable.level === 4 &&
						clientTable.total === 5
				)
			);

			setIsAllTablesFull(isEveryTableFull);
		}
	}, [user, clients_per_table]);

	const [term, setTerm] = useState("");

	const handleChange = (e) => {
		setTerm(e.target.value);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setTimeout(() => (window.location.href = "/search/clients/" + term), 0.2);
		setTerm("");
	};

	const handleChangeActive = (e) => {
		setActive(e.target.value);
	};

	const handleChangeTable = (e) => {
		setTable(e.target.value);
	};

	const handleChangeTokenVerified = (e) => {
		setTokenVerified(e.target.value);
	};

	const handleChangePlans = (e) => {
		setPlans(e.target.value);
	};
	const handleChangeVip = (e) => {
		setVip(e.target.value);
	};
	const handleChangeCorpoindustrial = (e) => {
		setCorpoindustrial(e.target.value);
	};
	const handleChangeEstrato = (e) => {
		setEstrato(e.target.value);
	};

	const handleChangeBeneficiarios = (e) => {
		setBeneficiarios(e.target.value);
	};

	const tableValues =
		useSelector((state) => state.auth.user?.table?.map((item) => item.table)) ||
		[];

		const refreshData = () => {
			get_client_list();
		}
	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Clientes</title>
			</Helmet>
			<div className="border-b border-gray-200 bg-white py-5 sm:px-6">
				<div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Lista de titulares
						</h3>
					</div>
					<div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">

						<div className="flex justify-between gap-4">
							<Link to="/create-clients">
								<button className="bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-slate-700">
									Agregar titular
								</button>
							</Link>
							<Link to="/create-referred">
								<button className="bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-slate-700">
									Agregar referido
								</button>
							</Link>
						</div>
						<div className="">
							<div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
								<form onSubmit={(e) => onSubmit(e)} className="w-full">
									<label htmlFor="search" className="sr-only">
										Search
									</label>
									<div className="relative">
										<button
											type="submit"
											className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
												/>
											</svg>
										</button>
										<input
											id="search"
											name="search"
											required
											onChange={(e) => {
												handleChange(e);
											}}
											className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											placeholder="Buscar"
											type="search"
										/>
									</div>
								</form>
							</div>
						</div>
						<div className="dropdown dropdown-end">
							<div
								tabIndex={0}
								role="button"
								className="p-1.5 bg-white border border-[#0096C4] rounded"
							>
								<IconFilter stroke="#0096C4" />
							</div>
							<div
								tabIndex={0}
								className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
							>
								<div className="flex flex-col gap-y-2">
									{user && user.role === "ENLACE" && (
										<label className="form-control w-full max-w-xs">
											<div className="label">
												<span className="label-text">Activo / Inactivo</span>
											</div>
											<select
												onChange={handleChangeActive}
												value={active}
												className="select select-bordered w-full"
											>
												<option value="">Elige una opción</option>
												<option value="true">Activo</option>
												<option value="false">Inactivo</option>
											</select>
										</label>
									)}
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Mesa</span>
										</div>
										<select
											onChange={handleChangeTable}
											value={table}
											className="select select-bordered w-full"
										>
											<option value="">Elige una mesa</option>
											{tableValues &&
												tableValues.map((value) => (
													<option key={value} value={value}>
														Mesa {value}
													</option>
												))}
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Verificado</span>
										</div>
										<select
											onChange={handleChangeTokenVerified}
											value={tokenVerified}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value="true">Token Verificado</option>
											<option value="false">Token No Verificado</option>
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Plan</span>
										</div>
										<select
											onChange={handleChangePlans}
											value={plans}
											className="select select-bordered w-full"
										>
											<option value="">Elige un Plan</option>
											<option value="CLASSIC">Estandar</option>
											<option value="PLUS">Plus</option>
											<option value="PREMIUM">Premium</option>
											<option value="PLATINUM">Platinum</option>
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">VIP</span>
										</div>
										<select
											onChange={handleChangeVip}
											value={vip}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value="true">VIP</option>
											<option value="false">No VIP</option>
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Corpoindustrial</span>
										</div>
										<select
											onChange={handleChangeCorpoindustrial}
											value={corpoindustrial}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value="true">Pertenece a Corpoindustrial</option>
											<option value="false">
												No pertenece a Corpoindustrial
											</option>
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Estrato</span>
										</div>
										<select
											onChange={handleChangeEstrato}
											value={estrato}
											className="select select-bordered w-full"
										>
											<option value="">Elige el Estrato</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
											<option value="6">6</option>
										</select>
									</label>

									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Beneficiarios</span>
										</div>
										<select
											onChange={handleChangeBeneficiarios}
											value={beneficiarios}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value="0">Sin beneficiarios</option>
											<option value="1">Con 1 beneficiario</option>
											<option value="2">Con 2 beneficiarios</option>
											<option value="3">Con 3 beneficiarios</option>
										</select>
										</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{clients && clients ? (
				<ClientsList
					clients={clients}
					get_client_list={get_client_list}
					get_client_list_page={get_client_list_page}
					count={count}
					refreshData={refreshData}
				/>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
		</DashboardLayout>
	);
}
const mapStateToProps = (state) => ({
	clients: state.clients.clients,
	count: state.clients.count,
	clients_per_table: state.clients.clients_per_table,
});
export default connect(mapStateToProps, {
	get_client_list,
	get_client_list_page,
})(Clients);
