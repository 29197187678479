import React from 'react'

function ProgressBar({ value, max, bg }) {

  return (
    <div className="flex items-center w-3/5">
      <div className="w-full bg-gray-200 rounded-full relative overflow-hidden">
        <div className={`${bg} h-4`} style={{ width: `${(value / max) * 100}%` }}>
          <div className="whitespace-nowrap text-[11px] text-center absolute w-full">{`${value} / ${max}`}</div>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar