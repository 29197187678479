import {
	GET_ENTERPRISE_LIST_SUCCESS,
	GET_ENTERPRISE_LIST_FAIL,
	GET_ENTERPRISE_SUCCESS,
	GET_ENTERPRISE_FAIL,
	GET_ENTERPRISE_SEARCH_SUCCESS,
	GET_ENTERPRISE_SEARCH_FAIL
} from "./types";
import axios from "axios";

export const get_enterprise_list = (corpoindustrial=null) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	let url = `${process.env.REACT_APP_API_URL}/enterprise/list/`;
	let params = new URLSearchParams();

	if (corpoindustrial !== null && corpoindustrial !== "") {
		params.append('corpoindustrial', corpoindustrial);
	}
	url += `?${params.toString()}`;
	try {
		const res = await axios.get(
			url,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_ENTERPRISE_LIST_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_ENTERPRISE_LIST_FAIL,
			});
		}
	} catch {
		dispatch({
			type: GET_ENTERPRISE_LIST_FAIL,
		});
	}
}

export const get_enterprise_list_page = (p) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/enterprise/list/?p=${p}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_ENTERPRISE_LIST_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_ENTERPRISE_LIST_FAIL,
			});
		}
	}
	catch {
		dispatch({
			type: GET_ENTERPRISE_LIST_FAIL,
		});
	}
}

export const get_enterprise = (id) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/enterprise/${id}/`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_ENTERPRISE_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_ENTERPRISE_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_ENTERPRISE_FAIL,
		});
	}
}

export const get_enterprise_search = (query) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/enterprise/search/?search=${query}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_ENTERPRISE_SEARCH_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_ENTERPRISE_SEARCH_FAIL,
			});
		}
	} catch {
		dispatch({
			type: GET_ENTERPRISE_SEARCH_FAIL,
		});
	}
}
