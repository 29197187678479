import {
	GET_COMMISSIONS_SUCCESS,
	GET_COMMISSIONS_FAIL,
	GET_COMMISSIONS_SEARCH_SUCCESS,
	GET_COMMISSIONS_SEARCH_FAIL,
	GET_COMMISSIONS_SUCCESS_ID,
	GET_COMMISSIONS_FAIL_ID,

	GET_COMMISSIONS_PAYMENTS_SUCCESS,
	GET_COMMISSIONS_PAYMENTS_FAIL,
	GET_COMMISSIONS_PAYMENTS_SUCCESS_ID,
	GET_COMMISSIONS_PAYMENTS_FAIL_ID,
	GET_COMMISSIONS_PAYMENTS_SEARCH_SUCCESS,
	GET_COMMISSIONS_PAYMENTS_SEARCH_FAIL
} from "../actions/commissions/types";


const initialState = {
	count : null,
	next : null,
	previous : null,
	commissions : null,
	commission : null,
	commissions_payments : null,
	commissions_payment : null,
};

export default function commissions(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_COMMISSIONS_SUCCESS:
			return {
				...state,
				commissions: payload.results,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_FAIL:
			return {
				...state,
				commissions: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_COMMISSIONS_SEARCH_SUCCESS:
			return {
				...state,
				commissions: payload.filtered_comissions,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_SEARCH_FAIL:
			return {
				...state,
				commissions: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_COMMISSIONS_SUCCESS_ID:
			return {
				...state,
				commission: payload,
			};
		case GET_COMMISSIONS_FAIL_ID:
			return {
				...state,
				commission: null,
			};
		case GET_COMMISSIONS_PAYMENTS_SUCCESS:
			return {
				...state,
				commissions_payments: payload.results,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_PAYMENTS_FAIL:
			return {
				...state,
				commissions_payments: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_COMMISSIONS_PAYMENTS_SUCCESS_ID:
			return {
				...state,
				commissions_payment: payload,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_PAYMENTS_FAIL_ID:
			return {
				...state,
				commissions_payment: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_COMMISSIONS_PAYMENTS_SEARCH_SUCCESS:
			return {
				...state,
				commissions_payments: payload.filtered_comissions,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_PAYMENTS_SEARCH_FAIL:
			return {
				...state,
				commissions_payments: null,
				count: null,
				next: null,
				previous: null,
			};
			
		default:
			return state;
	}
}