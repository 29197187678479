import { Helmet } from "react-helmet-async";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useState, useEffect } from "react";
import Toast from "../../../components/Alert";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CircleLoader } from "react-spinners";
import { connect } from "react-redux";
import { get_enterprise } from "../../../redux/actions/enterprises/enterprises";

function EnterpriseEdit({ get_enterprise, enterprise }) {
	const [loading, setLoading] = useState(false);
	const [thumbnailRut, setThumbnailRut] = useState(null);
	const [previewImage, setPreviewImage] = useState();
	const [formData, setFormData] = useState({
		document_type: "",
		nuip: "",
		first_name: "",
		last_name: "",
		name_enterprise: "",
		nit: "",
		address: "",
		rut_document: "",
		corpoindustrial: "",
	});

	const {
		document_type,
		nuip,
		first_name,
		last_name,
		name_enterprise,
		nit,
		address,
		corpoindustrial,
	} = formData;

	const navigate = useNavigate();
	const { uid } = useParams();

	useEffect(() => {
		get_enterprise(uid);
	}, [get_enterprise, uid]);

	useEffect(() => {
		if (enterprise) {
			setFormData({
				document_type: enterprise.document_type,
				nuip: enterprise.nuip,
				first_name: enterprise.legal_representative.split(" ")[0],
				last_name: enterprise.legal_representative.split(" ")[1],
				name_enterprise: enterprise.name_enterprise,
				nit: enterprise.nit,
				address: enterprise.address,
				rut_document: enterprise.rut_document,
				corpoindustrial: enterprise.corpoindustrial ? "SI" : "NO",
			});
		}
	}, [enterprise]);

	const fileSelectedHandler = (e) => {
		const file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			setPreviewImage(reader.result);
		};
		setThumbnailRut(file);
	};

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (nuip) {
			setLoading(true);
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `JWT ${localStorage.getItem("access")}`,
					Accept: "application/json",
				},
			};

			const form = new FormData();
			form.append("document_type", document_type);
			form.append("nuip", nuip);
			form.append("legal_representative", first_name + " " + last_name);
			form.append("name_enterprise", name_enterprise);
			form.append("nit", nit);
			form.append("address", address);
			if (thumbnailRut) {
				form.append("rut_document", thumbnailRut);
			}
			form.append("corpoindustrial", corpoindustrial === "SI" ? true : false);

			const fetchData = async () => {
				try {
					const response = await axios.put(
						`${process.env.REACT_APP_API_URL}/enterprise/update/${uid}/`,
						form,
						config
					);
					if (response.status === 200) {
						setLoading(false);
						Toast({
							tipo: "success",
							mensaje: "¡Empresa Actualizada Correctamente!",
						});
						navigate("/enterprises");
					}
				} catch (error) {
					setLoading(false);
					Toast({
						tipo: "error",
						mensaje: "¡Error al actualizar la empresa!",
					});
				}
			};
			fetchData();
		}
	};

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Editar empresa</title>
			</Helmet>
			<div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
				<h1 className="font-bold text-3xl text-gray-900">Editar empresa</h1>
				<form onSubmit={(e) => onSubmit(e)} className="">
					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Datos del Representante Legal
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="document_type" className="label">
								<span className="label-text">Tipo de documento</span>
							</label>
							<select
								name="document_type"
								value={document_type}
								onChange={(e) => onChange(e)}
								required
								className="select select-bordered w-full"
							>
								<option value="">Selecciona el tipo de documento</option>
								<option value="CC">Cédula de Ciudadanía</option>
								<option value="CE">Cédula de Extranjería</option>
								<option value="PA">Pasaporte</option>
								<option value="TI">Tarjeta de Identidad</option>
								<option value="RC">Registro Civil</option>
								<option value="PE">Permiso Especial de Permanencia</option>
								<option value="PT">Permiso Temporal de Permanencia</option>
							</select>
						</div>

						<div className="form-control">
							<label htmlFor="nuip" className="label">
								<span className="label-text">Docuemento de Identidad</span>
							</label>
							<input
								type="text"
								name="nuip"
								value={nuip}
								onChange={(e) => onChange(e)}
								required
								minLength={6}
								maxLength={11}
								pattern="[0-9]+"
								className="input input-bordered w-full"
								placeholder="Docuemento de Identidad"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="first_name" className="label">
								<span className="label-text">Nombres</span>
							</label>
							<input
								type="text"
								name="first_name"
								value={first_name}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Nombres"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="last_name" className="label">
								<span className="label-text">Apellidos</span>
							</label>
							<input
								type="text"
								name="last_name"
								value={last_name}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full "
								placeholder="Apellidos"
							/>
						</div>
					</div>

					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Datos de la empresa
					</div>

					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="name_enterprise" className="label">
								<span className="label-text">Nombre de la empresa</span>
							</label>
							<input
								type="text"
								name="name_enterprise"
								value={name_enterprise}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Nombre de la empresa"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="nit" className="label">
								<span className="label-text">Nit</span>
							</label>
							<input
								type="text"
								name="nit"
								value={nit}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Nit"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="address" className="label">
								<span className="label-text">Dirección</span>
							</label>
							<input
								type="text"
								name="address"
								value={address}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Dirección"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="rut_document" className="label">
								<span className="label-text">Documento RUT</span>
							</label>
							<input
								type="file"
								name="rut_document"
								accept="image/*,application/pdf"
								onChange={(e) => fileSelectedHandler(e)}
								className="file-input file-input-bordered w-full"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="corpoindustrial" className="label">
								<span className="label-text">Corpoindustrial</span>
							</label>
							<select
								name="corpoindustrial"
								value={corpoindustrial}
								onChange={(e) => onChange(e)}
								required
								className="select select-bordered w-full"
							>
								<option value="">Selecciona una opción</option>
								<option value="SI">SI</option>
								<option value="NO">NO</option>
							</select>
						</div>
					</div>
					<div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
						{loading ? (
							<div className="btn border bg-[#0096C4] text-white">
								<CircleLoader loading={loading} size={25} color="#ffffff" />
							</div>
						) : (
							<button
								type="submit"
								className="btn border bg-[#0096C4] text-white"
							>
								Guardar
							</button>
						)}
					</div>
				</form>
			</div>
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	enterprise: state.enterprise.enterprise,
});

export default connect(mapStateToProps, { get_enterprise })(EnterpriseEdit);
