import { Dialog, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";


function ViewModal({ data, title , icon}) {
	const [isImageDialogOpen, setImageDialogOpen] = useState(false);
	const [currentImageUrl, setCurrentImageUrl] = useState("");

	function openImageDialog(imageUrl) {
		setCurrentImageUrl(imageUrl);
		setImageDialogOpen(true);
	}

	return (
		<>
			<div className="flex gap-2">
				<div className="tooltip flex" data-tip={title}>
					<button
							className="p-1 bg-blue-500 text-white rounded-full"
							onClick={() =>
								openImageDialog(
									`${process.env.REACT_APP_API_URL}${data}`
								)
							}
						>
							{icon}
						</button>
				</div>
			</div>
			{isImageDialogOpen && (
				<Transition.Root show={isImageDialogOpen} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setImageDialogOpen}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
										<div>
											{currentImageUrl.endsWith(".pdf") ? (
												<div className="mt-3 text-center sm:mt-5">
													<embed src={currentImageUrl} type="application/pdf" width="100%" height="500px" />
												</div>
											) : (
												<div className="mt-3 text-center sm:mt-5">
													<img src={currentImageUrl} alt="Imagen de Pago" />
												</div>
											)}
										</div>
										<div className="mt-5 flex justify-center sm:mt-6">
											<button
												type="button"
												className="btn border border-[#0096C4] bg-white text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B]"
												onClick={() => setImageDialogOpen(false)}
											>
												Cerrar
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			)}
		</>
	);
}
export default ViewModal;
