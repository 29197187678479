import { legacy_createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};

const middleware = [thunk];

  const middlewareReduxTools = window.location.protocol === "https:" ?  applyMiddleware(...middleware) : composeWithDevTools(applyMiddleware(...middleware));

const store = legacy_createStore(
  rootReducer,
  initialState,
  middlewareReduxTools
);

export default store;
