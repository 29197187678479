import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import MyDocument from "../../../components/pdf/ReportClient";
import { PDFViewer } from "@react-pdf/renderer";
import { get_client } from "../../../redux/actions/client/client";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";

function ClientReport({ client, get_client }) {
	const params = useParams();
	const id = params.id;

	useEffect(() => {
		window.scrollTo(0, 0);
		get_client(id);
	}, [get_client, id]);
	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Reporte de cliente</title>
			</Helmet>
			<div className="container mx-auto max-w-5xl">
				{client && client ? (
					<>
						<div className="">
							<PDFViewer className="w-full h-[75vh] md:h-[85vh]">
								<MyDocument data={client} />
							</PDFViewer>
						</div>
					</>
				) : (
					<div className="w-full h-full flex justify-center items-center">
						<Loading />
					</div>
				)}
			</div>
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	client: state.clients.client,
});
export default connect(mapStateToProps, {
	get_client,
})(ClientReport);
