import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Alert";
import { Helmet } from "react-helmet-async";
import HomeLayout from "../../hocs/layout/HomeLayout";
import logo from "../../assets/img/logo.png";
function ActivateAccount() {
	const params = useParams();
	const uid = params.uid;
	const token = params.token;

	const navigate = useNavigate();

	const onClick = async () => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/auth/users/activation/`,
				{ uid, token }
			);
			if (res.status === 204) {
				Toast({ tipo: "success", mensaje: "Cuenta activada correctamente" });
				navigate("/login");
			}
		} catch (error) {
			if (
				error.response &&
				error.response.status === 403 &&
				error.response.data.detail &&
				error.response.data.detail.includes("El token del usuario ha expirado.")
			)
				Toast({ tipo: "error", mensaje: "El token del usuario ha expirado." });
			else {
				Toast({ tipo: "error", mensaje: "Error al activar la cuenta" });
			}
		}
	};

	return (
		<HomeLayout>
			<Helmet>
				<title>Latir | Activar cuenta</title>
			</Helmet>
			<div className="flex h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
				<div className="w-full max-w-md space-y-8">
					<div>
						<img
							className="mx-auto h-12 w-auto"
							src={logo}
							alt="....."
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Activar cuenta
						</h2>
					</div>
					<div className="mt-8 space-y-6">
						<div className="rounded-md shadow-sm -space-y-px">
							<button
								onClick={onClick}
								className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#0096C4] hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
							>
								Activar cuenta
							</button>
						</div>
					</div>
				</div>
			</div>
		</HomeLayout>
	);
}
export default ActivateAccount;
