import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { useState, Fragment } from "react";
import Toast from "../Alert";
import CircleLoader from "react-spinners/CircleLoader";
import { 
  IconPhotoUp,
} from '@tabler/icons-react';
import { Link } from "react-router-dom";

function UploadModal({ setOpenImg, openImg, data }) {
	const [loading, setLoading] = useState(false);
	const [previewImage, setPreviewImage] = useState();
	const [thumbnail, setThumbnail] = useState(null);
	const [formData, setFormData] = useState();

	const handleUpload = () => {
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
				Accept: "application/json",
			},
		};
		const formData = new FormData();
		if (thumbnail) {
			formData.append("img_payment", thumbnail, thumbnail.name);
		} else {
			formData.append("img_payment", "");
		}
		formData.append("client", data.id);
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/payment/edit/${data.id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						img_payment: "",
					});
					setLoading(false);
					setOpenImg(false);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					setLoading(false);

					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				setLoading(false);

				Toast({ tipo: "error", mensaje: "Error al editar la categoria" });
			}
		};
		fetchData();
	};

	const fileSelectedHandler = (e) => {
		const file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			setPreviewImage(reader.result);
		};
		setThumbnail(file);
	};

	return (
		<>
			<div className="tooltip flex" data-tip="Cargar Pago">
				<Link
					onClick={() => setOpenImg(true)}
					className="p-1 bg-green-500 text-white rounded-full cursor-pointer"
				>
					<IconPhotoUp />
				</Link>
			</div>
			<Transition.Root show={openImg} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setOpenImg}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
								<div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        <span>Cargue el recibo de pago o capture</span>
                      </Dialog.Title>
                    </div>
                  </div>
									<div>
										<label htmlFor="img_payment" className="label">
											<span className="label-text"></span>
										</label>
										<input
											type="file"
											name="img_payment"
											onChange={fileSelectedHandler}
											className="file-input file-input-bordered w-full"
										/>
									</div>
									<div className="flex justify-center mt-4 space-x-3">
									<button
											type="button"
											className="btn border border-[#0096C4] bg-white text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B]"
											onClick={() => setOpenImg(false)}
										>
											Cerrar
										</button>

										{loading ? (
											<div className="relative inline-flex items-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
												<CircleLoader
													loading={loading}
													size={25}
													color="#ffffff"
												/>
											</div>
										) : (
											<button
												onClick={handleUpload}
												className="btn border bg-[#0096C4] hover:bg-[#00496B] text-white"
											>
												Guardar
											</button>
										)}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}

export default UploadModal;
