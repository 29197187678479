import React from "react";
import { Link } from "react-router-dom";
import { IconPencil, IconFileDescription } from "@tabler/icons-react";
import CommissionEditModal from "../modals/CommissionEditModal";

function CommissionsPaymentsCardHorizontal({ data, index }) {
	// Parsea `commission_amount` a un objeto JavaScript.
	let commissionAmountDetails = {};
	try {
		commissionAmountDetails = data && JSON.stringify(data.commission_amount);
	} catch (e) {
		console.error("Error al parsear los datos de comisión", e);
	}
	return (
		<React.Fragment key={index}>
			<tr className="bg-white border-b">
				<td className="p-2 text-xs">
					<div className="w-44">
						{data.name}
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="w-56">
						<div className="font-bold">Comisión:</div>
						<div>
							<span className="font-bold">Monto: </span>{data.total}
						</div>
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="w-24">
						<span className="font-bold">Pagado: </span>
						<span className="text-green-500">{data.paid ? "Si" : "No"}</span>
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="flex gap-2">
						{!data.paid ? <CommissionEditModal id={data.id} isEnterprise={true} /> : null}
						<div className="tooltip flex" data-tip="Generar Reporte">
							<Link
								className="p-1 bg-teal-400 text-white rounded-full"
								to={`/commissions-report/enterprises/${data.id}`}
								target="_blank"
							>
								<IconFileDescription />
							</Link>
						</div>
					</div>
				</td>
			</tr>
		</React.Fragment>
	);
}
export default CommissionsPaymentsCardHorizontal;
