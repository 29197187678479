import {
	SEARCH_SUCCESS,
	SEARCH_ERROR
} from "./types"

import axios from "axios";

export const get_search = (nuip) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/search/?nuip=${nuip}`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: SEARCH_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: SEARCH_ERROR,
			});
		}
	} catch (error) {
		if (error.response && error.response.status === 404) {
			// Mostrar mensaje de error para 404
			dispatch({
				type: SEARCH_ERROR,
				payload: "No se encontró ningún resultado para el número ingresado.",
			});
		} else {
			// Otros errores
			dispatch({
				type: SEARCH_ERROR,
				payload: "Error al realizar la búsqueda. Inténtelo de nuevo más tarde.",
			});
		}
	}
};
