import { useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Toast from "../../../components/Alert";
import { Helmet } from "react-helmet-async";
import { CircleLoader } from "react-spinners";

function UsersForm() {
	const user = useSelector((state) => state.auth.user);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		document_type: "",
		nuip: "",
		email: "",
		username: "",
		first_name: "",
		last_name: "",
		password: "",
		re_password: "",
	});

	const {
		document_type,
		nuip,
		email,
		first_name,
		last_name,
		password,
		re_password,
	} = formData;


	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (nuip) {
			setLoading(true);
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `JWT ${localStorage.getItem("access")}`,
					Accept: "application/json",
				},
			};
			const username = email;
			const formData = new FormData();
			formData.append("document_type", document_type);
			formData.append("nuip", nuip);
			formData.append("email", email);
			formData.append("username", username);
			formData.append("first_name", first_name);
			formData.append("last_name", last_name);
			formData.append("password", password);
			formData.append("re_password", re_password);

			const fetchData = async () => {
				try {
					const res = await axios.post(
						`${process.env.REACT_APP_API_URL}/auth/users/`,
						formData,
						config
					);

					if (res.status === 201) {
						setLoading(false);
						setFormData({
							document_type: "",
							nuip: "",
							email: "",
							username: "",
							first_name: "",
							last_name: "",
							password: "",
							re_password: "",
						});
						Toast({
							tipo: "success",
							mensaje: "¡Titular Registrado Correctamente!",
						});
						navigate("/team");
					}
				} catch (error) {
					console.log(error.response);
					if (
						error.response &&
						error.response.status === 400 &&
						error.response.data.email &&
						error.response.data.email.includes('Ya existe user account con este email.')
					) {
						Toast({
							tipo: "error",
							mensaje: "Ya existe un titular registrado con este E-mail.",
						});
					}
					else if (
						error.response &&
						error.response.status === 400 &&
						error.response.data.username &&
						error.response.data.username.includes(
							"Ya existe user account con este username."
						)
					) {
						Toast({
							tipo: "error",
							mensaje:
								"Ya existe un titular registrado con este nombre de usuario.",
						});
					} else if (
						error.response &&
						error.response.status === 400 &&
						error.response.data.nuip &&
						error.response.data.nuip.includes(
							"Ya existe user account con este nuip."
						)
					) {
						Toast({
							tipo: "error",
							mensaje:
								"Ya existe un titular registrado con este numero de documento.",
						});
					} else if (
						error.response &&
						error.response.status === 400 &&
						error.response.data.non_field_errors &&
						error.response.data.non_field_errors.includes(
							"El contenido de los dos campos de contraseña no coincide."
						)
					) {
						Toast({
							tipo: "error",
							mensaje:
								"El contenido de los dos campos de contraseña no coincide.",
						});
					} else if (
						error.response &&
						error.response.status === 400 &&
						error.response.data.password &&
						Array.isArray(error.response.data.password) &&
						error.response.data.password.length > 0
					) {
						Toast({ tipo : "error", mensaje : error.response.data.password[0] })
					} else {
						Toast({
							tipo: "error",
							mensaje: "Ha ocurrido un error, intenta nuevamente.",
						});
					}

					setLoading(false);
				}
			};
			fetchData();
		} else {
			Toast({
				tipo: "error",
				mensaje: "Por favor ingresa el numero de documento.",
			});
		}
	};
	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Agregar Empleados</title>
			</Helmet>
			<div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
				<h1 className="font-bold text-3xl  text-gray-900">
					Registro de Empleados.
				</h1>
				<form onSubmit={(e) => onSubmit(e)} className="">
					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Datos {user && user.role === "admin" ? "del Representante Legal" : "Empleados"}
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="document_type" className="label">
								<span className="label-text">Tipo de documento</span>
							</label>
							<select
								name="document_type"
								value={document_type}
								onChange={(e) => onChange(e)}
								required
								className="select select-bordered w-full"
							>
								<option value="">Selecciona el tipo de documento</option>
								<option value="CC">Cédula de Ciudadanía</option>
								<option value="CE">Cédula de Extranjería</option>
								<option value="PA">Pasaporte</option>
								<option value="TI">Tarjeta de Identidad</option>
								<option value="RC">Registro Civil</option>
								<option value="PE">Permiso Especial de Permanencia</option>
								<option value="PT">Permiso Temporal de Permanencia</option>
							</select>
						</div>

						<div className="form-control">
							<label htmlFor="nuip" className="label">
								<span className="label-text">Cédula de identidad</span>
							</label>
							<input
								type="text"
								name="nuip"
								value={nuip}
								onChange={(e) => onChange(e)}
								required
								minLength={6}
								maxLength={11}
								pattern="[0-9]+"
								className="input input-bordered w-full"
								placeholder="Cedula de identidad"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="first_name" className="label">
								<span className="label-text">Nombres</span>
							</label>
							<input
								type="text"
								name="first_name"
								value={first_name}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Nombres"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="last_name" className="label">
								<span className="label-text">Apellidos</span>
							</label>
							<input
								type="text"
								name="last_name"
								value={last_name}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full "
								placeholder="Apellidos"
							/>
						</div>


						<div className="form-control">
							<label htmlFor="email" className="label">
								<span className="label-text">Correo electrónico</span>
							</label>
							<input
								type="email"
								name="email"
								value={email}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Correo electrónico"
							/>
						</div>
					</div>

					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Contraseña
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="password" className="label">
								<span className="label-text">Contraseña</span>
							</label>
							<input
								type="password"
								name="password"
								value={password}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Contraseña"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="re_password" className="label">
								<span className="label-text">Confirmar contraseña</span>
							</label>
							<input
								type="password"
								name="re_password"
								value={re_password}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Confirmar contraseña"
							/>
						</div>
					</div>
					<div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3">
            {loading ? (
              <div className="btn border bg-[#0096C4] text-white">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#0096C4] text-white"
              >
                Guardar
              </button>
            )}
          </div>
				</form>
			</div>
		</DashboardLayout>
	);
}

export default UsersForm;
