import { combineReducers } from "redux";
import auth from "./auth.js";
import alert from "./alert";
import clients from "./client.js";
import tokens from "./sharedtoken.js";
import payments from "./payment.js";
import users from "./user.js";
import search from "./search.js";
import commissions from "./commissions.js";
import commissions_enterprise from "./commissions-enterprise.js";
import enterprise from "./enterprise.js";
import freeconsultations from "./freeconsultations.js";
export default combineReducers({
  //  Autenticacion
  auth,
  //  Alerta
  alert,
  // Clientes
  clients,
  // Tokens
  tokens,
  // Pagos
  payments,
	// usuarios
		users,
	// busqueda 
	search,
	// comisiones
	commissions,
	// comisiones enterprise
	commissions_enterprise,
	// enterprise
	enterprise,
	// consultas gratuitas
	freeconsultations,
});
