import axios from "axios";
import {
  GET_SHARED_TOKEN_SUCCESS,
  GET_SHARED_TOKEN_FAIL,
  GET_TOKEN_SEARCH_SUCCESS,
  GET_TOKEN_SEARCH_FAIL,
} from "./types";

// listar todas las categorias
export const get_shared_tokens = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/token/list/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SHARED_TOKEN_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SHARED_TOKEN_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SHARED_TOKEN_FAIL,
    });
  }
};
export const get_shared_token_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/token/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_SHARED_TOKEN_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SHARED_TOKEN_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SHARED_TOKEN_FAIL,
    });
  }
};

export const search_token = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/token/search/${search_term}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_TOKEN_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_TOKEN_SEARCH_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_TOKEN_SEARCH_FAIL,
    });
  }
};
