import {
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_FAIL,
  GET_PAYMENT_SUCCESS_ID,
  GET_PAYMENT_FAIL_ID,
  GET_PAYMENT_SEARCH_SUCCESS,
  GET_PAYMENT_SEARCH_FAIL,
} from "../actions/payment/types";

const initialState = {
  payment: null,
  payments: null,
  count: null,
  next: null,
  previous: null,
};

export default function payments(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: payload.results.payments,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_PAYMENT_FAIL:
      return {
        ...state,
        payments: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_PAYMENT_SUCCESS_ID:
      return {
        ...state,
        payment: payload.payment,
      };
    case GET_PAYMENT_FAIL_ID:
      return {
        ...state,
        payment: null,
      };
    case GET_PAYMENT_SEARCH_SUCCESS:
      return {
        ...state,
        filtered_payments: payload.filtered_payments,
      };
    case GET_PAYMENT_SEARCH_FAIL:
      return {
        ...state,
        filtered_payments: null,
      };
    default:
      return state;
  }
}
