import React from "react";
import SmallSetPagination from "../pagination/SmallSetPagination";
import { Link } from "react-router-dom";
import { IconSend } from "@tabler/icons-react";

function FreeConsultationsList({
	free_consultations,
	get_free_consultation_page,
	count,
}) {
	console.log(free_consultations);

	// Función para formatear fechas
	const formatDate = (dateString) => {
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		};
		return new Date(dateString).toLocaleDateString("es-ES", options);
	};

	return (
		<div className="overflow-hidden bg-white">
			<div className="relative overflow-x-auto">
				<table className="table text-gray-500 w-full">
					<thead className="text-xs text-gray-700 uppercase bg-gray-100">
						<tr>
							<th className="p-2">Tipo</th>
							<th className="p-2">Nombres</th>
							<th className="p-2">Apellidos</th>
							<th className="p-2">Número de Documento</th>
							<th className="p-2">Mesa</th>
							<th className="p-2">Nivel</th>
							<th className="p-2">Factura</th>
							<th className="p-2">Asignada</th>
							<th className="p-2">Status</th>
							<th className="p-2">Acciones</th>
						</tr>
					</thead>
					<tbody>
						{free_consultations &&
							free_consultations.map((person) => (
								<tr key={person.id} className="border-b">
									<td className="p-2">{person.person_type === "client" ? "Titular" : "Beneficiario"}</td>
									<td className="p-2">{person.first_name}</td>
									<td className="p-2">{person.last_name}</td>
									<td className="p-2">{person.nuip}</td>
									<td className="p-2">{person.table || "N/A"}</td>
									<td className="p-2">{person.level !== null ? person.level + 1 : "N/A"}</td>
									<td className="p-2">{person.invoice_code}</td>
									<td className="p-2">{formatDate(person.created_at)}</td>
									<td className="p-2">
										{person.active ? (
											<span className="text-green-500">Activo</span>
										) : (
											<span className="text-red-500">Inactivo</span>
										)}
									</td>
									<td className="p-2">
									<div className="flex tooltip" data-tip="Verificar TNS">
								<Link
									// onClick={(e) => handleButtonClickResend(e, data.nuip)}
									className={`p-1 text-white bg-green-500 rounded-full`}

								>
									<IconSend />
								</Link>
							</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
			<SmallSetPagination
				list_page={get_free_consultation_page}
				list={free_consultations}
				count={count}
			/>
		</div>
	);
}

export default FreeConsultationsList;
