import SmallSetPagination from "../pagination/SmallSetPagination";
import CommissionsCardHorizontal from "./CommissionsCardHorizontal";
import CommissionsPaymentsCardHorizontal from "./CommissionsPaymentsCardHorizontal";

function CommissionsPaymentsList({ commissions, get_commissions_page, count }) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2">
                Data
              </th>
							<th scope="col" className="p-2">
                Total
              </th>
							<th scope="col" className="p-2">
                Pagado
              </th>

							<th scope="col" className="p-2">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {commissions &&
							commissions.map((commission, index) => (
								<CommissionsPaymentsCardHorizontal
									data={commission}
									key={index}
									index={index}
								/>
							))}
          </tbody>
        </table>
      </div>
      <SmallSetPagination
        list_page={get_commissions_page}
				list={commissions}
				count={count}
      />
    </div>
  );
}

export default CommissionsPaymentsList;
