import React, { useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { Helmet } from "react-helmet-async";
import { ClipLoader } from "react-spinners"; // Importa el loader

function Reports() {
	const [reportData, setReportData] = useState(null);
	const [reportUrl, setReportUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [reportType, setReportType] = useState("edad");
	const [table, setTable] = useState("");
	const [include, setInclude] = useState("all");
	const [minAge, setMinAge] = useState("");
	const [maxAge, setMaxAge] = useState("");
	const [rol, setRol] = useState("");
	const [fechaDesde, setFechaDesde] = useState("");
	const [fechaHasta, setFechaHasta] = useState("");

	const handleReportTypeChange = (e) => {
		const selectedReportType = e.target.value;
		setReportType(selectedReportType);
		setReportData(null);
		setReportUrl("");
		setError("");
		setFechaDesde("");
		setFechaHasta("");
		setTable("");
		setInclude("all");
		setRol("");
		if (selectedReportType !== "edad") {
			setMinAge("");
			setMaxAge("");
		}
	};

	const fetchReportData = async () => {
		let url = `${process.env.REACT_APP_API_URL}/report/?report_type=${reportType}&include=${include}`;
		if (table) {
			url += `&table=${table}`;
		}
		if (reportType === "edad" && minAge) {
			url += `&min_age=${minAge}`;
		}
		if (reportType === "edad" && maxAge) {
			url += `&max_age=${maxAge}`;
		}
		if (fechaDesde) {
			url += `&fecha_desde=${fechaDesde}`;
		}
		if (fechaHasta) {
			url += `&fecha_hasta=${fechaHasta}`;
		}
		if (rol) {
			url += `&rol=${rol}`;
		}
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		return await response.json();
	};

	const handleFetchReport = async () => {
		setLoading(true);
		setError("");
		try {
			const data = await fetchReportData();
			if (!data || data.length === 0 || Object.keys(data).length === 0) {
				setError("No se encontraron datos para el reporte.");
				setReportData(null);
				setReportUrl("");
			} else {
				setReportData(data);
				setReportUrl(data.csv_url);
			}
		} catch (error) {
			setError(`Hubo un error al cargar el reporte: ${error.message}`);
			setReportData(null);
			setReportUrl("");
		} finally {
			setLoading(false);
		}
	};

	const handleFechaDesdeChange = (e) => {
		const selectedFechaDesde = e.target.value;
		setFechaDesde(selectedFechaDesde);
		// Solo actualiza fechaHasta si está antes de fechaDesde
		if (fechaHasta && fechaHasta < selectedFechaDesde) {
			setFechaHasta(selectedFechaDesde);
		}
	};
	
	const handleFechaHastaChange = (e) => {
		const selectedFechaHasta = e.target.value;
		setFechaHasta(selectedFechaHasta);
	};
	

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Reportes</title>
			</Helmet>
			<div className="container mx-auto max-w-6xl mt-6 bg-gray-100 px-4 py-3 rounded-2xl">
				<h1 className="text-2xl font-bold mb-4">Generar reportes</h1>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
					<div className="form-control">
						<label className="label">
							<span className="label-text">Tipo de Reporte:</span>
						</label>
						<select
							value={reportType}
							onChange={handleReportTypeChange}
							className="select select-bordered w-full"
						>
							<option value="edad">Por Edad</option>
							<option value="genero">Por Género</option>
							<option value="estrato">Por Estrato</option>
							<option value="municipios">Por Municipio</option>
							<option value="eps">Por EPS</option>
							<option value="proximos_a_vencer">Próximos a Vencer</option>
							<option value="activos">Titulares Activos</option>
							<option value="inactivos">Titulares Inactivos</option>
							<option value="clientes_no_verificados">
								Titulares no Verificados
							</option>
							<option value="clientes_por_plan">Titulares por Plan</option>
							<option value="obtener_clientes_para_actualizar_a_plus">
								Titulares pendiente a pasar a Plus
							</option>
							<option value="obtener_clientes_con_pagos_pendientes">
								Titulares con pagos pendientes
							</option>
							<option value="obtener_clientes_aprobados_por_siau">
								Titulares con pagos aprobados por siau
							</option>
							<option value="conteo_por_metodo_de_pago">
								Listado Metodo de Pago
							</option>
							<option value="obtener_datos_usuarios_empresas">
								{" "}
								Titulares por Empresas
							</option>
							<option value="reporte_comisiones">Reporte Comisiones</option>
							<option value="reporte_comisiones_detalle">
								Reporte Comisiones Individual
							</option>
							<option value="calculate_general_commissions">
								Reporte Completo pago de Comisiones
							</option>
							<option value="generar_reporte_general">
								Reporte Completo de Beneficiarios
							</option>
							<option value="aceptar_terminos">
								Reporte Fechas de Aceptacion de Terminos
							</option>
						</select>
					</div>

					<div className="form-control">
						<label className="label">
							<span className="label-text">Fecha Desde (opcional):</span>
						</label>
						<input
							type="date"
							value={fechaDesde}
							onChange={handleFechaDesdeChange}
							className="input input-bordered w-full"
						/>
					</div>
					<div className="form-control">
						<label className="label">
							<span className="label-text">Fecha Hasta (opcional):</span>
						</label>
						<input
							type="date"
							value={fechaHasta}
							onChange={handleFechaHastaChange}
							className="input input-bordered w-full"
						/>
					</div>

					<div className="form-control">
						<label className="label">
							<span className="label-text">Número de Mesa (opcional):</span>
						</label>

						<select
							value={table}
							onChange={(e) => setTable(e.target.value)}
							className="select select-bordered w-full"
						>
							<option value="">Todas las mesas</option>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="9">9</option>
							<option value="10">10</option>
							<option value="11">11</option>
							<option value="12">12</option>
							<option value="13">13</option>
							<option value="14">14</option>
							<option value="15">15</option>
							<option value="16">16</option>
							<option value="17">17</option>
							<option value="18">18</option>
							<option value="19">19</option>
							<option value="20">20</option>
							<option value="21">21</option>
							<option value="22">22</option>
							<option value="23">23</option>
							<option value="24">24</option>
							<option value="25">25</option>
							<option value="26">26</option>
							<option value="27">27</option>
							<option value="28">28</option>
							<option value="29">29</option>
							<option value="30">30</option>
							<option value="31">31</option>
							<option value="32">32</option>
						</select>
					</div>
					<div className="form-control">
						<label className="label">
							<span className="label-text">Incluir:</span>
						</label>
						<select
							value={include}
							onChange={(e) => setInclude(e.target.value)}
							className="select select-bordered w-full"
						>
							{reportType === "edad" ||
							reportType === "genero" ||
							reportType === "estrato" ||
							reportType === "municipios" ||
							reportType === "eps" ? (
								<>
									<option value="all">Todos</option>
									<option value="clients">Solo Titulares</option>
									<option value="beneficiaries">Solo Beneficiarios</option>
								</>
							) : (
								<option value="clients">Solo Titulares</option>
							)}
						</select>
					</div>
					{reportType === "edad" && (
						<>
							<div className="form-control">
								<label className="label">
									<span className="label-text">Edad Mínima (opcional):</span>
								</label>
								<input
									type="number"
									value={minAge}
									onChange={(e) => setMinAge(e.target.value)}
									placeholder="Edad mínima"
									className="input input-bordered w-full"
								/>
							</div>
							<div className="form-control">
								<label className="label">
									<span className="label-text">Edad Máxima (opcional):</span>
								</label>
								<input
									type="number"
									value={maxAge}
									onChange={(e) => setMaxAge(e.target.value)}
									placeholder="Edad máxima"
									className="input input-bordered w-full"
								/>
							</div>
						</>
					)}
					{reportType === "reporte_comisiones_detalle" && (
						<div className="form-control">
							<label className="label">
								<span className="label-text">Rol (Opcional):</span>
							</label>
							<select
								value={rol}
								onChange={(e) => setRol(e.target.value)}
								className="select select-bordered w-full"
							>
								<option value="">Todos</option>
								<option value="COLABORADORES CULM">COLABORADORES CULM</option>
								<option value="COORDINADOR JUNIOR">COORDINADOR JUNIOR</option>
								<option value="COORDINADOR EJECUTIVO">
									COORDINADOR EJECUTIVO
								</option>
								<option value="SUBDIRECTOR">SUBDIRECTOR</option>
								<option value="DIRECTOR">DIRECTOR</option>
								<option value="ENLACE">ENLACE</option>
								<option value="BANCO">BANCO</option>
								<option value="COSTOS Y GASTOS">COSTOS Y GASTOS</option>
								<option value="IMPUESTOS">IMPUESTOS</option>
							</select>
						</div>
					)}
				</div>
				<div className="flex gap-3">
					<button
						onClick={handleFetchReport}
						className="bg-[#0096C4] text-white px-4 py-2 rounded"
						disabled={loading}
					>
						Cargar Reporte
					</button>
					{loading && (
						<ClipLoader color="#0096C4" loading={loading} size={35} />
					)}
					{reportUrl && !loading && (
						<a
							href={`${process.env.REACT_APP_API_URL}${reportUrl}`}
							className="bg-[#0096C4] text-white px-4 py-2 rounded"
						>
							Bajar Excel
						</a>
					)}
				</div>
				{error && <p className="text-red-500">{error}</p>}
			</div>
		</DashboardLayout>
	);
}

export default Reports;
