import {
	GET_ENTERPRISE_LIST_SUCCESS,
	GET_ENTERPRISE_LIST_FAIL,
	GET_ENTERPRISE_SUCCESS,
	GET_ENTERPRISE_FAIL,
	GET_ENTERPRISE_SEARCH_SUCCESS,
	GET_ENTERPRISE_SEARCH_FAIL
} from '../actions/enterprises/types';

const initialState = {
	enterprise_list: [],
	enterprise: null,
	enterprise_search: []
};

export default function enterprise(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_ENTERPRISE_LIST_SUCCESS:
			return {
				...state,
				enterprise_list: payload.results
			};
		case GET_ENTERPRISE_LIST_FAIL:
			return {
				...state,
				enterprise_list: []
			};
		case GET_ENTERPRISE_SUCCESS:
			return {
				...state,
				enterprise: payload.enterprise
			};
		case GET_ENTERPRISE_FAIL:
			return {
				...state,
				enterprise: null
			};
		case GET_ENTERPRISE_SEARCH_SUCCESS:
			return {
				...state,
				enterprise_search: payload
			};
		case GET_ENTERPRISE_SEARCH_FAIL:
			return {
				...state,
				enterprise_search: []
			};
		default:
			return state;
	}
}
