import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Fragment, useState } from "react";
import CircleLoader from "react-spinners/CircleLoader";
import Toast from "../Alert";
import { IconCheckbox } from "@tabler/icons-react";
import { Link } from "react-router-dom";

function VerifyModal({ data }) {
	const [openVerify, setOpenVerify] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({ token_key: "" });
	const { token_key } = formData;

	const handleModalClose = () => {
		setOpenVerify(false);
		setAcceptedTerms(false); // Reinicia los términos al cerrar
	};

	const handleAcceptTerms = (event) => {
		setAcceptedTerms(event.target.checked);
		if (event.target.checked) {
			setTimeout(() => setAcceptedTerms(true), 1500); // Espera de 1.5 segundos
		} else {
			setAcceptedTerms(false);
		}
	};

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		if (token_key) {
			const config = {
				headers: {
					Accept: "application/json",
				},
			};

			const formData = new FormData();
			formData.append("token_key", token_key);
			formData.append("client_id", data);

			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/token/verfied/`,
					formData,
					config
				);

				if (res.status === 200) {
					setLoading(false);
					setFormData({ token_key: "" });
					Toast({
						tipo: "success",
						mensaje: "¡Verificación Exitosa!",
					});
					setOpenVerify(false);
					setLoading(false);
					window.location.reload();
				}
			} catch (error) {
				Toast({
					tipo: "error",
					mensaje:
						error.response &&
						error.response.status === 400 &&
						error.response.data.error.includes(
							"Client does not exist or token is invalid"
						)
							? "El cliente no existe o el token es inválido"
							: "Error al verificar el token",
				});
				setLoading(false);
			}
		} else {
			Toast({ tipo: "error", mensaje: "Por favor ingrese un token válido" });
			setLoading(false);
		}
	};

	return (
		<>
			<div className="tooltip flex" data-tip="Verificar Token">
				<Link
					onClick={() => setOpenVerify(true)}
					className="p-1 bg-green-500 text-white rounded-full cursor-pointer"
				>
					<IconCheckbox />
				</Link>
			</div>

			<Transition.Root show={openVerify} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={handleModalClose}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 py-6 text-left shadow-xl transition-all sm:max-w-lg">
									{acceptedTerms ? (
										<div>
											<div className="mt-3 text-center">
												<Dialog.Title
													as="h3"
													className="text-lg font-semibold leading-6 text-gray-900"
												>
													Verificar código de cliente
												</Dialog.Title>
											</div>
											<form onSubmit={onSubmit} className="mt-5">
												<div>
													<input
														type="text"
														name="token_key"
														value={token_key}
														onChange={onChange}
														className="input input-bordered w-full"
														placeholder="Código de verificación"
													/>
												</div>
												<div className="flex justify-center mt-6 space-x-3">
													<button
														type="button"
														className="btn border bg-white text-[#0096C4]"
														onClick={handleModalClose}
													>
														Cerrar
													</button>
													{loading ? (
														<div className="btn bg-[#0096C4] text-white">
															<CircleLoader loading={loading} size={25} color="#ffffff" />
														</div>
													) : (
														<button
															type="submit"
															className="btn bg-[#0096C4] hover:bg-[#00496B] text-white"
														>
															Guardar
														</button>
													)}
												</div>
											</form>
										</div>
									) : (
										<div className="mt-6">
											<h3 className="text-lg font-medium text-gray-900">Términos y condiciones</h3>
											<p className="mt-2 text-sm text-gray-500">
												Para continuar, debes aceptar los términos y condiciones de uso del programa de fidelización LATIR.{" "}
												<a
													href="/terminos-y-condiciones"
													target="_blank"
													rel="noopener noreferrer"
													className="text-blue-500 underline"
												>
													Ver términos y condiciones
												</a>
											</p>
											<label htmlFor="acceptedTerms" className="flex items-center mt-4">
												<input
													type="checkbox"
													name="acceptedTerms"
													checked={acceptedTerms}
													onChange={handleAcceptTerms}
													className="form-checkbox h-4 w-4 text-indigo-600"
												/>
												<span className="ml-2 text-sm text-gray-600">
													Acepto los términos y condiciones
												</span>
											</label>
										</div>
									)}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}

export default VerifyModal;
