import {
	GET_FREE_CONSULTATION_LIST_SUCCESS,
	GET_FREE_CONSULTATION_LIST_FAIL,
	GET_FREE_CONSULTATION_SEARCH_SUCCESS,
	GET_FREE_CONSULTATION_SEARCH_FAIL,
} from '../actions/freeconsultations/types';

const initialState = {
	freeconsultations: null,
	filtered_freeconsultations: null,
	count : null,
	next : null,
	previous : null,
};

export default function freeconsultations(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_FREE_CONSULTATION_LIST_SUCCESS:
			return {
				...state,
				freeconsultations: payload.results,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_FREE_CONSULTATION_LIST_FAIL:
			return {
				...state,
				freeconsultations: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_FREE_CONSULTATION_SEARCH_SUCCESS:
			return {
				...state,
				filtered_freeconsultations: payload.results,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_FREE_CONSULTATION_SEARCH_FAIL:
			return {
				...state,
				filtered_freeconsultations: null,
				count: null,
				next: null,
				previous: null,
			};
		default:
			return state;
	}
}
