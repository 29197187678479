import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { connect, useSelector } from "react-redux";
import { get_user } from "../../../redux/actions/user/user";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Toast from "../../../components/Alert";
import Loading from "../../../components/Loading";

function UserEdit({ get_user, user }) {
	const params = useParams();
	const id = params.id;
	const [selectedTables, setSelectedTables] = useState([]);
	const useradmin = useSelector((state) => state.auth.user);
	const [updateDocumentType, setUpdateDocumentType] = useState(false);
	const [updateFirstName, setUpdateFirstName] = useState(false);
	const [updateLastName, setUpdateLastName] = useState(false);
	const [updateGender, setUpdateGender] = useState(false);
	const [updateNuip, setUpdateNuip] = useState(false);
	const [updateEmail, setUpdateEmail] = useState(false);
	const [updateUsername, setUpdateUsername] = useState(false);
	const [updateTable, setUpdateTable] = useState(false);

	useEffect(() => {
		get_user(id);
	}, [get_user, id]);

	const [formData, setFormData] = useState({
		email: "",
		username: "",
		first_name: "",
		last_name: "",
		document_type: "",
		nuip: "",
		gender: "",
		table: "",
	});
	const {
		email,
		username,
		first_name,
		last_name,
		document_type,
		nuip,
		gender,
	} = formData;
	const resetStates = () => {
		setUpdateDocumentType(false);
		setUpdateFirstName(false);
		setUpdateLastName(false);
		setUpdateGender(false);
		setUpdateNuip(false);
		setUpdateEmail(false);
		setUpdateUsername(false);

		setUpdateTable(false);
	};
	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const [loading, setLoading] = useState(false);

	const onSubmit = (e) => {
		e.preventDefault();
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("document_type", document_type);
		formData.append("first_name", first_name);
		formData.append("last_name", last_name);
		formData.append("gender", gender);
		formData.append("nuip", nuip);
		formData.append("email", email);
		formData.append("username", username);
		formData.append("id", id);
		formData.append("table", selectedTables);
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/user/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						document_type: "",
						first_name: "",
						last_name: "",
						gender: "",
						nuip: "",
						email: "",
						username: "",
						table: "",
					});
					setLoading(false);
					resetStates();
					get_user(id);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					setLoading(false);
					resetStates();
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				setLoading(false);
				resetStates();
				Toast({ tipo: "error", mensaje: "Error al editar el titular" });
			}
		};
		fetchData();
	};
	return (
		<DashboardLayout>
			{user ? (
				<>
					<Helmet>
						<title>Latir | Editar Usuario </title>
					</Helmet>
					<div className="w-full overflow-y-auto rounded-lg h-100 shadow-card overscroll-y-auto">
						<h1 className="text-3xl font-bold text-gray-900">
							Editar Usuario {user && user.first_name} {user && user.last_name}
						</h1>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos personales
						</div>

						<div className="grid grid-cols-1 gap-3 p-3 border border-gray-300 rounded-lg md:grid-cols-2 lg:grid-cols-3">
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Tipo de documento</span>
								</label>
								{updateDocumentType ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="">
												<select
													name="document_type"
													value={document_type}
													onChange={(e) => onChange(e)}
													required
													className="w-full select select-bordered"
												>
													<option value="">
														Selecciona el tipo de documento
													</option>
													<option value="CC">Cédula de ciudadanía</option>
													<option value="CE">Cédula de extranjería</option>
													<option value="PA">Pasaporte</option>
													<option value="TI">Tarjeta de identidad</option>
													<option value="RC">Registro civil</option>
													<option value="PE">
														Permiso especial de permanencia
													</option>
													<option value="PT">
														Permiso temporal de permanencia
													</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateDocumentType(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<div className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user && user.document_type
												? (() => {
														switch (user.document_type) {
															case "CC":
																return "Cédula de Ciudadanía";
															case "CE":
																return "Cédula de Extranjería";
															case "PA":
																return "Pasaporte";
															case "TI":
																return "Tarjeta de Identidad";
															case "RC":
																return "Registro Civil";
															case "PE":
																return "Permiso Especial de Permanencia";
															case "PT":
																return "Permiso Temporal de Permanencia";
															default:
																return "Sin Especificar";
														}
												  })()
												: "Sin Especificar"}
										</div>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateDocumentType(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Número de documento</span>
								</label>
								{updateNuip ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={nuip}
													onChange={(e) => onChange(e)}
													name="nuip"
													type="text"
													className="w-full input input-bordered"
													pattern="[0-9]+"
													minLength={6}
													maxLength={11}
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateNuip(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user.nuip}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateNuip(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="first_name" className="label">
									<span className="label-text">Nombres</span>
								</label>
								{updateFirstName ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={first_name}
													onChange={(e) => onChange(e)}
													name="first_name"
													type="text"
													className="w-full input input-bordered"
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateFirstName(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user.first_name}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateFirstName(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="last_name" className="label">
									<span className="label-text">Apellidos</span>
								</label>
								{updateLastName ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={last_name}
													onChange={(e) => onChange(e)}
													name="last_name"
													type="text"
													className="w-full input input-bordered"
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateLastName(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user.last_name}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateLastName(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="gender" className="label">
									<span className="label-text">Género</span>
								</label>
								{updateGender ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="gender"
													value={gender}
													onChange={(e) => onChange(e)}
													required
													className="w-full select select-bordered"
												>
													<option value="">Selecciona el género</option>
													<option value="M">Masculino</option>
													<option value="F">Femenino</option>
													<option value="O">Otro</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateGender(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user.gender === "M"
												? "Masculino"
												: user.gender === "F"
												? "Femenino"
												: "Otro"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateGender(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="username" className="label">
									<span className="label-text">Nombre de usuario</span>
								</label>
								{updateUsername ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={username}
													onChange={(e) => onChange(e)}
													name="username"
													type="text"
													className="w-full input input-bordered"
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateUsername(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user.username}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateUsername(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
							{
								useradmin && useradmin.role === "ADMIN" ? (

							<>
							<div className="form-control">
								<label htmlFor="email" className="label">
									<span className="label-text">Correo electrónico</span>
								</label>
								{updateEmail ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={email}
													onChange={(e) => onChange(e)}
													name="email"
													type="email"
													className="w-full input input-bordered"
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateEmail(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user.email}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateEmail(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="table" className="label">
								<span className="label-text">Mesa{user.table && user.table.length === 1 ? '' : 's'}</span>
								</label>
								{updateTable ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													value={selectedTables}
													onChange={(e) =>
														setSelectedTables(
															Array.from(
																e.target.selectedOptions,
																(option) => option.value
															)
														)
													}
													name="table"
													multiple
													className="w-full select select-bordered"
													required
													size={10}
												>
													<option value="">Selecciona la mesa</option>
													<option value="1">Mesa 1</option>
													<option value="2">Mesa 2</option>
													<option value="3">Mesa 3</option>
													<option value="4">Mesa 4</option>
													<option value="5">Mesa 5</option>
													<option value="6">Mesa 6</option>
													<option value="7">Mesa 7</option>
													<option value="8">Mesa 8</option>
													<option value="9">Mesa 9</option>
													<option value="10">Mesa 10</option>
													<option value="11">Mesa 11</option>
													<option value="12">Mesa 12</option>
													<option value="13">Mesa 13</option>
													<option value="14">Mesa 14</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateTable(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user.table && user.table.length > 0 ? (
												<div className="flex ml-1">
													{user.table.map((table, index) => (
														<span
															key={index}
															className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-700 mr-1"
														>
															{table.table}
														</span>
													))}
												</div>
											) : (
												<span className="flex ml-1 inline-block bg-red-200 rounded-full px-2 py-1 text-xs font-semibold text-red-700">
													No hay mesas asignadas
												</span>
											)}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateTable(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
						</>
								) : (<>
								<div className="form-control">
								<label htmlFor="email" className="label">
									<span className="label-text">Correo electrónico</span>
								</label>
									<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{user.email}
										</span>
								</div>

								<div className="form-control">
									<label htmlFor="table" className={`label ${user.table && user.table.length === 1 ? 'mesa' : 'mesas'}`}>
										<span className="label-text">Mesa{user.table && user.table.length === 1 ? '' : 's'}</span>
									</label>
									<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
										{user.table && user.table.length > 0 ? (
											<div className="flex ml-1">
												{user.table.map((table, index) => (
													<span
														key={index}
														className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-700 mr-1"
													>
														{table.table}
													</span>
												))}
											</div>
										) : (
											<span className="flex ml-1 inline-block bg-red-200 rounded-full px-2 py-1 text-xs font-semibold text-red-700">
												No hay mesas asignadas
											</span>
										)}
									</span>
								</div>
								</>)
							}
						</div>
					</div>
				</>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	user: state.users.user,
});
export default connect(mapStateToProps, {
	get_user,
})(UserEdit);
