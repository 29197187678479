import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { IconMailForward, IconPencil } from "@tabler/icons-react";
import Toast from "../Alert";
import axios from "axios";
import { useSelector } from "react-redux";

function UsersCardHorizontal({ data, index }) {
	const user = useSelector((state) => state.auth.user);
	const onSubmit = (e, email) => {
		e.preventDefault();

		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};

		const formData = new FormData();
		formData.append("email", email);

		const fetchData = async () => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/users/resend_activation/`,
					formData, // Pass the form data directly
					config
				);
				if (res.status === 204) {
					Toast({
						tipo: "success",
						mensaje: "Se envió la activación correctamente!",
					});
				} else {
					Toast({
						tipo: "error",
						mensaje: "Error al enviar activacion del Cliente",
					});
				}
			} catch (err) {
				if (
					err.response &&
					err.response.status === 400 &&
					err.response.data.error.includes("Invalid credentials.")
				) {
					Toast({ tipo: "error", mensaje: "Credenciales incorrectas" });
				}
				if (
					err.response &&
					err.response.status === 403 &&
					err.response.data.error.includes("User is not active.")
				) {
					Toast({
						tipo: "error",
						mensaje:
							"No posees los suficientes permisos para realizar esta acción",
					});
				}
			}
		};
		fetchData();
	};

	return (
		<React.Fragment key={index}>
			<tr className="bg-white border-b">
				<td className="p-2">
					<div className="flex text-xs">
						<span className="font-bold">Doc.</span>
						<span className="flex ml-1">
							{data.document_type}-{data.nuip}
						</span>
					</div>
					<div className="flex text-xs">
						<span className="font-bold">Nombre: </span>{" "}
						<span className="flex ml-1">
							{data.first_name} {data.last_name}
						</span>
					</div>
					<div className="flex text-xs">
						<span className="font-bold">Género: </span>{" "}
						<span className="flex ml-1">
							{data.gender === "M"
								? "Masculino"
								: data.gender === "F"
								? "Femenino"
								: "Otro"}
						</span>
					</div>
				</td>
				<td className="p-2">
					<div className="flex text-xs">
						<span className="font-bold">Teléfono: </span>{" "}
						<span className="flex ml-1">{data.phone}</span>
					</div>
					<div className="flex text-xs">
						<span className="font-bold">Correo: </span>{" "}
						<span className="flex ml-1">{data.email}</span>
					</div>
				</td>
				<td className="p-2">
					<div className="flex text-xs">
						<span className="font-bold">Departamento: </span>{" "}
						<span className="flex ml-1">{data.department}</span>
					</div>
					<div className="flex text-xs">
						<span className="font-bold">Rol: </span>{" "}
						<span className="flex ml-1">{data.role}</span>
					</div>
				</td>
				<td className="p-2">
					<div className="flex text-xs">
						{data.table.sort((a, b) => a.table - b.table) &&
						data.table.length > 0 ? (
							<div className="grid grid-cols-4 gap-1">
								{data.table.map((table, index) => (
									<div
										key={index}
										className="bg-gray-200 text-center w-7 h-7 flex items-center justify-center rounded-full text-xs font-semibold text-gray-700 mr-1"
									>
										{table.table}
									</div>
								))}
							</div>
						) : (
							<span className="flex ml-1 bg-red-200 rounded-full px-2 py-1 text-xs font-semibold text-red-700">
								S/N mesa
							</span>
						)}
					</div>
				</td>
				<td className="p-2">
					<div className="flex text-xs">
						<span className="font-bold">Fecha de registro: </span>{" "}
						<span className="flex ml-1">
							{moment(data.date_joined).format("DD/MM/YYYY")}
						</span>
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="flex gap-2">
						{
							user && user.role === "ADMIN" ? (
								<div className="flex tooltip" data-tip="Editar">
									<Link
										to={`/edit-users/${data.id}`}
										className="p-1 text-white bg-yellow-500 rounded-full"
									>
										<IconPencil />
									</Link>
								</div>
							) : (
								user && user.id === data.id ? (
									<div className="flex tooltip" data-tip="Editar">
										<Link
											to={`/edit-users/${data.id}`}
											className="p-1 text-white bg-yellow-500 rounded-full"
										>
											<IconPencil />
										</Link>
									</div>
								) : (
									""
								)
							) // <Link
						}

						{data && data.is_active ? (
							""
						) : (
							<div className="flex tooltip" data-tip="Reenviar activación">
								<Link
									onClick={(e) => onSubmit(e, data.email)}
									className="p-1 text-white bg-green-500 rounded-full"
								>
									<IconMailForward />
								</Link>
							</div>
						)}
					</div>
				</td>
			</tr>
		</React.Fragment>
	);
}
export default UsersCardHorizontal;
