import React, { useState, useEffect, useRef } from "react";
import Loader from "../assets/images/loader.svg";
import LogoCF from "../assets/images/cf-logo.png";
import VAImage from "../assets/images/img.png";

const ChatBubble = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
    const ws = useRef(null);
    const endOfMessagesRef = useRef(null);
    const typingTimeoutRef = useRef(null);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const maxReconnectAttempts = 5;

    useEffect(() => {
        const savedMessages = JSON.parse(sessionStorage.getItem("chatMessages")) || [];
        setMessages(savedMessages);

        if (!sessionStorage.getItem("user")) {
            sessionStorage.setItem("user", Math.floor(Math.random() * 1000));
        }
        if (!sessionStorage.getItem("conversation_id")) {
            sessionStorage.setItem("conversation_id", Math.floor(Math.random() * 1000));
        }
        if (!sessionStorage.getItem("session_id")) {
            sessionStorage.setItem("session_id", Math.floor(Math.random() * 1000));
        }

        setupWebSocket();

        return () => {
            if (ws.current?.readyState === WebSocket.OPEN) {
                ws.current.close();
            }
        };
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const setupWebSocket = () => {
				ws.current = new WebSocket("wss://bot.culm.com.co/ws/chat/");

        ws.current.onopen = () => {
            console.log("WebSocket connected!");
            setReconnectAttempts(0);
            setIsWebSocketOpen(true);
        };

        ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            addMessageLetterByLetter(data.response, "bot");
        };

        ws.current.onerror = (event) => {
            console.error("WebSocket error observed:", event);
        };

        ws.current.onclose = (event) => {
            console.log(`WebSocket is closed now. Code: ${event.code}, Reason: ${event.reason}`);
            setIsWebSocketOpen(false);
            handleReconnect();
        };
    };

    const handleReconnect = () => {
        if (reconnectAttempts < maxReconnectAttempts) {
            let timeout = Math.pow(2, reconnectAttempts) * 1000;
            setTimeout(() => {
                setupWebSocket();
            }, timeout);
        } else {
            console.log("Max reconnect attempts reached, not attempting further reconnects.");
        }
    };

    const handleMessageChange = (e) => {
        setInputMessage(e.target.value);
    };

    const displayTypingMessage = () => {
        setMessages((prevMessages) => [
            ...prevMessages,
            { id: "typing", text: "Respondiendo...", sender: "bot", typing: true },
        ]);
        scrollToBottom();
    };

    const clearTypingMessage = () => {
        setMessages((prevMessages) =>
            prevMessages.filter((message) => message.id !== "typing")
        );
    };

    const handleSendMessage = (e) => {
			e.preventDefault();
	
			// Verificar si el mensaje de entrada está vacío
			if (inputMessage.trim() === "") {
					console.error("Cannot send an empty message.");
					return;
			}
	
			const newMessage = { id: Date.now(), text: inputMessage, sender: "user" };
	
			setMessages((prevMessages) => {
					const updatedMessages = [...prevMessages, newMessage];
					sessionStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
					return updatedMessages;
			});
			setInputMessage("");
			setIsTyping(true);
	
			if (isWebSocketOpen) {
					const messagePayload = {
							message: inputMessage,
							action: "chat",
							user: sessionStorage.getItem("user"),
							conversation_id: sessionStorage.getItem("conversation_id"),
							session_id: sessionStorage.getItem("session_id")
					};
					ws.current.send(JSON.stringify(messagePayload));
					displayTypingMessage();
			} else {
					console.error("WebSocket is not open. Unable to send message.");
			}
	};
	

    const addMessageLetterByLetter = (text, sender) => {
			if (!text) {
					console.error("Cannot add a message with undefined or empty text.");
					// Agregar el mensaje completo de una vez si está vacío o indefinido
					setMessages((prevMessages) => {
							const updatedMessages = [...prevMessages, { id: Date.now(), text: "", sender: sender }];
							sessionStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
							return updatedMessages;
					});
					return;
			}
	
			let messageToAdd = "";
			const typingMessageId = Date.now();
	
			const addLetter = (i) => {
					if (i < text.length) {
							messageToAdd += text[i];
							setMessages((prevMessages) =>
									prevMessages.map((m) => {
											if (m.id === typingMessageId) {
													return { ...m, text: messageToAdd };
											}
											return m;
									})
							);
							scrollToBottom();
							typingTimeoutRef.current = setTimeout(() => addLetter(i + 1), 50);
					} else {
							setIsTyping(false);
							clearTypingMessage();
							setMessages((prevMessages) => {
									const updatedMessages = prevMessages.filter((m) => m.id !== typingMessageId).concat({
											id: typingMessageId,
											text: messageToAdd,
											sender: sender,
									});
									sessionStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
									return updatedMessages;
							});
					}
			};
	
			setMessages((prevMessages) => [
					...prevMessages,
					{ id: typingMessageId, text: "", sender: sender, typing: true },
			]);
			addLetter(0);
	};
	

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

	return (
		<div className="fixed bottom-4 right-4">
			{isChatOpen ? (
				<div className="bg-[#f6f6f6] overflow-hidden rounded-lg shadow-lg flex flex-col animate-fadeIn">
					<div className="p-2 flex justify-between items-center bg-[#1b1b1b]">
						<h2 className="text-lg font-semibold text-white">Bot</h2>
						<button onClick={toggleChat} className="p-2">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="#fff"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
								/>
							</svg>
						</button>
					</div>
					<div className="overflow-y-scroll h-64 max-h-64 flex flex-col p-2">
						<div className="p-2 my-1 rounded-2xl bg-gray-200 max-w-52 whitespace-normal rounded-tl-none">
							Hola, soy el asistente virtual de la CULM en el programa Latir, ¿en qué puedo ayudarte?
						</div>
						{messages.map((msg) => (
							<div
								key={msg.id}
								className={`p-2 my-2 text-sm rounded-2xl whitespace-normal max-w-52 ${
									msg.sender === "user"
										? "bg-[#f69100] text-white rounded-tr-none self-end"
										: "bg-gray-200 rounded-tl-none self-start"
								}`}
							>
								{msg.text}
							</div>
						))}
						{isTyping && (
							<div className="text-sm flex items-end">
								Respondiendo...{" "}
								<svg height="24px" width="24px" version="1.1" viewBox="0 0 32 32">
									<title />
									<desc />
									<g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
										<g fill="#222222" id="pen-angled">
											<path
												d="M23.1464466,12.0278086 L11.8535534,23.3207019 L11.8535534,23.3207019 L7.85355339,19.3207019 L19.1464466,8.02780864 L23.1464466,12.0278086 L23.1464466,12.0278086 Z M23.8535534,11.3207018 L25.5801067,9.59414849 C26.3642921,8.8099631 26.3661881,7.54044334 25.5897496,6.76400487 L24.4102504,5.58450561 C23.6313906,4.80564584 22.372781,4.80147421 21.5801067,5.59414851 L19.8535534,7.32070186 L23.8535534,11.3207018 L23.8535534,11.3207018 Z M11.1464466,24.0278086 L11,24.1742552 L6,25.1742552 L7,20.1742552 L7.14644661,20.0278086 L11.1464466,24.0278086 L11.1464466,24.0278086 Z"
												id="pen-angled"
											/>
										</g>
									</g>
								</svg>
							</div>
						)}
						<div ref={endOfMessagesRef} />
					</div>
					<div className="p-2">
						<form onSubmit={handleSendMessage} className="flex">
							<input
								type="text"
								value={inputMessage}
								onChange={handleMessageChange}
								className="flex-1 p-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-[#f69100]"
								disabled={isTyping}
							/>
							<button
								type="submit"
								className="bg-[#f69100] text-white px-4 rounded-r-lg"
								disabled={isTyping}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6 -rotate-45"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
									/>
								</svg>
							</button>
						</form>
						<div className="divider my-0.5"></div>
						<div className="text-[10px] flex justify-center gap-2">
							<img src={LogoCF} className="w-5" alt="Logo Creative Ferrets" />
							<div>
								Desarrollado por <span className="font-bold">Creative Ferrets</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				<button
					onClick={toggleChat}
					className="p-3 rounded-full text-white flex items-center justify-center relative"
				>
					{/* <svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="w-6 h-6"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
						/>
					</svg> */}
					<div className="bg-gray-200 text-gray-800 animate-bounce w-44 text-xs p-2 rounded-xl rounded-br-none absolute top-[-40px] right-[85%]">
						<p className="text-left">Hola, soy tu asistente virtual, ¿en qué puedo ayudarte?</p>
					</div>
					<img src={VAImage} alt="Virtual Assistant" className="w-20 h-20" />
				</button>
			)}
		</div>
	);

};

export default ChatBubble;
