import SmallSetPagination from "../pagination/SmallSetPagination";
import EnterprisesCardHorizontal from "./EnterprisesCardHorizontal";

function EnterprisesList({
  enterprises,
  get_enterprise_list,
  get_enterprise_list_page,
  count,
  refreshData,
}) {
  console.log(enterprises);
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2">#</th>
              <th scope="col" className="p-2">
                <div className="w-64">Empresa</div>
              </th>
							<th scope="col" className="p-2">
								<div className="w-52">Dirección</div>
							</th>
              <th scope="col" className="p-2">
                <div className="w-52">Representante legal</div>
              </th>
              <th scope="col" className="p-2">Corpoindustrial</th>
							<th scope="col" className="p-2">Rut</th>
              <th scope="col" className="p-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {enterprises &&
              enterprises.map((enterprise, index) => (
                <EnterprisesCardHorizontal
                  data={enterprise}
                  key={enterprise.id}
                  index={index}
                  refreshData={refreshData}
                />
              ))}
          </tbody>
        </table>
      </div>
      <SmallSetPagination
        list_page={get_enterprise_list_page}
        list={enterprises}
        count={count}
      />
    </div>
  );
}

export default EnterprisesList;
