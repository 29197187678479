import React from "react";
import {
	Document,
	Font,
	Page,
	Text,
	Image,
	StyleSheet,
	View,
} from "@react-pdf/renderer";
import logo from "../../assets/img/logo.png";

const styles = StyleSheet.create({
	body: {
		paddingTop: 5,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: "Oswald",
	},
	author: {
		fontSize: 12,
		textAlign: "center",
		marginBottom: 40,
	},
	subtitle: {
		fontSize: 14,
		fontFamily: "Oswald",
	},
	subtitleLatir: {
		fontSize: 14,
		fontFamily: "Oswald",
		color: "#00496B",
	},
	text: {
		margin: 12,
		fontSize: 14,
		textAlign: "justify",
		fontFamily: "Times-Roman",
	},
	image: {
		marginVertical: 15,
		marginHorizontal: 150,
		width: 200,
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: "center",
		color: "grey",
	},
	pageNumber: {
		position: "absolute",
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: "center",
		color: "grey",
	},
	datosp: {
		fontSize: 13,
		fontFamily: "Oswald",
		marginTop: 3,
	},
	datosinner: {
		width: "33.33%",
		marginBottom: 6,
	},
	datos: {
		fontSize: 11,
		color: "#676767",
	},
	wrapper: {
		flexDirection: "col",
		border: "1px solid #676767",
		padding: 6,
		borderRadius: 5,
		marginBottom: 6,
	},
	wrapperLatir: {
		flexDirection: "col",
		border: "1px solid #676767",
		borderColor: "#00496B",
		padding: 6,
		borderRadius: 5,
		marginBottom: 6,
	},
	content: {
		flexDirection: "row",
	},
});

Font.register({
	family: "Oswald",
	src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

function MyDocument({ data }) {
	return (
		<>
			{data && data ? (
				<Document title={data.document_type + "_" + data.nuip}>
					<Page style={styles.body} wrap>
						<Image style={styles.image} src={logo} />
						<Text style={styles.subtitle}>Datos personales</Text>
						<View style={styles.wrapper}>
							<View style={styles.content}>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Nombres y Apellidos:</Text>
									<Text style={styles.datos}>
										{data.last_name} {data.first_name}
									</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Género:</Text>
									<Text style={styles.datos}>
										{data.gender === "M"
											? "Masculino"
											: data.gender === "F"
											? "Femenino"
											: "Otro"}
									</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Fecha de nacimiento:</Text>
									<Text style={styles.datos}>{data.birth_date}</Text>
								</View>
							</View>
							<View style={styles.content}>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Documento de Identidad:</Text>
									<Text style={styles.datos}>
										{data.document_type}-{data.nuip}
									</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Teléfono:</Text>
									<Text style={styles.datos}>{data.phone}</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Correo:</Text>
									<Text style={styles.datos}>{data.email}</Text>
								</View>
							</View>
						</View>
						<Text style={styles.subtitle}>Datos de dirección</Text>
						<View style={styles.wrapper}>
							<View style={styles.content}>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Estrato</Text>
									<Text style={styles.datos}>{data.stratum}</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Municipio:</Text>
									<Text style={styles.datos}>
										{data && data.municipality
											? (() => {
													switch (data.municipality) {
														case "AR":
															return "Arboledas";
														case "CA":
															return "Cucutilla";
														case "GR":
															return "Gramalote";
														case "LO":
															return "Lourdes";
														case "SP":
															return "Salazar de Las Palmas";
														case "SA":
															return "Santiago";
														case "VC":
															return "Villa Caro";
														case "CU":
															return "Cúcuta";
														case "EZ":
															return "El Zulia";
														case "LP":
															return "Los Patios";
														case "PS":
															return "Puerto Santander";
														case "SC":
															return "San Cayetano";
														case "VR":
															return "Villa del Rosario";
														case "BU":
															return "Bucarasica";
														case "ET":
															return "El Tarra";
														case "SAR":
															return "Sardinata";
														case "TI":
															return "Tibú";
														case "AB":
															return "Ábrego";
														case "CAC":
															return "Cáchira";
														case "CON":
															return "Convención";
														case "EC":
															return "El Carmen";
														case "HA":
															return "Hacarí";
														case "LE":
															return "La Esperanza";
														case "LPB":
															return "La Playa de Belén";
														case "OC":
															return "Ocaña";
														case "SCA":
															return "San Calixto";
														case "TE":
															return "Teorama";
														case "CAA":
															return "Cácota";
														case "CH":
															return "Chitagá";
														case "MU":
															return "Mutiscua";
														case "PA":
															return "Pamplona";
														case "PAM":
															return "Pamplonita";
														case "SDS":
															return "Santo Domingo de Silos";
														case "BO":
															return "Bochalema";
														case "CHI":
															return "Chinácota";
														case "DU":
															return "Durania";
														case "HER":
															return "Herrán";
														case "LAB":
															return "Labateca";
														case "RAG":
															return "Ragonvalia";
														case "TO":
															return "Toledo";

														default:
															return "Sin Especificar1";
													}
											  })()
											: "Sin Especificar"}
									</Text>
								</View>
								<View style={styles.content}>
									<View style={styles.datosinner}>
										<Text style={styles.datosp}>Dirección:</Text>
										<Text style={styles.datos}>{data.address}</Text>
									</View>
								</View>
							</View>
						</View>
						<Text style={styles.subtitle}>Salud</Text>
						<View style={styles.wrapper}>
							<View style={styles.content}>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>EPS:</Text>
									<Text style={styles.datos}>
										Prepagada: {data.prepaid_eps === true ? "Sí" : "No"}
									</Text>
									<Text style={styles.datos}>
										{data.eps && data.eps ? data.eps : "No tiene"}
									</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Patologías:</Text>
									<Text style={styles.datos}>{data.pathologies}</Text>
								</View>
							</View>
						</View>
						<Text style={styles.subtitleLatir}>Información Latir</Text>
						<View style={styles.wrapperLatir}>
							<View style={styles.content}>
								{/* <View style={styles.datosinner}>
									<Text style={styles.datosp}>Mesa</Text>
									<Text style={styles.datos}>{data.table}</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Nivel</Text>
									<Text style={styles.datos}>{data.level + 1}</Text>
								</View> */}
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Pago validado</Text>
									<Text style={styles.datos}>
										{data.validated_payment === true ? "Sí" : "No"}
									</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Consultas Realizadas</Text>
									<Text style={styles.datos}>{data.number_queries} / 1</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>VIP</Text>
									<Text style={styles.datos}>
										{data.client_vip === true ? "Sí" : "No"}
									</Text>
								</View>
							</View>
							{/* <View style={styles.content}>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Consultas Realizadas</Text>
									<Text style={styles.datos}>{data.number_queries} / 2</Text>
								</View>
							</View> */}
						</View>

						{/* <MyDocument	 break></MyDocument	> */}
						<Text
							style={styles.pageNumber}
							render={({ pageNumber, totalPages }) =>
								`${pageNumber} / ${totalPages}`
							}
							fixed
						/>
					</Page>
				</Document>
			) : (
				"No se pudieron obtener los datos del cliente"
			)}
		</>
	);
}

export default MyDocument;
