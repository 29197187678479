
import { useState } from 'react';

function SmallSetPagination({ list_page, count }) {
    const [currentPage, setCurrentPage] = useState(1);
    const listingsPerPage = 5;
    const totalPages = Math.ceil(count / listingsPerPage);

    const visitPage = (page) => {
        setCurrentPage(page);
        list_page(page);
    };

    const previous_number = () => {
        if (currentPage > 1) {
            visitPage(currentPage - 1);
        }
    };

    const next_number = () => {
        if (currentPage < totalPages) {
            visitPage(currentPage + 1);
        }
    };

    const getNumbers = () => {
        return [
            <div key="current" className={`hidden md:-mt-px md:flex`}>
                <div className={`cursor-pointer border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300`}>
                    Página {currentPage} de {totalPages}
                </div>
            </div>
        ];
    };

    // const getNumbers = () => {
    //     let numbers = [];
    //     for (let page = 1; page <= totalPages; page++) {
    //         numbers.push(
    //             <div key={page} onClick={() => visitPage(page)} className={`hidden md:-mt-px md:flex`}>
    //                 <div className={`cursor-pointer border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium ${currentPage === page ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}>
    //                     {page}
    //                 </div>
    //             </div>
    //         );
    //     }
    //     return numbers;
    // };

    return (
        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
                <button
                    onClick={previous_number}
                    disabled={currentPage === 1}
                    className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                >
                    {/* <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                    Anterior
                </button>
            </div>

            {getNumbers()}

            <div className="-mt-px w-0 flex-1 flex justify-end">
                <button
                    onClick={next_number}
                    disabled={currentPage === totalPages}
                    className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                >
                    Siguiente
                    {/* <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                </button>
            </div>
        </nav>
    );
}

export default SmallSetPagination;
