import {
	SEARCH_SUCCESS,
	SEARCH_ERROR
} from "../actions/search/types"

const initialState = {
	search: null,
	error: null,
};

export default function search(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SEARCH_SUCCESS:
			return {
				...state,
				search: payload,
				error: null, 
			};
		case SEARCH_ERROR:
			return {
				...state,
				search: null,
				error: payload,
			};
		default:
			return state;
	}
}
