import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import MyDocument from "../../../components/pdf/ReportCommission";
import { PDFViewer } from "@react-pdf/renderer";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import { get_commission_payments } from "../../../redux/actions/commissions/commissions";


function CommissionsReport({
	get_commission_payments,
	commissions_payments,
}) {
	const params = useParams();
	const id = params.id;

	useEffect(() => {
		get_commission_payments(id);
	}, [get_commission_payments]);

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Reporte de comisiones</title>
			</Helmet>
			<div className="container mx-auto max-w-5xl">
				{commissions_payments ? (
					<>
						<div className="">
							
								<PDFViewer className="w-full h-[75vh] md:h-[85vh]">
									<MyDocument data={commissions_payments}/>
								</PDFViewer>
					
						</div>
					</>
				) : (
					<div className="w-full h-full flex justify-center items-center">
						<Loading />
					</div>
				)}
			</div>
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	commissions_payments: state.commissions.commissions_payment,
});
export default connect(mapStateToProps, { get_commission_payments })(CommissionsReport);
