import {
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAIL,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  GET_BENEFICIARY_SUCCESS,
  GET_BENEFICIARY_FAIL,
  GET_CLIENT_SEARCH_SUCCESS,
  GET_CLIENT_SEARCH_FAIL,
	GET_CLIENT_SEARCH_NUIP_SUCCESS,
	GET_CLIENT_SEARCH_NUIP_FAIL,
	GET_CLIENT_DASHBOARD_SUCCESS,
	GET_CLIENT_DASHBOARD_FAIL,
	GET_CLIENT_DASHBOARD_LIST_BY_TABLE_SUCCESS,
	GET_CLIENT_DASHBOARD_LIST_BY_TABLE_FAIL,
} from "../actions/client/types";

const initialState = {
  client: null,
  clients: null,
  filtered_clients: null,
	clients_per_table: null,
  beneficiary: null,
  count: null,
  next: null,
  previous: null,
};

export default function clients(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clients: payload.results.clients,
				clients_per_table: payload.results.clients_per_table,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_CLIENT_LIST_FAIL:
      return {
        ...state,
        clients: null,
				clients_per_table: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        client: payload.client,
      };
    case GET_CLIENT_FAIL:
      return {
        ...state,
        client: null,
      };
    case GET_BENEFICIARY_SUCCESS:
      return {
        ...state,
        beneficiary: payload.beneficiary,
      };
    case GET_BENEFICIARY_FAIL:
      return {
        ...state,
        beneficiary: null,
      };
    case GET_CLIENT_SEARCH_SUCCESS:
      return {
        ...state,
        filtered_clients: payload.filtered_clients,
      };
    case GET_CLIENT_SEARCH_FAIL:
      return {
        ...state,
        filtered_clients: null,
      };
		case GET_CLIENT_DASHBOARD_SUCCESS:
			return {
				...state,
				clients: payload.tables,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_CLIENT_DASHBOARD_FAIL:
			return {
				...state,
				clients: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_CLIENT_DASHBOARD_LIST_BY_TABLE_SUCCESS:
			return {
				...state,
				clients: payload.table_clients,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_CLIENT_DASHBOARD_LIST_BY_TABLE_FAIL:
			return {
				...state,
				clients: null,
				count: null,
				next: null,
				previous: null,
			};

		case GET_CLIENT_SEARCH_NUIP_SUCCESS:
      return {
        ...state,
        filtered_clients: payload.filtered_clients,
      };
    case GET_CLIENT_SEARCH_NUIP_FAIL:
      return {
        ...state,
        filtered_clients: null,
      };
    default:
      return state;
  }
}
