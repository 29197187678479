import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { connect, useSelector } from "react-redux";
import {
	get_payments,
	get_payments_page,
} from "../../../redux/actions/payment/payment";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import PaymentsList from "../../../components/payments/PaymentsList";
import Loading from "../../../components/Loading";
import { IconFilter } from "@tabler/icons-react";


function Payments({ get_payments, get_payments_page, payments, count }) {
	const user = useSelector((state) => state.auth.user);
	const [term, setTerm] = useState("");

	const handleChange = (e) => {
		setTerm(e.target.value);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setTimeout(() => (window.location.href = "/search/payments/" + term), 0.2);
		setTerm("");
	};

	const [approved_siau, setApprovedSiau] = useState("");
	const [table, setTable] = useState("");
	const [payment_method, setPaymentMethod] = useState("");

	useEffect(() => {
		if (approved_siau !== "" || table !== "" || payment_method !== "") {
			get_payments(approved_siau, table, payment_method);
		} else {
			get_payments();
		}
	}, [get_payments, approved_siau, table, payment_method]);

	const handleChangeApovedSiau = (e) => {
		setApprovedSiau(e.target.value);
	};
	const handleChangeTable = (e) => {
		setTable(e.target.value);
	};
	const handleChangePaymentMethod = (e) => {
		setPaymentMethod(e.target.value);
	};


	const tableValues =
		useSelector((state) => state.auth.user?.table?.map((item) => item.table)) ||
		[];


	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Pagos</title>
			</Helmet>
			<div className="border-b border-gray-200 bg-white py-5">
				<div className="flex flex-col md:flex-row md:items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Lista de pagos
						</h3>
					</div>
					<div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
						<div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
							<form onSubmit={(e) => onSubmit(e)} className="w-full">
								<label htmlFor="search" className="sr-only">
									Buscar
								</label>
								<div className="relative">
									<button
										type="submit"
										className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth={1.5}
											stroke="currentColor"
											className="w-6 h-6"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
											/>
										</svg>
									</button>
									<input
										id="search"
										name="search"
										required
										onChange={(e) => {
											handleChange(e);
										}}
										className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										placeholder="Buscar"
										type="search"
									/>
								</div>
							</form>
						</div>
						<div className="dropdown dropdown-end">
							<div
								tabIndex={0}
								role="button"
								className="p-1.5 bg-white border border-[#0096C4] rounded"
							>
								<IconFilter stroke="#0096C4" />
							</div>
							<div
								tabIndex={0}
								className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
							>
								<div className="flex flex-col gap-y-2">
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Aprobado por SIAU</span>
										</div>
										<select
											onChange={(e) => handleChangeApovedSiau(e)}
											value={approved_siau}
											className="select select-bordered w-full"
										>
											<option value="">Aprobado SIAU</option>
											<option value="true">Si</option>
											<option value="false">No</option>
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Mesa</span>
										</div>
										<select
											onChange={(e) => handleChangeTable(e)}
											value={table}
											className="select select-bordered w-full"
										>
											<option value="">Mesa</option>
											{tableValues &&
												tableValues.map((value) => (
													<option key={value} value={value}>
														{value}
													</option>
												))}
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Métodos de pago</span>
										</div>
										<select
											onChange={(e) => handleChangePaymentMethod(e)}
											value={payment_method}
											className="select select-bordered w-full"
										>
											<option value="">Metodo de pago</option>
											<option value="EFECTIVO">Efectivo</option>
											<option value="TARJETA DE CREDITO">Tarjeta de crédito</option>
											<option value="TRANSFERENCIA">Transferencia</option>
											<option value="NEQUI">Nequi</option>
											<option value="DAVIPLATA">Daviplata</option>
											<option value="BANCOLOMBIA">Bancolombia</option>
											<option value="DAVIVIENDA">Davivienda</option>
											<option value="CREDITO POR LIBRANZA">Credito por libranza</option>
										</select>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{payments && payments ? (
				<PaymentsList
					className="relative"
					payments={payments}
					get_payments={get_payments_page}
					user={user}
					count={count}
				/>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
		</DashboardLayout>
	);
}
const mapStateToProps = (state) => ({
	payments: state.payments.payments,
	count: state.payments.count,
});
export default connect(mapStateToProps, {
	get_payments,
	get_payments_page,
})(Payments);
