import axios from "axios";
import {
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_FAIL,
  GET_PAYMENT_SUCCESS_ID,
  GET_PAYMENT_FAIL_ID,
  GET_PAYMENT_SEARCH_SUCCESS,
  GET_PAYMENT_SEARCH_FAIL,
} from "./types";

// listar todas las categorias
export const get_payments = (approved_siau=null,table=null,payment_method=null) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

	let url = `${process.env.REACT_APP_API_URL}/payment/list/`;
	let params = new URLSearchParams();

	if (approved_siau !== null && approved_siau !== "") {
		params.append('approved_siau', approved_siau);
	}
	if (table !== null && table !== "") {
		params.append('table', table);
	}
	if (payment_method !== null && payment_method !== "") {
		params.append('payment_method', payment_method);
	}

	url += `?${params.toString()}`;

  try {
    const res = await axios.get(url,config);
    if (res.status === 200) {
      dispatch({
        type: GET_PAYMENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PAYMENT_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PAYMENT_FAIL,
    });
  }
};
export const get_payments_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PAYMENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PAYMENT_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_PAYMENT_FAIL,
    });
  }
};

export const get_payment = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_PAYMENT_SUCCESS_ID,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PAYMENT_FAIL_ID,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PAYMENT_FAIL_ID,
    });
  }
};

export const search_payment = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/search/${search_term}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PAYMENT_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PAYMENT_SEARCH_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PAYMENT_SEARCH_FAIL,
    });
  }
};
