import { connect } from "react-redux";
import ClientsCardHorizontal from "./ClientsCardHorizontal";

function ClientListSearch({ get_clients_list, clients, count }) {
	return (
		<div className="overflow-hidden bg-white">
			<div className="relative overflow-x-auto">
				<table className="table auto text-gray-500">
				<thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2">
                
              </th>
							<th scope="col" className="p-2">
                <div className="w-64">Datos</div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-52">Contacto</div>
              </th>
              <th scope="col" className="p-2">
                Patologías
              </th>
              <th scope="col" className="p-2">
                Estrato
              </th>
              <th scope="col" className="p-2">
                <div className="w-28">Plan</div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-16">Nivel</div> 
              </th> 
              <th scope="col" className="p-2">
                <div className="w-40">Fecha</div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-28">
                  Estatus
                </div>
              </th>
							<th scope="col" className="p-2">
								<div className="w-28">
									<div className="w-28">Imagenes</div>
								</div>
							</th>
              <th scope="col" className="p-2">
                Acciones
              </th>

              {/* <th></th> */}
            </tr>
          </thead>
					<tbody>
						{clients &&
							clients.map((client, index) => (
								<ClientsCardHorizontal
									data={client}
									key={index}
									index={index}
								/>
							))}
					</tbody>
				</table>
			</div>
			
		</div>
	);
}

const mapStateToProps = (state) => ({
	count: state.clients.count,
});

export default connect(mapStateToProps, {})(ClientListSearch);
