import SmallSetPagination from "../pagination/SmallSetPagination";
import PaymentsCardHorizontal from "./PaymentsCardHorizontal";

function PaymentsList({ payments, get_payments, count, user }) {
	return (
		<div className="overflow-hidden bg-white">
			<div className="relative overflow-x-auto">
				<table className="table text-gray-500">
					<thead className="text-xs text-gray-700 uppercase">
						<tr>
							<th scope="col" className="p-2">
								<div className="w-52">Datos</div>
							</th>
							<th scope="col" className="p-2">
								<div className="w-48">Factura</div>
							</th>
							<th scope="col" className="p-2">
								<div className="w-44">Fecha</div>
							</th>
							<th scope="col" className="p-2">
							Tipo de Pago
							</th>
							<th scope="col" className="p-2">
								<div className="w-36">Estatus</div>
							</th>
							{user && user.role === "CONTABILIDAD" && (
								<th scope="col" className="p-2">
									<div className="w-36">Vigente</div>
								</th>
							)}
							<th scope="col" className="p-2">
								Acciones
							</th>
						</tr>
					</thead>
					<tbody>
						{payments &&
							payments.map((payment, index) => (
								<PaymentsCardHorizontal
									data={payment}
									key={index}
									index={index}
									user={user}
								/>
							))}
					</tbody>
				</table>
			</div>
			<SmallSetPagination
				list_page={get_payments}
				list={payments}
				count={count}
			/>
		</div>
	);
}

export default PaymentsList;
