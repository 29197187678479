import React from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import logo from '../../assets/img/logo.png';


const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Oswald',
  },
	subtitleLatir: {
    fontSize: 14,
    fontFamily: 'Oswald',
		color: '#00496B',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 150,
    width: 200,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  datosp: {
    fontSize: 13,
    fontFamily: 'Oswald',
    marginTop: 3,

  },
  datosinner:{
    width: '33.33%',
    marginBottom: 6,
  },
  datos: {
    fontSize: 11,
    color: '#676767',
  },
  wrapper: {
    flexDirection: 'col',
    border: '1px solid #676767',
    padding: 6,
    borderRadius: 5,
    marginBottom: 6,
  },
	wrapperLatir: {
    flexDirection: 'col',
    border: '1px solid #676767',
		borderColor: '#00496B',
    padding: 6,
    borderRadius: 5,
    marginBottom: 6,
  },
  content: {
    flexDirection: 'row',
  },
	payed: {
		position: 'absolute',
		top: '90%',
		left: 0,
		color: '#FF0000',
		padding: 8,
		opacity: 0.7,
		fontWeight: 'black',
		textAlign: 'center',
		fontSize: 30,
		width: '170%',
		transform: 'rotate(-45deg)',
		transformOrigin: 'bottom left',
	},
	payedText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

});

// const commissionAmountDetails = Object.entries(data.commission_amount).filter(([nivel, detalles]) => nivel.includes("nivel")).map(([nivel, detalles]) => ({nivel, detalles}) );


Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

function MyDocument({data}) {
	let commissionAmountDetails = {};
	try {
		commissionAmountDetails = data && JSON.parse(data.commission_amount);
	} catch (e) {
		console.error("Error al parsear los datos de comisión", e);
	}

	return (
		<>
			{data && data ? (
				<Document title={data.id}>
					<Page style={styles.body} wrap>
						<Image 
							style={styles.image}
							src={logo}
						/>
						<Text style={styles.subtitle}>
							Datos personales
						</Text>
						<View style={styles.wrapper}>
							<View style={styles.content}>
								{data.user.role === "BANCO" || data.user.role === "COSTOS Y GASTOS" || data.user.role === "IMPUESTOS" ? (
									<View style={styles.datosinner}>
										<Text style={styles.datosp}>Rol: </Text>
										<Text style={styles.datos}>{data.user.role}</Text>
									</View>
								) : (
									<>
										<View style={styles.datosinner}>
											<Text style={styles.datosp}>Nombres y Apellidos:</Text>
											<Text style={styles.datos}>{ data.user.last_name} { data.user.first_name }</Text>
										</View>
										<View style={styles.datosinner}>
											<Text style={styles.datosp}>Rol:</Text>
											<Text style={styles.datos}>
												{data.user.role}
											</Text>
										</View>
										<View style={styles.datosinner}>
											<Text style={styles.datosp}>Documento de Identidad:</Text>
											<Text style={styles.datos}>{ data.user.document_type }-{ data.user.nuip }</Text>
										</View>
									</>
								)}
							</View>
							<View style={styles.content}>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Mesas asignadas: </Text>
									<Text style={styles.datos}>
										{data.table.table}
									</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Fecha:</Text>
									<Text style={styles.datos}>
										{data.created_at && new Date(data.created_at).toLocaleDateString()}
									</Text>
								</View>
							</View>
						</View>
						<Text style={styles.subtitle}>
							Comisiones
						</Text>
						<View style={styles.wrapper}>
							<View style={styles.content}>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Total por pagar</Text>
									<Text style={styles.datos}>$ {data.total}</Text>
								</View>
								<View style={styles.datosinner}>
									<Text style={styles.datosp}>Pagado</Text>
									<Text style={styles.datos}>{data.paid === true ? "Sí" : "No"}</Text>
								</View>
							</View>
							<View style={styles.content}>
								<Text style={styles.datosp}>Detalles</Text>
							</View>
							<View style={styles.content}>
								{data.commission_amount ? (
									Object.entries(commissionAmountDetails).map(([nivel, detalles]) => {
										if (nivel.includes("nivel")) {
											const formattedLevel = nivel.replace(/_/g, " ").replace(/\b\w/g, l => l.toUpperCase());
											return (
												<View style={styles.datosinner} key={nivel}>
													<View>
														<Text style={styles.datosp}>{formattedLevel}: </Text>
														{detalles.monto > 0 && (
															<Text style={styles.datos}>Monto: ${detalles.monto}</Text>
														)}
														{detalles.clientes > 0 && (
															<Text style={styles.datos}>Clientes: {detalles.clientes}</Text>
														)}
													</View>
												</View>
											);
										}
									})
								) : null}
							</View>
							<View style={styles.content}>
							{data.commission_amount ? (
								Object.entries(commissionAmountDetails).map(([bono, detalles]) => {
									if(bono.includes("bono")){
										let formattedLevel = bono.replace("bono", "Bono").replace("_", " ");
										if (formattedLevel === "Bono end_line") {
											formattedLevel = "Bono de fin de línea";
										}
										return (
											<View style={styles.datosinner} key={bono}>
												<View>
													<Text style={styles.datosp}>{formattedLevel}: </Text>
													{detalles.bono_de_completar_registros > 0 && (
														<Text style={styles.datos}>Monto: ${detalles.bono_de_completar_registros}</Text>
													)}
													{detalles > 0 && (
														<Text style={styles.datos}>Monto: ${detalles}</Text>
													)}
												</View>
											</View>
										);
									}
								})
							) : null}
							</View>
						</View>
		
						{/* <MyDocument	 break></MyDocument	> */}
						<Text
							style={styles.pageNumber}
							render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
							fixed
						/>

						{data.paid === true ? (
							<View style={styles.payed}>
								<Text style={styles.payedText}>
									PAGADO
									-
									PAGADO
									-
									PAGADO
									-
									PAGADO
									-
									PAGADO
								</Text>
							</View>
						) : (
							null
						)}
					</Page>
				</Document>
			) : ("No se pudieron obtener los datos del cliente")}
		</>
	)

}

export default MyDocument;