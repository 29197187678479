import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ChatIcon } from "@heroicons/react/outline"; // Asegúrate de tener heroicons instalado

import { XIcon } from "@heroicons/react/solid"; 

function Chat() {
	const [socket, setSocket] = useState(null);
	const [username, setUsername] = useState("");
	const [room, setRoom] = useState("");
	const [message, setMessage] = useState("");
	const [messages, setMessages] = useState([]);
	const [activeUsers, setActiveUsers] = useState([]);
	const [isChatOpen, setIsChatOpen] = useState(false);
	const [unreadMessages, setUnreadMessages] = useState(0);

	const messagesEndRef = useRef();

	// Selector de usuario simulado desde el almacenamiento Redux
	const user = useSelector((state) => state.auth.user);

	// Función para establecer datos en sessionStorage
	const setSessionStorage = (key, value) => {
		sessionStorage.setItem(key, value);
	};

	// Función para obtener datos de sessionStorage
	const getSessionStorage = (key) => {
		return sessionStorage.getItem(key);
	};

	function capitalize(name) {
		if (!name) return "";
		return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
	}

	// Manejar la configuración de nombre de usuario y sala
	useEffect(() => {
		// Construir el nombre de usuario a partir del objeto de usuario y establecerlo
		if (user && user.first_name && user.last_name) {
			const fullName = `${capitalize(user.first_name)} ${capitalize(
				user.last_name
			)}`;
			setUsername(fullName); // Establecer estado
			setSessionStorage("username", fullName);
		}

		// Recuperar la sala de sessionStorage o establecer un valor predeterminado
		const storedRoom = getSessionStorage("room") || "Todos";
		setRoom(storedRoom); // Establecer estado
		if (!getSessionStorage("room")) {
			setSessionStorage("room", storedRoom); // Establecer sessionStorage solo si aún no está establecido
		}
	}, [user]);

	// Inicialización de la conexión WebSocket
	const wsScheme = window.location.protocol === "https:" ? "wss://" : "ws://";
	const wsScheme2 =
		window.location.protocol === "https:"
			? window.location.host
			: "localhost:8000";
	const token = user && user.id;
	const webSocketUrl = `${wsScheme}${wsScheme2}/ws/chat/public_room/?token=${token}`;

	useEffect(() => {
		if (username && room) {
			if (!socket) {
				const newSocket = new WebSocket(webSocketUrl);

				newSocket.onopen = () => console.log("WebSocket connected");
				newSocket.onclose = () => {
					console.log("WebSocket disconnected");
					sessionStorage.removeItem("username");
					sessionStorage.removeItem("room");
				};
				newSocket.onmessage = (event) => {
					const data = JSON.parse(event.data);
					if (data.user_list) {
						setActiveUsers(data.user_list);
					} else {
						setMessages((prevMessages) => [...prevMessages, data]);
						setUnreadMessages((prevUnreadMessages) => prevUnreadMessages + 1); // Incrementar el número de mensajes sin leer
					}
				};

				setSocket(newSocket);
			}

			return () => {
				// No hacer nada al desmontar para mantener la conexión WebSocket activa
			};
		}
	}, [username, room]);

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [messages]);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (message && socket) {
			const data = {
				message: message,
				username: username,
				timestamp: new Date().toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })

			};
			socket.send(JSON.stringify(data));
			setMessage("");

			const storedMessages = JSON.parse(sessionStorage.getItem("messages")) || [];
			const updatedMessages = [...storedMessages, data];
			sessionStorage.setItem("messages", JSON.stringify(updatedMessages));
		}
	};

	useEffect(() => {
		const storedMessages = JSON.parse(sessionStorage.getItem("messages")) || [];
		setMessages(storedMessages);
	}, []);

	const toggleChat = () => {
		setIsChatOpen(!isChatOpen);
		setUnreadMessages(0); // Restablecer el número de mensajes sin leer al abrir el chat
	};

	return (
		<div className="fixed bottom-4 right-4 flex flex-col items-end">
			{isChatOpen && (
				<div className="bg-white shadow-lg rounded-lg overflow-hidden w-80">
					<div className="bg-[#0096C4] text-white text-lg p-2 flex">Sala: {room}
					<button
				onClick={toggleChat}
				className={`text-white rounded-full text-md w-8 flex justify-end m-auto mr-2`}
				aria-label="Toggle Chat"
			>
			<XIcon />
			</button>
					</div>
					<div className="flex flex-col justify-between h-96">
						<div className="overflow-auto p-2" ref={messagesEndRef}>
							<h2 className="text-sm font-semibold">
								Usuarios Activos ({activeUsers.length})
							</h2>
							<ul>
								{activeUsers.map(
									(user, index) =>
										// <li key={index} className="text-xs p-1">{user}</li>
										""
								)}
							</ul>
							{messages.map((message, index) => {
								const lastMessage = messages[index - 1];
								const nextMessage = messages[index + 1];
								const isGroupStart =
									!lastMessage || lastMessage.username !== message.username;
								const isGroupEnd =
									!nextMessage || nextMessage.username !== message.username;

								return (
									<div
										key={index}
										className={`chat ${
											message.username === username ? "chat-end" : "chat-start"
										}`}
									>
										{isGroupStart && (
											<div className="chat-header">{message.username}</div>
										)}
										<>
											<div
												className={`chat-bubble ${
													message.username === username
														? "chat-bubble-success"
														: "chat-bubble-primary"
												} w-fit overflow-hidden whitespace-normal break-words `}
											>
												{message.message}
											</div>
										</>
										{isGroupEnd && (
											<>
												<div className="chat-footer opacity-50">
													{`Enviado ${message.timestamp}`}
												</div>
											</>
										)}
									</div>
								);
							})}
						</div>
						<form onSubmit={handleSubmit} className="p-2 flex">
							<input
								type="text"
								placeholder="Escribe un mensaje..."
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								className="flex-1 border rounded p-1 mr-2 text-sm"
							/>
							<button
								type="submit"
								className="bg-[#0096C4] text-white rounded px-4 py-1 text-sm"
							>
								Enviar
							</button>
						</form>
					</div>
				</div>
			)}

			<button
				onClick={toggleChat}
				className={`text-white p-4 rounded-full shadow-lg text-lg bg-[#0096C4] mt-2`}
				aria-label="Toggle Chat"
			>

				{isChatOpen ? "Cerrar" : (
					<>
						<ChatIcon className="h-6 w-6" />
						{unreadMessages > 0 && <span className="bg-red-500 text-white rounded-full px-2 py-1 text-xs absolute -top-1 -right-1">{unreadMessages}</span>}
					</>
				)}

			</button>
		</div>
	);
}

export default Chat;
