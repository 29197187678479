import { useEffect, useState } from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { get_client_dashboard } from "../redux/actions/client/client";
import Loading from "../components/Loading";
import ProgressBar from "../components/ProgressBar";
import { Link } from "react-router-dom";

function Dashboard({ get_client_dashboard, clients }) {
	useEffect(() => {
		get_client_dashboard();
	}, [get_client_dashboard]);

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Dashboard</title>
			</Helmet>
			<div className="border-b border-gray-200 bg-white py-5">
				<div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Dashboard
						</h3>
					</div>
				</div>
			</div>

      {clients && clients ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-6">
          {clients.map((client, index) => (
            <Link to={`/dashboard/${client.table_id}`} key={index}>
              <div className="p-2 bg-white shadow-md rounded-lg">
                <h5 className="text-center mb-2 font-medium">Mesa {client.name}</h5>
                <div className="grid grid-cols-2 gap-x-3">
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 1
                    </span>
                    <ProgressBar value={client.levels && client.levels[0]} max={1} bg="bg-level-1" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 2
                    </span>
                    <ProgressBar value={client.levels && client.levels[1]} max={2} bg="bg-level-2" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 3
                    </span>
                    <ProgressBar value={client.levels && client.levels[2]} max={4} bg="bg-level-3" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 4
                    </span>
                    <ProgressBar value={client.levels && client.levels[3]} max={8} bg="bg-level-4" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 5
                    </span>
                    <ProgressBar value={client.levels && client.levels[4]} max={16} bg="bg-level-5" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 6
                    </span>
                    <ProgressBar value={client.levels&& client.levels[5]} max={32} bg="bg-level-6" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 7
                    </span>
                    <ProgressBar value={client.levels && client.levels[6]} max={64} bg="bg-level-7" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 8
                    </span>
                    <ProgressBar value={client.levels && client.levels[7]} max={128} bg="bg-level-8" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 9
                    </span>
                    <ProgressBar value={client.levels && client.levels[8]} max={256} bg="bg-level-9" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 10
                    </span>
                    <ProgressBar value={client.levels && client.levels[9]} max={512} bg="bg-level-10" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 11
                    </span>
                    <ProgressBar value={client.levels && client.levels[10]} max={1024} bg="bg-level-11" />
                  </div>
                  <div className="flex items-center gap-x-2 my-1">
                    <span className="text-xs w-2/5 whitespace-nowrap">
                      Nivel 12
                    </span>
                    <ProgressBar value={client.levels && client.levels[11]} max={2048} bg="bg-level-12" />
                  </div>
                </div>
								<h6 className="text-right mt-2 text-sm font-medium">Total Mesa: <strong>{client.total}</strong></h6>
              </div>
            </Link>
          ))}  
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <Loading />
        </div>
      )}

		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	clients: state.clients.clients,
});
export default connect(mapStateToProps, {
	get_client_dashboard,
})(Dashboard);
