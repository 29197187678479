import SmallSetPagination from "../pagination/SmallSetPagination";
import SharedTokenCardHorizontal from "./SharedTokenCardHorizontal";

function SharedTokenList({ tokens, get_shared_tokens, count }) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2">
                Datos
              </th>
              <th scope="col" className="p-2">
                Código de Token
              </th>
              <th scope="col" className="p-2">
                Fecha de Creación
              </th>
              <th scope="col" className="p-2">
                <div className="w-32">
                  Estatus
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tokens &&
              tokens.map((token, index) => (
                <SharedTokenCardHorizontal
                  data={token}
                  key={index}
                  index={index}
                />
              ))}
          </tbody>
        </table>
      </div>
      <SmallSetPagination
        list_page={get_shared_tokens}
        list={tokens}
        count={count}
      />
    </div>
  );
}

export default SharedTokenList;
