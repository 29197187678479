import React from "react";
import { Link } from "react-router-dom";
import { IconPencil } from "@tabler/icons-react";

function CommissionsCardHorizontal({ data, index }) {

  return (
    <React.Fragment key={index}>
      <tr className="bg-white border-b">
        <td className="p-2 text-xs">
          <div className="w-44">
            <span className="font-bold">Rol:</span> {data.rol}
          </div>
          <div className="w-44">
            <span className="font-bold">Nivel:</span> {data.nivel}
          </div>
          <div className="w-44">
            <span className="font-bold">Compensacion:</span> ${data.monto_compensation}
          </div>
        </td>
				<td className="p-2 text-xs">
					<div className="w-44">
						{data.monto_bonus_1 === null || data.monto_bonus_1 === "none" || data.monto_bonus_1 === "0.00" || data.monto_bonus_1 === "0,00" ? "-" : (<><span className="font-bold">Bono 1:</span> ${data.monto_bonus_1}</>)}
					</div>
					<div className="w-44">
						{data.monto_bonu2 === null || data.monto_bonu2 === "none" || data.monto_bonu2 === "0.00" || data.monto_bonu2 === "0,00" ? "-" : (<><span className="font-bold">Bono 2:</span> ${data.monto_bonu2}</>)}
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="w-44">
						{data.monto_bonus_end_line_1 === null ||  data.monto_bonus_end_line_1 === "none" || data.monto_bonus_end_line_1 === "0.00" || data.monto_bonus_end_line_1 === "0,00" ? "-" : (<><span className="font-bold">Bono Final 1:</span> ${data.monto_bonus_end_line_1}</>)}
					</div>
				
				</td>
				<td className="p-2 text-xs">
          <div className="flex gap-2">
            <div className="tooltip flex" data-tip="Editar">
              <Link
                to={`/commissions-edit/users/${data.id}`}
                className="p-1 bg-yellow-500 text-white rounded-full"
              >
                <IconPencil />
              </Link>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}
export default CommissionsCardHorizontal;
