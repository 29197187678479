import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
	check_authenticated,
	load_user,
	login,
	refresh,
} from "../redux/actions/auth/auth";
import { Navigate } from "react-router-dom";
import img_login from "../assets/img/img-login.png";
import img_logo from "../assets/img/logo.png";

import Toast from "../components/Alert";
import { Helmet } from "react-helmet-async";
import HomeLayout from "../hocs/layout/HomeLayout";
import { Link } from "react-router-dom";
import HomeNavbar from "../components/navigation/HomeNavbar";
import { IconEye } from "@tabler/icons-react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function Login({
	login,
	isAuthenticated,
	refresh,
	check_authenticated,
	load_user,
}) {
	const [loading, setLoading] = useState(false);
	const [term, setTerm] = useState("");

	// State to toggle password visibility
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		isAuthenticated ? (
			<></>
		) : (
			<>
				{refresh()}
				{check_authenticated()}
				{load_user()}
			</>
		);
	}, [isAuthenticated, refresh, check_authenticated, load_user]);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const { email, password } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		login(email, password);
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	if (isAuthenticated) {
		// Redireccionar al dashboard y mostrar la alerta de éxito
		const redirectToDashboard = () => {
			Toast({ tipo: "success", mensaje: "Has Iniciado Sesion!" });
			return <Navigate to="/dashboard" />;
		};

		// Renderizar la redirección y la alerta
		return redirectToDashboard();
	}

	return (
		<HomeLayout>
			<HomeNavbar />
			<Helmet>
				<title>Latir | Login</title>
			</Helmet>
			<main className="h-auto lg:h-screen py-24 lg:py-0 lg:flex lg:items-center bg-gray-100">
				<div className="container mx-auto w-10/12 md:w-auto">
					<div className="flex justify-center">
						<div className="card lg:card-side bg-base-100 shadow-xl">
							<figure>
								<img
									src={img_login}
									className="w-[320px] lg:w-auto lg:h-[495px]"
									alt="login"
								/>
							</figure>
							<div className="card-body w-80 px-0 py-4">
								<div className="top flex justify-between lg:flex-col">
									<div className="bg-[#00496B] pl-8 pr-3 py-1.5 lg:w-36 rounded-e-full text-white font-bold">
										Bienvenido
									</div>
									<div className="flex md:justify-center lg:mt-3 pr-5 lg:pr-0">
										<img className="w-28 md:w-32" src={img_logo} alt="Logo" />
									</div>
								</div>
								<div className=" px-8">
									<div className="flex justify-center my-2">
                    <div className="w-full max-w-md px-2 sm:px-0">
											<div className="">
												<div className="">
													<h2 className="text-center text-gray-900 text-lg font-bold">
														Ingresa a tu cuenta
													</h2>
												</div>
												<form
													onSubmit={(e) => {
														onSubmit(e);
													}}
													className="mt-2"
													action="#"
													method="POST"
												>
													<input
														type="hidden"
														name="remember"
														defaultValue="true"
													/>
													<div className="">
														<div className="mb-2">
															<label htmlFor="email-address" className="">
																Correo
															</label>
															<input
																id="email-address"
																name="email"
																value={email}
																onChange={(e) => onChange(e)}
																type="email"
																required
																className="w-full rounded-lg px-3 py-2 text-gray-900 bg-gray-200 focus:z-10 sm:text-sm"
															/>
														</div>
														<div className="relative">
														
															<label htmlFor="password" className="">
																Contraseña
															</label>
															<label className="input-bordered flex items-center gap-2">
															<input
																id="password"
																name="password"
																value={password}
																onChange={(e) => onChange(e)}
																type={showPassword ? "text" : "password"}  // Toggle between text and password
																required
																className="w-full rounded-lg px-3 py-2 text-gray-900 bg-gray-200 focus:z-10 sm:text-sm"
															/>
															<button
																type="button"
																onClick={togglePasswordVisibility}
																className="grow"
															>
																<IconEye 
																	size={20} 
																	stroke={1.5} 
																	color={showPassword ? "#00496B" : "#9CA3AF"} 
																/>
															</button>
															</label>
														</div>
													</div>

													<div className="mt-6 lg:mt-8 flex justify-center">
														<button
															type="submit"
															className="rounded-lg border border-transparent bg-[#00496B] px-6 py-2 text-sm text-white"
														>
															Ingresar
														</button>
													</div>
													<div className="mt-4 lg:mt-8 text-center text-sm">
														<Link
															to="/forgot_password"
															className="text-[#00496B] border-b border-b-[#00496B] pb-1"
														>
															¿Olvidó su contraseña?
														</Link>
													</div>
												</form>
											</div>
                    </div>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</HomeLayout>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	loading: state.auth.loading,
});

export default connect(mapStateToProps, {
	login,
	refresh,
	check_authenticated,
	load_user,
})(Login);
