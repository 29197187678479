import ClientsCardHorizontal from "./ClientsCardHorizontal";
import SmallSetPagination from "../pagination/SmallSetPagination";

function ClientsList({
  clients,
  get_clients_list,
  get_client_list_page,
  count,
	refreshData,
}) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2">
                
              </th>
							<th scope="col" className="p-2">
                <div className="w-64">Datos</div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-52">Contacto</div>
              </th>
              <th scope="col" className="p-2">
                Patologías
              </th>
              <th scope="col" className="p-2">
                Estrato
              </th>
              <th scope="col" className="p-2">
                <div className="w-28">Plan</div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-16">Nivel</div> 
              </th> 
              <th scope="col" className="p-2">
                <div className="w-40">Fecha</div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-28">
                  Estatus
                </div>
              </th>
							<th scope="col" className="p-2">
								<div className="w-28">
									<div className="w-28">Imagenes</div>
								</div>
							</th>
              <th scope="col" className="p-2">
                Acciones
              </th>

              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {clients &&
              clients.map((client, index) => (
                <ClientsCardHorizontal
                  data={client}
                  key={index}
                  index={index}
									refreshData={refreshData}
                />
              ))}
          </tbody>
        </table>
      </div>
      <SmallSetPagination
        list_page={get_client_list_page}
        list={clients}
        count={count}
      />
    </div>
  );
}
export default ClientsList;
