import { connect } from "react-redux";
import { IconLock } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
	check_authenticated,
	load_user,
	refresh,
	reset_password_confirm,
} from "../../redux/actions/auth/auth";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import img_logo from "../../assets/img/logo.png";
import HomeLayout from "../../hocs/layout/HomeLayout";
import { Helmet } from "react-helmet-async";
import Toast from "../../components/Alert";

function ResetPasswordConfirm({
	reset_password_confirm,
	isAuthenticated,
	refresh,
	check_authenticated,
	load_user,
}) {
	const params = useParams();
	const uid = params.uid;
	const token = params.token;

	useEffect(() => {
		isAuthenticated ? (
			<></>
		) : (
			<>
				{refresh()}
				{check_authenticated()}
				{load_user()}
			</>
		);
	}, [refresh, check_authenticated, load_user, isAuthenticated]);

	const [formData, setFormData] = useState({
		new_password: "",
		re_new_password: "",
	});

	const { new_password, re_new_password } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const navigate = useNavigate();

	const onSubmit = (e) => {
		e.preventDefault();
		reset_password_confirm(uid, token, new_password, re_new_password);
		Toast({ tipo: "success", mensaje: "Contraseña cambiada correctamente" });
		navigate("/");
	};

	if (isAuthenticated) {
		
		return <Navigate to="/dashboard" />;
	}

	return (
		<HomeLayout>
			<Helmet>
				<title>Latir | Confirmar Reinicio de Contraseña</title>
			</Helmet>
			<div className="flex h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
				<div className="w-full max-w-md space-y-8 bg-white shadow-lg p-4 rounded-lg">
					<div>
						<img
							className="mx-auto h-20 w-auto"
							src={img_logo}
							alt="Your Company"
						/>
					</div>
					<form
						onSubmit={(e) => {
							onSubmit(e);
						}}
						className="mt-8 space-y-6"
						action="#"
						method="POST"
					>
						<input type="hidden" name="remember" defaultValue="true" />
						<div className="-space-y-px rounded-md shadow-sm">
							<div className="mb-3">
								<label htmlFor="email-address" className="sr-only">
									Password
								</label>
								<input
									name="new_password"
									value={new_password}
									onChange={(e) => onChange(e)}
									type="password"
									required
									className="input input-bordered w-full border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-[#00496B] focus:outline-none focus:ring-[#00496B] sm:text-sm"
									placeholder="Nueva Contraseña"
								/>
							</div>
							<div>
								<label htmlFor="password" className="sr-only">
									Repeat Password
								</label>
								<input
									name="re_new_password"
									value={re_new_password}
									onChange={(e) => onChange(e)}
									type="password"
									required
									className="input input-bordered w-full border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-[#00496B] focus:outline-none focus:ring-[#00496B] sm:text-sm"
									placeholder="Repetir Contraseña"
								/>
							</div>
						</div>

						<div className="flex items-center justify-between">
							<div className="text-sm">
								<Link
									to="/forgot_password"
									className="font-medium text-[#0096C4] hover:text-[#00496B] "
								>
									¿Olvidaste tu contraseña?
								</Link>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="group relative flex w-full justify-center rounded-md border border-transparent bg-[#0096C4] py-2 px-4 text-sm font-medium text-white hover:bg-[#00496B] focus:outline-none focus:ring-2 focus:ring-[#00496B] focus:ring-offset-2"
							>
								<span className="absolute inset-y-0 left-0 flex items-center pl-3">
									<IconLock className="h-5 w-5 text-white" aria-hidden="true" />
								</span>
								Cambiar la Contraseña
							</button>
						</div>
					</form>
				</div>
			</div>
		</HomeLayout>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	loading: state.auth.loading,
});

export default connect(mapStateToProps, {
	reset_password_confirm,
	refresh,
	check_authenticated,
	load_user,
})(ResetPasswordConfirm);
