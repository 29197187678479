import React from "react";
import { Link } from "react-router-dom";
import { IconPencil } from "@tabler/icons-react";

function CommissionsCardHorizontal({ data, index }) {

  return (
    <React.Fragment key={index}>
      <tr className="bg-white border-b">
        <td className="p-2 text-xs">
          <div className="w-56">
            <span className="font-bold">{data.choices}</span>
          </div>
        </td>
				<td className="p-2 text-xs">
					<div className="w-44">
						{data.amount === null || data.amount === "none" || data.amount === "0.00" || data.amount === "0,00" ? "-" : `$${data.amount}`}
					</div>
				</td>
				<td className="p-2 text-xs">
          <div className="flex gap-2">
            <div className="tooltip flex" data-tip="Editar">
              <Link
                to={`/commissions-edit/enterprises/${data.id}`}
                className="p-1 bg-yellow-500 text-white rounded-full"
              >
                <IconPencil />
              </Link>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}
export default CommissionsCardHorizontal;
