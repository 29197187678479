import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import Loading from "../../../components/Loading";
import { IconFilter } from "@tabler/icons-react";
import {
	get_enterprise_list,
	get_enterprise_list_page,
} from "../../../redux/actions/enterprises/enterprises";
import EnterprisesList from "../../../components/enterprises/EnterprisesList";
function Enterprise({
	get_enterprise_list,
	get_enterprise_list_page,
	count,
	enterprises,
}) {
	const [corpoindustrial, setCorpoindustrial] = useState("");

	const [term, setTerm] = useState("");

	const handleChange = (e) => {
		setTerm(e.target.value);
	};
	useEffect(() => {
		if (corpoindustrial !== "" ) {
			get_enterprise_list(corpoindustrial);
		} else {
			get_enterprise_list();
		}
	}, [get_enterprise_list, corpoindustrial]);

	const onSubmit = (e) => {
		e.preventDefault();
		setTimeout(() => (window.location.href = "/search/clients/" + term), 0.2);
		setTerm("");
	};

	const handleChangeCorpoindustrial = (e) => {
		setCorpoindustrial(e.target.value);
	};
	const refreshData = () => {
		get_enterprise_list();
	}
	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Empresas</title>
			</Helmet>
			<div className="border-b border-gray-200 bg-white py-5 sm:px-6">
				<div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Lista de Empresas
						</h3>
					</div>
					<div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
						<div className="flex justify-between gap-4">
							<Link to="/enterprises/form">
								<button className="bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-slate-700">
									Agregar Empresa
								</button>
							</Link>
						</div>
						<div className="">
							<div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
								<form onSubmit={(e) => onSubmit(e)} className="w-full">
									<label htmlFor="search" className="sr-only">
										Search
									</label>
									<div className="relative">
										<button
											type="submit"
											className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
												/>
											</svg>
										</button>
										<input
											id="search"
											name="search"
											required
											onChange={(e) => {
												handleChange(e);
											}}
											className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											placeholder="Buscar"
											type="search"
										/>
									</div>
								</form>
							</div>
						</div>
						<div className="dropdown dropdown-end">
							<div
								tabIndex={0}
								role="button"
								className="p-1.5 bg-white border border-[#0096C4] rounded"
							>
								<IconFilter stroke="#0096C4" />
							</div>
							<div
								tabIndex={0}
								className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
							>
								<div className="flex flex-col gap-y-2">
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Corpoindustrial</span>
										</div>
										<select
											onChange={handleChangeCorpoindustrial}
											value={corpoindustrial}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value ="">Todas</option>
											<option value="SI">SI</option>
											<option value="NO">NO</option>
										</select>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{enterprises && enterprises ? (
				<EnterprisesList
				enterprises={enterprises}
					get_enterprise_list={get_enterprise_list}
					get_enterprise_list_page={get_enterprise_list_page}
					count={count}
					refreshData={refreshData}
				/>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
		</DashboardLayout>
	);
}
const mapStateToProps = (state) => ({
	count: state.enterprise.enterprise_list.length,
	enterprises: state.enterprise.enterprise_list,
});
export default connect(mapStateToProps, {
	get_enterprise_list,
	get_enterprise_list_page,
})(Enterprise);
