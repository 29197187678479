import { Link } from "react-router-dom";
import logoimg from "../../assets/img/logo.png";
import HomeLayout from "../../hocs/layout/HomeLayout";
import DashboardLayout from "../../hocs/layout/DashboardLayout";

function Error404() {
	const MIN_JWT_LENGTH = 20;

	const auth = localStorage.getItem("auth");
	const access = localStorage.getItem("access");
	const refresh = localStorage.getItem("refresh");

	const isValidToken = (token) => token && token.length > MIN_JWT_LENGTH;

	return auth && isValidToken(access) && isValidToken ? (
		<DashboardLayout>
			<div className="py-20 flex flex-col justify-center items-center">
				<Link to="/dashboard">
					<img src={logoimg} className="w-32" alt="logo" />
				</Link>
				<h1 className="text-[14rem] leading-none m-0 text-center font-black text-gray-500">
					404
				</h1>
				<span className="text-sm">
					La página a la que intentas acceder no está disponible
				</span>
				<span className="text-sm">Pulsa en el logo para volver al menu principal</span>
			</div>
		</DashboardLayout>
	) : (
		<HomeLayout>
			<div className="py-20 min-h-screen flex flex-col justify-center items-center">
				<Link to="/">
					<img src={logoimg} className="w-32" alt="logo" />
				</Link>
				<h1 className="text-[14rem] leading-none m-0 text-center font-black text-gray-500">
					404
				</h1>
				<span className="text-sm">
					La página a la que intentas acceder no está disponible
				</span>
				<span className="text-sm">Pulsa en el logo para volver al inicio</span>
			</div>
		</HomeLayout>
	);
}

export default Error404;
