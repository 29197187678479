import React, { useState } from "react";
import Modal from "react-modal";
import CircleLoader from "react-spinners/CircleLoader";
import axios from "axios";
import Toast from "../Alert";
import { IconPencil } from "@tabler/icons-react";

function CommissionEditModal({ id, isEnterprise }) {
	const [loading, setLoading] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [formData, setFormData] = useState({
		paid: "",
	});
	const { paid } = formData;

	const handleClose = () => {
		setModalIsOpen(false)
	};
	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};


	const onSubmit = async (e) => {
		e.preventDefault();
		if (paid === "") {
			Toast({
				tipo: "error",
				mensaje: "Selecciona una opcion de la lista.",
			});
			return;
		}
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
				Accept: "application/json",
			},
		};
		const formData = new FormData();
		formData.append("paid", paid);
		formData.append("id", id);
		let url = ""
		if(isEnterprise){
			url = "/commissions-payments-enterprise/edit/"
		}else{
			url = "/commissions-payments/edit/"
		}
			
		const fetchData = async () => {
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}${url}`,
					formData,
					config
				);
				if (res.status === 200) {
					Toast({
						tipo: "success",
						mensaje: "Comision pagada correctamente.",
					});
					setModalIsOpen(false);
					handleClose();
				}
			} catch (err) {
				console.log(err);
				Toast({
					tipo: "error",
					mensaje: "Error al actualizar el estado de pago.",
				});
			}
		};
		fetchData();
	};

	return (
		<div>
			<div className="lg:tooltip" data-tip="Editar">
				<button
					type="button"
					className="p-1 bg-yellow-500 text-white rounded-full"
					onClick={() => setModalIsOpen(true)}
				>
					<IconPencil />
				</button>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				ariaHideApp={false}
				style={{
					content: {
						maxWidth: "32rem",
						height: "280px",
						justifySelf: "center",
					},
				}}
			>
				<div className="w-full h-100 shadow-card rounded-lg overflow-y-auto">
					<h1 className="font-bold text-xl text-gray-900">
						Editar estado de pago
					</h1>
					<form onSubmit={onSubmit}>
						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]"></div>
						<div className="border border-gray-300 rounded-lg p-3">
							<div className="form-control">
								<label htmlFor="paiment_status" className="label">
									<span className="label-text">Pagado</span>
								</label>
								<select
									name="paid"
									value={paid}
									onChange={onChange}
									required={true}
									className="select select-bordered w-full"
								>
									<option value="">Selecciona el estado del pago</option>
									<option value="si">Sí</option>
									<option value="no">No</option>
								</select>
							</div>
						</div>

						<div className="flex justify-center mt-4 space-x-3">
							<button
								type="submit"
								className="btn border border-[#0096C4] bg-white text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B]"
								onClick={() => setModalIsOpen(false)}
							>
								Cerrar
							</button>
							{loading ? (
								<div className="btn border bg-[#0096C4] text-white">
									<CircleLoader loading={loading} size={25} color="#ffffff" />
								</div>
							) : (
								<button
									type="button"
									className="btn border bg-[#0096C4] hover:bg-[#00496B] text-white"
									onClick={onSubmit}
								>
									Guardar
								</button>
							)}
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
}

export default CommissionEditModal;
