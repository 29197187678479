import {
	GET_COMMISSIONS_ENTERPRISES_SUCCESS,
	GET_COMMISSIONS_ENTERPRISES_FAIL,
	GET_COMMISSIONS_ENTERPRISES_SEARCH_SUCCESS,
	GET_COMMISSIONS_ENTERPRISES_SEARCH_FAIL,
	GET_COMMISSIONS_ENTERPRISES_SUCCESS_ID,
	GET_COMMISSIONS_ENTERPRISES_FAIL_ID,

	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS_ID,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL_ID,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_SUCCESS,
	GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_FAIL
} from "../actions/commissions-enterprise/types";


const initialState = {
	count : null,
	next : null,
	previous : null,
	commissions_enterprises : null,
	commission_enterprise : null,
	commissions_enterprises_payments : null,
	commissions_enterprises_payment : null,
};

export default function commissions_enterprises(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_COMMISSIONS_ENTERPRISES_SUCCESS:
			return {
				...state,
				commissions_enterprises: payload.results,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_ENTERPRISES_FAIL:
			return {
				...state,
				commissions_enterprises: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_COMMISSIONS_ENTERPRISES_SEARCH_SUCCESS:
			return {
				...state,
				commissions_enterprises: payload.filtered_comissions,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_ENTERPRISES_SEARCH_FAIL:
			return {
				...state,
				commissions_enterprises: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_COMMISSIONS_ENTERPRISES_SUCCESS_ID:
			return {
				...state,
				commission_enterprise: payload,
			};
		case GET_COMMISSIONS_ENTERPRISES_FAIL_ID:
			return {
				...state,
				commission_enterprise: null,
			};
		case GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS:
			return {
				...state,
				commissions_enterprises_payments: payload.results,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL:
			return {
				...state,
				commissions_enterprises_payments: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SUCCESS_ID:
			return {
				...state,
				commissions_enterprises_payment: payload,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_ENTERPRISES_PAYMENTS_FAIL_ID:
			return {
				...state,
				commissions_enterprises_payment: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_SUCCESS:
			return {
				...state,
				commissions_enterprises_payments: payload.filtered_comissions,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case GET_COMMISSIONS_ENTERPRISES_PAYMENTS_SEARCH_FAIL:
			return {
				...state,
				commissions_enterprises_payments: null,
				count: null,
				next: null,
				previous: null,
			};
			
		default:
			return state;
	}
}