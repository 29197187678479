import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Toast from "../../../components/Alert";
import { get_payment } from "../../../redux/actions/payment/payment";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import ViewModal from "../../../components/modals/ViewModal";
import { IconEye } from "@tabler/icons-react";
import Loading from "../../../components/Loading";

function PaymentEdit({ get_payment, payment }) {
	const params = useParams();
	const id = params.id;

	const user = useSelector((state) => state.auth.user);
	useEffect(() => {
		window.scrollTo(0, 0);
		get_payment(id);
	}, [get_payment, id]);

	const [updatePrice, setUpdatePrice] = useState(false);
	const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
	const [updateInvoiceCode, setUpdateInvoiceCode] = useState(false);
	const [updateStatus, setUpdateStatus] = useState(false);
	const [updateApprovedSiau, setUpdateApprovedSiau] = useState(false);
	const [updatePartialPayment, setUpdatePartialPayment] = useState(false);
	const [updateInstallments, setUpdateInstallments] = useState(false);
	const [formData, setFormData] = useState({
		price: "",
		payment_method: "",
		invoice_code: "",
		status: "",
		approved_siau: "",
		partial_payments: "",
		installments: "",
	});
	const {
		price,
		payment_method,
		invoice_code,
		status,
		approved_siau,
		partial_payments,
		installments,
	} = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const [loading, setLoading] = useState(false);

	const resetStates = () => {
		setUpdatePrice(false);
		setUpdatePaymentMethod(false);
		setUpdateInvoiceCode(false);
		setUpdateStatus(false);
		setUpdateApprovedSiau(false);
		setUpdatePartialPayment(false);
		setUpdateInstallments(false);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("price", price);
		formData.append("payment_method", payment_method);
		formData.append("invoice_code", invoice_code);
		formData.append("status", status);
		formData.append("id", id);
		formData.append("approved_siau", approved_siau);
		formData.append("partial_payments", JSON.stringify(selectedPayments));
		formData.append("installments", installments);
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/payment/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						price: "",
						payment_method: "",
						invoice_code: "",
						status: "",
						approved_siau: "",
					});

					setLoading(false);
					resetStates();
					get_payment(id);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					setLoading(false);
					resetStates();
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				setLoading(false);
				resetStates();
				Toast({ tipo: "error", mensaje: "Error al editar el pago" });
			}
		};
		fetchData();
	};
	const isPaymentOverdue = (paymentDate) => {
		const today = new Date();
		const dueDate = new Date(paymentDate);
		return dueDate < today;
	};

	const [selectedPayments, setSelectedPayments] = useState([]);

	const handlePaymentSelection = (paymentId) => {
		if (selectedPayments.includes(paymentId)) {
			setSelectedPayments(selectedPayments.filter((id) => id !== paymentId));
		} else {
			setSelectedPayments([...selectedPayments, paymentId]);
		}
	};

	// mostrar los pagos ya realizados verificar aqui y la otra para hacerlo
	const renderPendingPayments = () => {
		if (payment && payment.payment_method === "CREDITO POR LIBRANZA") {
			const pendingPayments = payment.partial_payments.filter(
				(pp) => isPaymentOverdue(pp.payment_date) && !pp.verified
			);
			return pendingPayments.map((item, index) =>
				item.verified === true ? (
					""
				) : (
					<div className="flex items-center justify-between mb-2">
						<label
							htmlFor={`payment_${index}`}
							className="label flex-grow"
							key={index}
						>
							<span className="label-text mr-3">{`Pago ${index + 1}`}</span>
							<span className="py-2 label-text mr-3">
								{item.payment_date
									? new Date(item.payment_date).toLocaleDateString("es-CO")
									: "Sin Especificar"}
							</span>
							<span className="py-2 label-text mr-3">
								{item.amount
									? new Intl.NumberFormat("es-CO", {
											style: "currency",
											currency: "COP",
									  }).format(item.amount)
									: "Sin Especificar"}
							</span>
						</label>
						<input
							type="checkbox"
							id={`payment_${index}`}
							name={`payment_${index}`}
							value={item.id}
							checked={selectedPayments.includes(item.id)}
							onChange={() => handlePaymentSelection(item.id)}
							className="checkbox checkbox-primary ml-3"
						/>
					</div>
				)
			);
		}
	};

	const puedeModificarMetodoDePago = () => {

		if (payment.partial_payments.length === 0) {
			return true;
		}
		return (
			payment.payment_method === "CREDITO POR LIBRANZA" &&
			payment.partial_payments.length === payment.installments
		);
	};

	const isPaymentProcessed =
		payment && payment.approved_siau && (payment.status === "APROBADO" || payment.status === "APROBADO POR SIAU");


	return (
		<DashboardLayout>
			{payment ? (
				<>
					<Helmet>
						<title>Latir | Editar pago</title>
					</Helmet>
					<div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
						<h1 className="font-bold text-3xl  text-gray-900">Editar pago</h1>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos personales
						</div>
						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Titular</span>
								</label>
								<span className="px-4 py-3 rounded-lg w-full">
									{payment && payment.first_name
										? payment.first_name
										: "Sin Especificar"}{" "}
									{payment && payment.last_name
										? payment.last_name
										: "Sin Especificar"}
								</span>
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Número de documento</span>
								</label>
								<span className="px-4 py-3 rounded-lg w-full">
									{payment && payment.nuip ? payment.nuip : "Sin Especificar"}
								</span>
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Información del pago
						</div>

						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Precio</span>
								</label>
								{updatePrice ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													type="text"
													name="price"
													value={price}
													onChange={(e) => onChange(e)}
													required
													className="input input-bordered w-full"
												/>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<div
													onClick={() => setUpdatePrice(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</div>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{payment && payment.price
												? payment.price
												: "Sin Especificar"}
										</span>
										<span className="mt-3">
										{puedeModificarMetodoDePago() === false ? (
												""
											) : (
												!isPaymentProcessed && (
													<div
													onClick={() => setUpdatePrice(true)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Modificar
												</div>
												)
											) }
											
										</span>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Método de pago</span>
								</label>
								{updatePaymentMethod ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="payment_method"
													value={payment_method}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">Seleccione un método de pago</option>
													<option value="EFECTIVO">Efectivo</option>
													<option value="TARJETA DE CREDITO">
														Tarjeta de crédito
													</option>
													<option value="TRANSFERENCIA">Transferencia</option>
													<option value="NEQUI">Nequi</option>
													<option value="DAVIPLATA">Daviplata</option>
													<option value="BANCOLOMBIA">Bancolombia</option>
													<option value="DAVIVIENDA">Davivienda</option>
													<option value="CREDITO POR LIBRANZA">
														Credito por libranza
													</option>
												</select>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<div
													onClick={() => setUpdatePaymentMethod(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</div>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{payment && payment.payment_method
												? payment.payment_method
												: "Sin Especificar"}
										</span>
										<div className="mt-3">
											{puedeModificarMetodoDePago() === false ? (
												""
											) : (
												!isPaymentProcessed && (
													<div
													onClick={() => setUpdatePaymentMethod(true)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Modificar
												</div>
												)
											)}
										</div>
									</>
								)}
							</div>
							
							{payment.payment_method === "CREDITO POR LIBRANZA" ? (
								<div className="form-control">
									<label htmlFor="document_type" className="label">
										<span className="label-text">Cuotas</span>
									</label>
									{updateInstallments ? (
										<>
											<form onSubmit={(e) => onSubmit(e)} className="">
												<span className="flex-grow">
													<input
														type="number"
														name="installments"
														value={installments}
														onChange={(e) => onChange(e)}
														required
														className="input input-bordered w-full"
													/>
												</span>
												<div className="mt-3 flex gap-4">
													<button
														type="submit"

														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Guardar
													</button>
													<div
														onClick={() => setUpdateInstallments(false)}
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Cancelar
													</div>
												</div>
											</form>
										</>
									) : (
										<>
											<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
												{payment && payment.installments
													? payment.installments
													: "Sin Especificar"}
											</span>
											<div className="mt-3">
											{puedeModificarMetodoDePago() === false ? (
												""
											) : (
												!isPaymentProcessed && (
													<div
													onClick={() => setUpdateInstallments(true)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Modificar
													</div>
												)
											)}
											</div>
										</>
									)}
								</div>
							) : (
								""
							)}
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Código de factura</span>
								</label>
								{updateInvoiceCode ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													type="text"
													name="invoice_code"
													value={invoice_code}
													onChange={(e) => onChange(e)}
													required
													className="input input-bordered w-full"
												/>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<div
													onClick={() => setUpdateInvoiceCode(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</div>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{payment && payment.invoice_code
												? payment.invoice_code
												: "Sin Especificar"}
										</span>
										<div className="mt-3">
											{
												!isPaymentProcessed && (
													<div
														onClick={() => setUpdateInvoiceCode(true)}
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Modificar
													</div>
												) 
											}
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="status" className="label">
									<span className="label-text">Estado</span>
								</label>
								{updateStatus ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="status"
													value={status}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">Seleccione un estado</option>
													<option value="EN PROCESO">En proceso</option>
													<option value="APROBADO POR SIAU">
														Aprobado por SIAU
													</option>
													<option value="RECHAZADO">Rechazado</option>
													<option value="APROBADO">Aprobado</option>
												</select>
											</span>
											<span className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<div
													onClick={() => setUpdateStatus(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</div>
											</span>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{payment.status && payment.status
												? payment.status
												: "Sin Especificar"}
										</span>
										<div className="mt-3">
											{!isPaymentProcessed || user.role === "CONTABILIDAD" && user.department === "CONTABILIDAD" ?(
												<div
													onClick={() => setUpdateStatus(true)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Modificar
												</div>
											):""}
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								{payment.payment_method === "CREDITO POR LIBRANZA" ? (
									<>
										<label htmlFor="partial_payments" className="label">
											<span className="label-text">Pagos parciales</span>
										</label>
										{updatePartialPayment ? (
											<form onSubmit={onSubmit} className="">
												{renderPendingPayments()}
												<div className="mt-3 flex gap-4">
													<button
														type="submit"
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Guardar
													</button>
													<div
														onClick={() => setUpdatePartialPayment(false)}
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Cancelar
													</div>
												</div>
											</form>
										) : (
											<>
												{payment &&
												payment.partial_payments &&
												payment.partial_payments.filter(
													(pp) =>
														isPaymentOverdue(pp.payment_date) && !pp.verified
												).length > 0 ? (
													<>
														<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
															{`${
																payment.partial_payments.filter(
																	(pp) =>
																		isPaymentOverdue(pp.payment_date) &&
																		!pp.verified
																).length
															} pagos pendientes`}
														</span>
														<div className="mt-3">
															<div
																onClick={() => setUpdatePartialPayment(true)}
																className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
															>
																Modificar
															</div>
														</div>
													</>
												) : (
													<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
														No hay pagos pendientes por el momento.
													</span>
												)}
											</>
										)}
									</>
								) : (
									<>
										<label htmlFor="document_type" className="label">
											<span className="label-text">Ver pago</span>
										</label>
										{payment.img_payment ? (
											<div className="px-4 py-3 rounded-lg w-full">
												<ViewModal
													data={payment.img_payment}
													title={"Ver Pago"}
													icon={<IconEye />}
												/>
											</div>
										) : (
											<span className="px-4 py-3 rounded-lg w-full">
												Sin pago cargado
											</span>
										)}
									</>
								)}
							</div>

							{user && user.department === "SIAU" ? (
								<div className="form-control">
									<label htmlFor="approved_siau" className="label">
										<span className="label-text">Aprobado por SIAU</span>
									</label>
									{updateApprovedSiau ? (
										<>
											<form onSubmit={(e) => onSubmit(e)} className="">
												<span className="flex-grow">
													<select
														name="approved_siau"
														value={approved_siau}
														onChange={(e) => onChange(e)}
														required
														className="select select-bordered w-full"
													>
														<option value="">Seleccione una opción</option>
														<option value="True">Si</option>
														<option value="False">No</option>
													</select>
												</span>
												<span className="mt-3 flex gap-4">
													<button
														type="submit"
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Guardar
													</button>
													<div
														onClick={() => setUpdateApprovedSiau(false)}
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Cancelar
													</div>
												</span>
											</form>
										</>
									) : (
										<>
											<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
												{payment && payment.approved_siau ? "Si" : "No"}
											</span>
											
											<div className="mt-3">
												<div
													onClick={() => setUpdateApprovedSiau(true)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Modificar
												</div>
											</div>
										</>
									)}
								</div>
							) : (
								<div className="form-control">
									<label htmlFor="approved_siau" className="label">
										<span className="label-text">Aprobado por SIAU</span>
									</label>
									<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
										{payment && payment.approved_siau ? "Si" : "No"}
									</span>
								</div>
							)}
						</div>
					</div>
				</>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	payment: state.payments.payment,
});
export default connect(mapStateToProps, {
	get_payment,
})(PaymentEdit);
