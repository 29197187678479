import axios from "axios";

import {
	GET_FREE_CONSULTATION_LIST_SUCCESS,
	GET_FREE_CONSULTATION_LIST_FAIL,
	GET_FREE_CONSULTATION_SEARCH_SUCCESS,
	GET_FREE_CONSULTATION_SEARCH_FAIL,
} from "./types";

export const get_free_consultation = () => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/free-consultation/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_FREE_CONSULTATION_LIST_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_FREE_CONSULTATION_LIST_FAIL,
			});
		}
	} catch {
		dispatch({
			type: GET_FREE_CONSULTATION_LIST_FAIL,
		});
	}
}

export const get_free_consultation_page = (p) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/free-consultation/list/?p=${p}`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_FREE_CONSULTATION_LIST_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_FREE_CONSULTATION_LIST_FAIL,
			});
		}
	} catch {
		dispatch({
			type: GET_FREE_CONSULTATION_LIST_FAIL,
		});
	}
}

export const search_free_consultation = (search_term) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
		},
	};
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/free-consultation/search/${search_term}/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_FREE_CONSULTATION_SEARCH_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_FREE_CONSULTATION_SEARCH_FAIL,
			});
		}
	} catch {
		dispatch({
			type: GET_FREE_CONSULTATION_SEARCH_FAIL,
		});
	}
}
