import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
	check_authenticated,
	load_user,
	login,
	refresh,
} from "../redux/actions/auth/auth";
import { CircleLoader } from "react-spinners";
import { Navigate } from "react-router-dom";
import img_login from "../assets/img/img-login.png";
import img_logo from "../assets/img/logo.png";

import Toast from "../components/Alert";
import { Helmet } from "react-helmet-async";
import HomeLayout from "../hocs/layout/HomeLayout";
import HomeNavbar from "../components/navigation/HomeNavbar";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function LoginTitular({
	login,
	isAuthenticated,
	refresh,
	check_authenticated,
	load_user,
}) {
	const [loading, setLoading] = useState(false);

	const [term, setTerm] = useState("");

	useEffect(() => {
		isAuthenticated ? (
			<></>
		) : (
			<>
				{refresh()}
				{check_authenticated()}
				{load_user()}
			</>
		);
	}, [isAuthenticated, refresh, check_authenticated, load_user]);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const { email, password } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		login(email, password);
	};

	const handleChange = (e) => {
		setTerm(e.target.value);
	};

	const onSubmitClient = (e) => {
		e.preventDefault();
		setTimeout(
			() => (window.location.href = "/search/client/full/" + term),
			0.2
		);
		setTerm("");
		setLoading(false);
	};

	// const loginWithGoogle = async () => {
	//   try {
	//     const res = await axios.get(
	//       `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/dashboard`
	//     );
	//     window.location.replace(res.data.authorization_url);
	//   } catch (err) {
	//     Toast({ tipo: "error", mensaje: "Error al iniciar sesion con Google" });
	//   }
	// };


	return (
		<HomeLayout>
			<HomeNavbar />
			<Helmet>
				<title>Latir | Login</title>
			</Helmet>
			<main className="h-auto lg:h-screen py-24 lg:py-0 lg:flex lg:items-center bg-gray-100">
				<div className="container mx-auto w-10/12 md:w-auto">
					<div className="flex justify-center">
						<div className="card lg:card-side bg-base-100 shadow-xl">
							<figure>
								<img
									src={img_login}
									className="w-[320px] lg:w-auto lg:h-[495px]"
									alt="login"
								/>
							</figure>
							<div className="card-body w-80 px-0 py-4">
								<div className="top flex justify-between lg:flex-col">
									<div className="bg-[#00496B] pl-8 pr-3 py-1.5 lg:w-36 rounded-e-full text-white font-bold">
										Bienvenido
									</div>
									<div className="flex md:justify-center lg:mt-3 pr-5 lg:pr-0">
										<img className="w-28 md:w-32" src={img_logo} alt="Logo" />
									</div>
								</div>
								<div className=" px-8">
									<div className="flex justify-center my-2">
                    <div className="w-full max-w-md px-2 sm:px-0">
											<div className="">
												<div className="">
													<h2 className="text-center text-gray-900 text-lg font-bold">
														Usuario
													</h2>
												</div>
												<form onSubmit={(e) => onSubmitClient(e)} className="mt-2">
													<div className="flex flex-col items-center justify-center">
														<div className="form-control w-full max-w-xs">
															<label htmlFor="search" className="">
																Número de Documento
															</label>
															<input
																id="search"
																name="search"
																required
																onChange={(e) => {
																	handleChange(e);
																}}
																className="w-full rounded-lg px-3 py-2 text-gray-900 bg-gray-200 focus:z-10 sm:text-sm"
																type="search"
															/>
														</div>
														<div className="mt-6 lg:mt-8 flex justify-center">
															{loading ? (
																<div className="rounded-lg border border-transparent bg-[#00496B] px-6 py-2 text-sm text-white">
																	<CircleLoader
																		loading={loading}
																		size={25}
																		color="#ffffff"
																	/>
																</div>
															) : (
																<button
																	type="submit"
																	className="rounded-lg border border-transparent bg-[#00496B] px-6 py-2 text-sm text-white"
																>
																	Verificar
																</button>
															)}
														</div>
													</div>
												</form>
											</div>
                    </div>
									</div>
									{/* <div className="">
										<div className="">
											<h2 className="text-center text-gray-900">
												Ingresa a tu cuenta
											</h2>
										</div>
										<form
											onSubmit={(e) => {
												onSubmit(e);
											}}
											className="mt-2"
											action="#"
											method="POST"
										>
											<input
												type="hidden"
												name="remember"
												defaultValue="true"
											/>
											<div className="">
												<div className="mb-2">
													<label htmlFor="email-address" className="">
														Correo
													</label>
													<input
														id="email-address"
														name="email"
														value={email}
														onChange={(e) => onChange(e)}
														type="email"
														required
														className="w-full rounded-lg px-3 py-2 text-gray-900 bg-gray-200 focus:z-10 sm:text-sm"
													/>
												</div>
												<div>
													<label htmlFor="password" className="">
														Contraseña
													</label>
													<input
														id="password"
														name="password"
														value={password}
														onChange={(e) => onChange(e)}
														type="password"
														required
														className="w-full rounded-lg px-3 py-2 text-gray-900 bg-gray-200 focus:z-10 sm:text-sm"
													/>
												</div>
											</div>

											<div className="mt-6 lg:mt-8 flex justify-center">
												<button
													type="submit"
													className="rounded-lg border border-transparent bg-[#00496B] px-6 py-2 text-sm text-white"
												>
													Ingresar
												</button>
											</div>
											<div className="mt-4 lg:mt-8 text-center text-sm">
												<Link
													to="/forgot_password"
													className="text-[#00496B] border-b border-b-[#00496B] pb-1"
												>
													¿Olvidó su contraseña?
												</Link>
											</div>
										</form>
									</div>
									<div className="">
										<form onSubmit={(e) => onSubmitClient(e)} className="">
											<div className="flex flex-col items-center justify-center">
												<div className="form-control w-full max-w-xs">
													<div className="">
														<h2 className="text-center text-gray-900">
															Verifica tus datos
														</h2>
													</div>
													<input
														id="search"
														name="search"
														required
														onChange={(e) => {
															handleChange(e);
														}}
														className="block w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
														placeholder="Cédula"
														type="search"
													/>
												</div>
												<div className="flex justify-center">
													{loading ? (
														<div className="rounded-md border-2 border-white px-4 py-2 mt-4 text-white">
															<CircleLoader
																loading={loading}
																size={25}
																color="#ffffff"
															/>
														</div>
													) : (
														<button
															type="submit"
															className="rounded-md border-2 border-white px-4 py-2 mt-4 text-white"
														>
															Verificar
														</button>
													)}
												</div>
											</div>
										</form>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</HomeLayout>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	loading: state.auth.loading,
});

export default connect(mapStateToProps, {
	login,
	refresh,
	check_authenticated,
	load_user,
})(LoginTitular);
