import { useEffect, useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";

import {
	get_commissions_payments,
	get_commissions_payments_page,
} from "../../../redux/actions/commissions/commissions";

import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import CommissionsPaymentsList from "../../../components/userscommissions/CommissionsPaymentsList";
import { IconFilter } from "@tabler/icons-react";

function PaymentCommissions({
	get_commissions_payments,
	get_commissions_payments_page,
	commissions_payments,
	count,
}) {
	const user = useSelector((state) => state.auth.user);

	const [paid, setPaid] = useState("");
	const [table, setTable] = useState("");
	const [role, setRole] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");

	useEffect(() => {
		if (
			table !== "" ||
			role !== "" ||
			month !== "" ||
			year !== "" ||
			paid !== ""
		) {
			get_commissions_payments(table, role, month, year, paid);
		} else {
			get_commissions_payments();
		}
	}, [get_commissions_payments, table, role, month, year, paid]);

	const tableValues =
		useSelector((state) => state.auth.user?.table?.map((item) => item.table)) ||
		[];

	const [term, setTerm] = useState("");
	useEffect(() => {
		get_commissions_payments();
	}, [get_commissions_payments]);
	const handleChange = (e) => {
		setTerm(e.target.value);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setTimeout(
			() => (window.location.href = "/search/commission/users/payment/" + term),
			0.2
		);
		setTerm("");
	};

	const handleChangeTable = (e) => {
		setTable(e.target.value);
	};

	const handleChangeRole = (e) => {
		setRole(e.target.value);
	};

	const handleChangeMonth = (e) => {
		setMonth(e.target.value);
	};

	const handleChangeYear = (e) => {
		setYear(e.target.value);
	};

	const handleChangePaid = (e) => {
		setPaid(e.target.value);
	};

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Registro de Comisiones</title>
			</Helmet>
			<div className="border-b border-gray-200 bg-white py-5">
				<div className="flex flex-col md:flex-row md:items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Lista de Comisiones por pagar a Usuarios
						</h3>
					</div>
					<div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
						<div className="flex justify-between gap-4"></div>
						<div className="">
							<div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
								<form onSubmit={(e) => onSubmit(e)} className="w-full">
									<label htmlFor="search" className="sr-only">
										Search
									</label>
									<div className="relative">
										<button
											type="submit"
											className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
												/>
											</svg>
										</button>
										<input
											id="search"
											name="search"
											required
											onChange={(e) => {
												handleChange(e);
											}}
											className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											placeholder="Buscar"
											type="search"
										/>
									</div>
								</form>
							</div>
						</div>
						<div className="dropdown dropdown-end">
							<div
								tabIndex={0}
								role="button"
								className="p-1.5 bg-white border border-[#0096C4] rounded"
							>
								<IconFilter stroke="#0096C4" />
							</div>
							<div
								tabIndex={0}
								className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
							>
								<div className="flex flex-col gap-y-2">
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Estado</span>
										</div>
										<select
											onChange={handleChangePaid}
											value={paid}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value="true">Pagado</option>
											<option value="false">No Pagado</option>
										</select>
									</label>

									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Mesa</span>
										</div>
										<select
											onChange={handleChangeTable}
											value={table}
											className="select select-bordered w-full"
										>
											<option value="">Elige una mesa</option>
											{tableValues &&
												tableValues.map((value) => (
													<option key={value} value={value}>
														Mesa {value}
													</option>
												))}
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Rol</span>
										</div>
										<select
											onChange={handleChangeRole}
											value={role}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value="ENLACE">Enlace</option>
											<option value="DIRECTOR">Director</option>
											<option value="SUBDIRECTOR">Sub Director</option>
											<option value="COORDINADOR EJECUTIVO">
												Coordinador Ejecutivo
											</option>
											<option value="COORDINADOR JUNIOR">
												Coordinador Junior
											</option>
											<option value="SUPERVISOR">Supervisor</option>
											<option value="REPRESENTANTES">Representantes</option>
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Mes</span>
										</div>
										<select
											onChange={handleChangeMonth}
											value={month}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value="01">Enero</option>
											<option value="02">Febrero</option>
											<option value="03">Marzo</option>
											<option value="04">Abril</option>
											<option value="05">Mayo</option>
											<option value="06">Junio</option>
											<option value="07">Julio</option>
											<option value="08">Agosto</option>
											<option value="09">Septiembre</option>
											<option value="10">Octubre</option>
											<option value="11">Noviembre</option>
											<option value="12">Diciembre</option>
										</select>
									</label>
									<label className="form-control w-full max-w-xs">
										<div className="label">
											<span className="label-text">Año</span>
										</div>
										<select
											onChange={handleChangeYear}
											value={year}
											className="select select-bordered w-full"
										>
											<option value="">Elige una opción</option>
											<option value="2024">2024</option>
											<option value="2025">2025</option>
										</select>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{commissions_payments && commissions_payments.length > 0 ? (
				<CommissionsPaymentsList
					commissions={commissions_payments}
					get_commissions_page={get_commissions_payments_page}
					count={count}
				/>
			) : (
				<div className="flex items-center justify-center h-96">
					<p className="text-gray-500 text-2xl">No hay comisiones por pagar</p>
				</div>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	commissions_payments: state.commissions.commissions_payments,
	count: state.commissions.count,
});

export default connect(mapStateToProps, {
	get_commissions_payments,
	get_commissions_payments_page,
})(PaymentCommissions);
