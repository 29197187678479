import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { IconTrash } from "@tabler/icons-react";

function DeleteClient({ data, onSubmitDeleteClient, type }) {

	const [openDelete, setOpenDelete] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const user = useSelector((state) => state.auth.user);

	const handleSubmit = (e) => {
		e.preventDefault();
		setOpenDelete(false);
		onSubmitDeleteClient(e, data.id, email, password);
	};
	return (
		<>
			{type === "titular" ? (
				<label
					type="button"
					onClick={(e) => setOpenDelete(true)}
					className="btn border w-20 bg-[#c32a2a] text-white"
					htmlFor="membership_renew_modal"
				>
					<span>No</span>
				</label>
			) : (
				<div className="tooltip flex" data-tip="Eliminar">
					<a
						onClick={(e) => setOpenDelete(true)}
						className="p-1 bg-red-500 text-white rounded-full cursor-pointer"
					>
						<IconTrash />
					</a>
				</div>
			)}

			<Transition.Root show={openDelete} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setOpenDelete}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
									<div>
										<div className="text-center">
											<Dialog.Title
												as="h3"
												className="text-2xl font-medium leading-6 text-gray-900"
											>
												{type === "titular" ? "Desactivar Cliente" : "Eliminar Beneficiario"}
											</Dialog.Title>
											<div className="mt-2">
												{type === "titular" ? (
												<p className="text-xl text-gray-500">
													¿Estás seguro de Desactivar el cliente{" "}
													{data.first_name} y todos sus beneficiarios directos?
												</p>
												):(
												<p className="text-xl text-gray-500">
													¿Estás seguro de Eliminar el beneficiario{" "}
													{data.first_name}?
												</p>
												)}
											</div>
										</div>
									</div>
									{user && user.role !== "ENLACE" ? (
										<form
											onSubmit={handleSubmit}
											className="mt-4 flex flex-col items-center"
										>
											<input
												type="email"
												placeholder="Email"
												onChange={(e) => setEmail(e.target.value)}
												className="input input-bordered w-full max-w-xs mb-3"
												required
											/>
											<input
												type="password"
												placeholder="Contraseña"
												onChange={(e) => setPassword(e.target.value)}
												className="input input-bordered w-full max-w-xs"
												required
											/>
											<div className="mt-4">
												<button
													type="submit"
													className="btn border bg-[#c32a2a] text-white"
													onClick={() => setOpenDelete(false)}
												>
													Eliminar
												</button>
												<button
													type="button"
													onClick={() => setOpenDelete(false)}
													className="btn border bg-[#0096C4] text-white"
												>
													Cancelar
												</button>
											</div>
										</form>
									) : (
										<form
											onSubmit={(e) => onSubmitDeleteClient(e, data.id)}
											className="mt-5 sm:mt-6 flex flex-wrap space-x-2 justify-center"
										>
											<button
												type="submit"
												className="btn border bg-[#c32a2a] text-white"
											>
												<span>Eliminar</span>
											</button>

											<button
												type="button"
												onClick={(e) => setOpenDelete(false)}
												className="btn border bg-[#0096C4] text-white"
											>
												<span>Cancelar</span>
											</button>
										</form>
									)}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}

export default DeleteClient;
