import { useEffect, useState } from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { get_client_dashboard_table } from "../redux/actions/client/client";
import Loading from "../components/Loading";
import { useParams } from "react-router-dom";

function buildPyramid(nodes) {
	if (!nodes.length) {
		return [];
	}

	const innerClients = nodes.flatMap((node) => node.clients);
	let pyramid = [];
	innerClients.forEach((node) => {
		if (node && node.level !== undefined) {
			if (!pyramid[node.level]) {
				pyramid[node.level] = [];
			}
			pyramid[node.level].push(node);
		}
	});

	return pyramid;
}

function Level({ nodes }) {
	// Función para agrupar los nodos por nivel
	const groupByLevel = (nodes) => {
		const levelGroups = {};
		nodes.forEach((node) => {
			if (!levelGroups[node.level]) {
				levelGroups[node.level] = [];
			}
			levelGroups[node.level].push(node);
		});
		return levelGroups;
	};

	// Agrupamos los nodos por nivel
	const levels = groupByLevel(nodes);

	const colorByLevel = (level) => {
		switch (level) {
			case 0:
				return "bg-level-1";
			case 1:
				return "bg-level-2";
			case 2:
				return "bg-level-3";
			case 3:
				return "bg-level-4";
			case 4:
				return "bg-level-5";
			case 5:
				return "bg-level-6";
			case 6:
				return "bg-level-7";
			case 7:
				return "bg-level-8";
			case 8:
				return "bg-level-9";
			case 9:
				return "bg-level-10";
			case 10:
				return "bg-level-11";
			case 11:
				return "bg-level-12";
			case 12:
				return "bg-level-13";
			default:
				return "no-level";
		}
	};

	// Función para renderizar los nodos de un nivel
	const renderLevel = (nodes, level) => {
		return (
			<div key={level} className="mb-0.5">
				<div className="flex">
					{nodes.map((node, index) => (
						<div
							key={`${node.tree_id}-${index}`}
							className={`${colorByLevel(
								node.level
							)} mx-0.5 p-2 rounded tooltip tooltip-right tooltip-info`}
							data-tip={`Nivel - ${node.level + 1}`}
						>
							{/* Node content here */}
						</div>
					))}
				</div>
			</div>
		);
	};

	return (
		<div className="flex mx-auto">
			{Object.entries(levels)
				.sort(([a], [b]) => a - b)
				.map(([level, nodes]) => renderLevel(nodes, level))}
		</div>
	);
}

function DashboardDetail({ get_client_dashboard_table, clients }) {
	const [pyramid, setPyramid] = useState([]);
	const params = useParams();
	const id = params.id;
	useEffect(() => {
		get_client_dashboard_table(id);
	}, [get_client_dashboard_table,id]);
	useEffect(() => {
		if (clients) {
			setPyramid(buildPyramid(clients));
		}
	}, [clients]);

  const leyend = [
    {
      level: 1,
      color: "bg-level-1",
      text: "Nivel 1"
    },
    {
      level: 2,
      color: "bg-level-2",
      text: "Nivel 2"
    },
    {
      level: 3,
      color: "bg-level-3",
      text: "Nivel 3"
    },
    {
      level: 4,
      color: "bg-level-4",
      text: "Nivel 4"
    },
    {
      level: 5,
      color: "bg-level-5",
      text: "Nivel 5"
    },
    {
      level: 6,
      color: "bg-level-6",
      text: "Nivel 6"
    },
    {
      level: 7,
      color: "bg-level-7",
      text: "Nivel 7"
    },
    {
      level: 8,
      color: "bg-level-8",
      text: "Nivel 8"
    },
    {
      level: 9,
      color: "bg-level-9",
      text: "Nivel 9"
    },
    {
      level: 10,
      color: "bg-level-10",
      text: "Nivel 10"
    },
    {
      level: 11,
      color: "bg-level-11",
      text: "Nivel 11"
    },
    {
      level: 12,
      color: "bg-level-12",
      text: "Nivel 12"
    },
  ]
  
	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Dashboard</title>
			</Helmet>
			<div className="border-b border-gray-200 bg-white py-5">
				<div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Dashboard
						</h3>
					</div>
				</div>
			</div>
      <div className="py-2">
        <h4 className="text-xl text-center font-medium">Leyenda</h4>
        <div className="flex justify-center flex-wrap items-center gap-2 py-2">
          {leyend.map((item, index) => (
            <div key={index} className={`${item.color} py-1 px-2 text-sm text-white rounded`}>{item.text}</div>
          ))}
        </div>
      </div>

			{clients ? (
				<div className="mt-1.5 overflow-x-auto py-2">
					{pyramid.map((level, index) => (
						<Level key={index} nodes={level} />
					))}
				</div>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	clients: state.clients.clients,
});
export default connect(mapStateToProps, {
	get_client_dashboard_table,
})(DashboardDetail);
