import { IconEye, IconPencil } from "@tabler/icons-react";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import ViewModal from "../modals/ViewModal";

function EnterprisesCardHorizontal({ data, index, refreshData }) {
	const [openImg, setOpenImg] = useState(false);
	return (
		<tr className="bg-white border-b">
			<td className="p-2"></td>
			<td className="p-2">
				<div className="flex text">
					<span className="font-bold">Razon Social: </span>{" "}
					<span className="flex ml-1">{data.name_enterprise}</span>
				</div>
				<div className="flex text">
					<span className="font-bold">Nit: </span>{" "}
					<span className="flex ml-1">{data.nit}</span>
				</div>
			</td>
			<td className="p-2">
				<div className="w-52">{data.address || "No se ha proporcionado"}</div>
			</td>
			<td className="p-2">
				<div className="text-xs">
					<span className="font-bold">Nombre: </span>{" "}
					<span className="flex ml-1">{data.legal_representative}</span>
				</div>
				<div className="text-xs">
					<span className="font-bold">Numero de Docuento: </span>{" "}
					<span className="flex ml-1">{data.nuip}</span>
				</div>
			</td>
			<td className="p-2">
				{data.corpoindustrial ? (
					<div className="badge badge-success gap-2">SI</div>
				) : (
					<div className="badge badge-error gap-2">NO</div>
				)}
			</td>
			<td className="p-2">
				{(data.rut_document && (
					<ViewModal
						setOpenImg={setOpenImg}
						data={data.rut_document}
						title={"Ver Rut"}
						icon={<IconEye />}
					/>
				)) ||
					"No se ha proporcionado"}
			</td>
			<td className="p-2">
				<div className="flex tooltip" data-tip="Editar">
					<Link
						to={`/enterprises/edit/${data.id}`}
						className="p-1 text-white bg-yellow-500 rounded-full"
					>
						<IconPencil />
					</Link>
				</div>
			</td>
		</tr>
	);
}

export default EnterprisesCardHorizontal;
